import React from 'react'
import { spinnerRedHDGif } from 'constant/images'

const SpinnerRed = props => {
  return (
    <div
      style={{ zoom: window.scalingPercent, width: 40 }}>
      <img
        src={spinnerRedHDGif}
        style={{
          width: 40,
          height: 40
        }}
      />
    </div>
  )
}

export default SpinnerRed
