import ReactDOM from 'react-dom'
import videojs from 'video.js'
import AgeContentRatingRA from '../components/ageContentRating'

const vjsComponent = videojs.getComponent('Component');

// The videojs.extend function is used to assist with inheritance. In
// an ES6 environment, `class SeekNext extends Component` would work
// identically.

class AgeContentRating extends vjsComponent {
  // The constructor of a component receives two arguments: the
  // player it will be associated with and an object of options.
  constructor(player, options) {
    super(player, options);
    this.mount = this.mount.bind(this);

    this.mount();
    this.on('dispose', () => {
      ReactDOM.unmountComponentAtNode(this.el());
    });
  }

  // The `createEl` function of a component creates its DOM element.
  createEl() {
    const div = videojs.dom.createEl('div', {
      className: 'vjs-age-content-rating',
    });
    return div;
  }

  mount() {
    const { content } = this.options_;
    console.log('content',content)
    ReactDOM.render(<AgeContentRatingRA content={content} player={this.player_} />, this.el());
  }
}

// Register the component with Video.js, so it can be used in players.
videojs.registerComponent('AgeContentRating', AgeContentRating);
