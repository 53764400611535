/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { keyCode } from 'common/utils'
import Spinner from 'components/Spinner'
import Menu from 'features/menu/components/Menu'
import Search from 'features/search/components/Search'
import Ribbon from 'features/ribbon/components/Ribbon'
import useProfile from 'hooks/useProfile'
import { setIsShowLogin } from 'features/home/loginSlice'
import '../styles/home.scss'

const HomePage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { getProfile } = useProfile()
  
  const loading = useSelector((state) => state.root.loading)
  const menu = useSelector((state) => state.menu.menu)
  const isShowLogin = useSelector((state) => state.login.isShowLogin)
  const isShowWarningLogin = useSelector((state) => state.modal.isShowWarningLogin)
  const isShowSubscription = useSelector((state) => state.subscription.isShowSubscription)

  const [isLoadLoginState, setIsLoadLoginState] = useState(true)

  const eventListener = useCallback(() => {
    //
  }, [isShowLogin, isShowSubscription, isShowWarningLogin])

  useEffect(() => {
    const updateUserProfile = async () => {
      let profile = await localStorage.getItem('user_profile')
      if (profile) {
        await getProfile.mutateAsync()
        setTimeout(() => {
          setIsLoadLoginState(false)
        }, 2000)
      } else {
        setIsLoadLoginState(false)
      }
    }

    updateUserProfile()
  }, [])

  useEffect(() => {
    window.onpopstate = () => {
      history.push('/exit')
    }

    document.addEventListener('keydown', handleKey)
    return () => {
      document.removeEventListener('keydown', handleKey)
    }
  }, [eventListener])

  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */
  const handleKey = (e) => {
    e.preventDefault()

    if (isShowSubscription || isShowWarningLogin || isShowLogin) return

    switch (e.keyCode) {
      case keyCode.RETURN: {
        if (!isShowLogin) {
          history.push('/exit')
        } else {
          dispatch(setIsShowLogin(false))
        }
        break
      }
      case keyCode.EXIT: {
        history.push('/exit')
        break
      }
    }
  }

  if (isLoadLoginState) {
    <div id="home-page" className="container-fluid remove-pading-left home-page-background-color">
      <Spinner />
    </div>
  }

  return (
    <div id="home-page" className="container-fluid remove-pading-left home-page-background-color">
      {loading &&
        <Spinner />
      }

      <div className="row flex-nowrap home-wrapper">
        <Menu />

        {(menu && menu.slug === 'search')
          ? <Search />
          : <Ribbon />
        }
      </div>

    </div>
  )
}

export default HomePage
