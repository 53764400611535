import rootReducer from './slice'
import menuReducer from 'features/menu/menuSlice'
import ribbonReducer from 'features/ribbon/ribbonSlice'
import contentReducer from 'features/content/contentSlice'
import viewmoreReducer from 'features/viewmore/viewmoreSlice'
import playerReducer from 'features/player/playerSlice'
import epgReducer from 'features/epg/epgSlice'
import seasonReducer from 'features/season/seasonSlice'
import loginReducer from 'features/home/loginSlice'
import subscriptionReducer from 'features/subscription/subscriptionSlice'
import modalReducer from 'features/modal/modalSlice'
import searchReducer from 'features/search/searchSlice'


const rootReducerExport = {
  root: rootReducer,
  menu: menuReducer,
  ribbon: ribbonReducer,
  content: contentReducer,
  viewmore: viewmoreReducer,
  player: playerReducer,
  epg: epgReducer,
  season: seasonReducer,
  login: loginReducer,
  subscription: subscriptionReducer,
  modal: modalReducer,
  search: searchReducer
}

export default rootReducerExport
