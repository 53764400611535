import React, { useState, useContext, useEffect, useCallback, useRef } from 'react'

import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useMutation } from 'react-query'

import {  getData } from 'common/utils'

import { setIsShowSeason, setLinkPlay } from 'features/season/seasonSlice'
import useStreamLimit from 'features/player/hooks/streamingLimit'
import useEventFirebase from 'features/player/hooks/eventFirebase'
import useBlockKeyboard from 'hooks/useBlockKeyboard'
import useKeyBoardPlayer from 'features/player/hooks/useKeyBoardPlayer'
import useFetchApiSeason from 'features/season/hooks/useFetchSeason'

import PropTypes from 'prop-types'

const PlayerContext = React.createContext({})

export const PlayerProvider = ({ children }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const playerRef = useRef(null)
  const modalSeasonRef = useRef()
  const content = useSelector((state) => state.content.content)
  const isLogin = useSelector(state => state.login.isLogin)

  const enterCountRef = useRef(null)
  const timeoutResetEnterCountRef = useRef(null)
  const isDisplayDebugLogRef = useRef(null)
  const timerGetDebugLogRef = useRef(null)
  const remoteDebugLogChannelIdRef = useRef(null)
  const durationRef = useRef(0)

  const isShowSubscription = useSelector((state) => state.subscription.isShowSubscription)
  const playerData = useSelector((state) => state.player.playerData)
  const isShowSeason = useSelector((state) => state.season.isShowSeason)
  const linkPlay = useSelector((state) => state.season.linkPlay)
  const currentPlayerData = useRef(null)

  const [src, setSrc] = useState(null)

  const [player, setPlayer] = useState(null)
  const [loadingPlayer, setLoadingPlayer] = useState(false)
  const [title, setTitle] = useState('')
  // const [toast, setToast] = useState(null)
  const [active, setActive] = useState('playPause') // playPause hoặc back
  const [status, setStatus] = useState('play') // trạng thái play/pause
  const [showInfo, setShowInfo] = useState(false)
  const [timeoutVar, setTimeoutVar] = useState(null)
  const [isEndedVideo, setEndedVideo] = useState(false)
  const {
    setupStreamLimit,
    handleRetry,
    handleKeyLimit,

    isLimit,
    loadingLimit,
    activeCwl
  } = useStreamLimit({ playerRef })
  const { checkLogEvent } = useEventFirebase(playerRef)
  const { isBlockKeyboard } = useBlockKeyboard()

  const [isDisplayDebugLog, setIsDisplayDebugLog] = useState(false)
  const [bandWidth, setBandWidth] = useState(0)
  const [videoBitrateState, setVideoBitrateState] = useState(0)
  const [currentTimeDebug, setCurrentTimeDebug] = useState(0)
  const [buffered, setBuffered] = useState(0)
  const [videoBuffered, setVideoBuffered] = useState(0)
  const [seekable, setSeekable] = useState(0)
  const [bufferCount, setBufferCount] = useState(0)
  const [currentSource, setCurrentSource] = useState('')
  const [currentSegment, setCurrentSegment] = useState({})
  const {
    setEpisode,
    setSeason,
    handleNextEps,
    handlePrevEps,

    isNextContent,
    isPrevContent,
    dataSeasonById,
    isLoadingSeasonById,
    isLoadingContentSeason
  } = useFetchApiSeason({ type: 'modal' })


  const { mutate: logView } = useMutation(
    async ({ content_id, progress }) => {
      if(!isLogin) return
      const data = { progress };
      return await getData('POST' ,`content/${content_id}/progress`, data)
    },
    {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onSuccess: () => {},
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {}
    }
  )

  const handleLogProgress = useCallback(() => {
    if (!playerRef.current) return
    const playerDataTmp = currentPlayerData.current || playerData
    const progress = playerRef.current?.currentTime()
    const contentId = playerDataTmp?.currentEpisodes ? playerDataTmp?.currentEpisodes?.id : playerDataTmp?.id
    logView({ content_id: contentId, progress: progress.toFixed() > 1 ? progress.toFixed() : 1 })
  }, [playerData])


  const eventListener = useCallback(() => {
  }, [playerData, player, active, status, showInfo, timeoutVar, isShowSeason, isLimit, isBlockKeyboard, activeCwl, loadingLimit, isNextContent,
    isPrevContent,
    dataSeasonById,
    isLoadingSeasonById,
    isLoadingContentSeason])

  useEffect(() => {
    window.onpopstate = () => {
      if (!isShowSeason) {
        if (timeoutVar) {
          clearTimeout(timeoutVar)
        }

        if (playerData && playerData.season) {
          dispatch(setLinkPlay(''))
          dispatch(setIsShowSeason(false))
          if (modalSeasonRef && modalSeasonRef.current) {
            modalSeasonRef.current.disposeModal()
          }
        }
        history.goBack()
      }
    }

    return () => {
      console.clear()
      handleLogProgress()
      if (timeoutVar) {
        clearTimeout(timeoutVar)
      }
    }
  }, [])

  useEffect(() => {
    setLoadingPlayer(false)
    setTimeout(() => {
      setLoadingPlayer(true)
    }, [200])
  }, [playerData?.id])


  const getBuffered = (buffered) => {
    let bufferedText = ''

    if (!buffered) {
      return bufferedText
    }

    if (buffered.length) {
      bufferedText += buffered.start(0) + ' - ' + buffered.end(0)
    }
    for (let i = 1; i < buffered.length; i++) {
      bufferedText += ', ' + buffered.start(i) + ' - ' + buffered.end(i)
    }
    return bufferedText
  }

  const increaseCount = () => {
    enterCountRef.current++
  }

  const createDebugModeCounter = () => {
    if (!player) return

    if (enterCountRef.current >= 10) {
      enterCountRef.current = 0
      if (timeoutResetEnterCountRef.current) {
        clearTimeout(timeoutResetEnterCountRef.current)
        timeoutResetEnterCountRef.current = null
      }

      if (isDisplayDebugLog) {
        setIsDisplayDebugLog(() => false)
        isDisplayDebugLogRef.current = false

        window.videojs.log.level('off')
        // removeRemoteLogging()

        if (timerGetDebugLogRef.current) {
          clearInterval(timerGetDebugLogRef.current)
          timerGetDebugLogRef.current = null
        }
      } else {
        setIsDisplayDebugLog(() => true)
        isDisplayDebugLogRef.current = true

        // addRemoteLogging(remoteDebugLogChannelIdRef.current)
        window.videojs.log.level('debug')

        if (!timerGetDebugLogRef.current) {
          timerGetDebugLogRef.current = setInterval(() => {
            const bandwidth = (player?.tech_?.vhs?.bandwidth / 1024).toLocaleString(undefined, { maximumFractionDigits: 1 }) + ' kbps'
            setBandWidth(() => bandwidth)

            const playlist = player?.tech_?.vhs?.playlists?.media()
            if (playlist && playlist.attributes && playlist.attributes.BANDWIDTH) {
              const videoBW = (playlist.attributes.BANDWIDTH / 1024).toLocaleString(undefined, { maximumFractionDigits: 1 }) + ' kbps'
              setVideoBitrateState(() => videoBW)
            }

            setBuffered(getBuffered(player?.buffered()))

            const videoBufferedStat = getBuffered(player.tech_?.vhs?.playlistController_?.mainSegmentLoader_?.sourceUpdater_?.videoBuffer && player.tech(true)?.vhs?.playlistController_?.mainSegmentLoader_?.sourceUpdater_?.videoBuffer?.buffered)
            setVideoBuffered(() => videoBufferedStat)

            const seekable = player.seekable()
            if (seekable && seekable.length) {
              const seekableStartStat = seekable.start(0).toFixed(1)
              setSeekable(() => seekableStartStat)
            }

            const curSource = player?.currentSource()?.src
            setCurrentSource(() => curSource)

            const curSegment = getCurrentSegmentInfo(player)
            setCurrentSegment(() => curSegment)
          }, 2000)
        }
      }
    } else {
      if (timeoutResetEnterCountRef.current) {
        clearTimeout(timeoutResetEnterCountRef.current)
        timeoutResetEnterCountRef.current = null
      }

      timeoutResetEnterCountRef.current = setTimeout(() => {
        enterCountRef.current = 0
      }, 1000)
    }
  }

  const getCurrentSegmentInfo = (obj) => {
    let segment
    const targetMedia = obj?.tech_?.vhs?.playlists?.media()
    const snapshotTime = obj?.currentTime()

    if (targetMedia && targetMedia?.segments) {
      for (let i = 0, l = targetMedia?.segments?.length; i < l; i++) {
        if (snapshotTime < targetMedia.segments[i].end) {
          segment = targetMedia.segments[i]
          break
        }
      }
    }
    return segment
  }

  useEffect(() => {
    return () => {
      if (timeoutVar) {
        clearTimeout(timeoutVar)
      }
    }
  }, [timeoutVar])

  useEffect(() => {
    if (playerData) {
      currentPlayerData.current = playerData
      handleLogProgress()
      setTitle(playerData.title)
      setSrc(playerData.linkPlay)
    }

  }, [playerData])

  useEffect(() => {
    if (player) {
      handleLogProgress()
      // player.src({
      //   type: 'application/x-mpegURL',
      //   src: playerData.linkPlay
      // })

      // Buffering
      player.on('waiting', () => {
        if (isDisplayDebugLogRef.current) {
          setBufferCount(pre => pre + 1)
          const curSegment = getCurrentSegmentInfo(player)
          setCurrentSegment(() => curSegment)
        }
      })

      player.on('loadedmetadata', () => {
        if (currentPlayerData.current?.progress) {
          const duration = durationRef.current || player.duration()
          let seekResume = currentPlayerData.current?.progress
          if (seekResume + 30 >= duration) return
          player.currentTime(currentPlayerData.current?.progress)
        }
      })

      player.on('timeupdate', () => {
        if (durationRef.current !== player.duration()) {
          durationRef.current = player.duration()
        }
        if (isDisplayDebugLogRef.current) {
          setCurrentTimeDebug(player.currentTime().toFixed(1))
        }
      })

      player.on('playing', () => {
        if (isDisplayDebugLogRef.current) {
          const curSegment = getCurrentSegmentInfo(player)
          setCurrentSegment(() => curSegment)
        }
      })

      // End Video
      player.on('ended', () => {
        console.log('ended duration', player.duration())
        console.log('ended currrent', player.currentTime())
        console.log('ended', Math.floor(player.duration()) , Math.floor(durationRef.current))
        if (durationRef.current > 0 && Math.floor(player.duration()) === Math.floor(durationRef.current)) {
          setEndedVideo(true)
        }
      })
      if (document.getElementsByClassName('vjs-play-control') && document.getElementsByClassName('vjs-play-control')[0]) {
        document.getElementsByClassName('vjs-play-control')[0].classList.add('active')
      }
      durationRef.current = 0
      // Finish init player
      setShowInfo(true)
    }

    return () => {
      if (player) {
        // player.dispose()
        dispatch(setLinkPlay(''))
      }
    }
  }, [player])

  useEffect(() => {
    if (linkPlay !== '') {
      if (player) {
        setSrc(linkPlay)
      }
    }
  }, [linkPlay])


  useEffect(() => {
    if (isEndedVideo) {
      handleEndVjs()
      setEndedVideo(false)
    }
  }, [isEndedVideo,handleNextEps, isNextContent, isPrevContent,])


  useEffect(() => {
    if (playerRef.current) {
      if(isShowSubscription)  return playerRef.current.pause()
        playerRef.current.play()
    }
  }, [isShowSubscription])


  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */

  const handleEndVjs = () => {
    if (playerData.season) {
      if (!isNextContent) {
        history.goBack()
      } else {
        handleNextEps()
      }
      return
    }
    returnExitEvent()
  }

  const handlePlayerReady = (player) => {
    playerRef.current = player
    setPlayer(player)
    checkLogEvent(player)
    // if (login?.isLogin === true && !playerData?.drmSession) {
    if (!playerData?.drmSession) {
      setupStreamLimit(playerRef.current)
    }

    player.one('canplaythrough', () => {
      durationRef.current = player.duration()
    })
  }

  const disposeVis = () => {
    setPlayer(null)
    playerRef.current?.dispose()
    playerRef.current = null
  }

  const returnExitEvent = () => {
    if (!isShowSeason) {
      if (timeoutVar) {
        clearTimeout(timeoutVar)
      }

      // if (timeoutBuffering) {
      //   clearTimeout(timeoutBuffering)
      // }

      // if (toast && toast._element) {
      //   toast.dispose()
      // }

      if (playerData && playerData.season) {
        dispatch(setLinkPlay(''))
        dispatch(setIsShowSeason(false))
        if (modalSeasonRef && modalSeasonRef.current) {
          modalSeasonRef.current.disposeModal()
        }
      }

      history.goBack()
    }
  }


  const handleClickBack = () => {
    if (timeoutVar) {
      clearTimeout(timeoutVar)
    }
    history.goBack()
  }

  // useEffect(() => {
  //   console.log('options', options)
  // }, [options])

  const handleClickShowSeason = () => {
    setActive('season')
    dispatch(setIsShowSeason(true))
  }

  useKeyBoardPlayer({
    player,
    isBlockKeyboard,
    isLimit,
    isShowSeason,
    showInfo,
    active,
    timeoutVar,
    setIsShowSeason,
    playerData,
    status,

    isNextContent,
    isPrevContent,

    isLoadingContentSeason,

    increaseCount,
    createDebugModeCounter,
    eventListener,
    handleKeyLimit,
    returnExitEvent,
    setShowInfo,
    setTimeoutVar,
    setStatus,
    setActive,
    handleNextEps,
    handlePrevEps,
    handleLogProgress
  })

  const value = {
    modalSeasonRef,

    showInfo,
    active,
    isLimit,
    playerData,
    title,
    content,
    player,
    src,
    activeCwl,

    isDisplayDebugLog,
    bandWidth, 
    videoBitrateState,
    currentTimeDebug, 
    buffered, 
    videoBuffered, 
    seekable,
    bufferCount,
    currentSource,
    currentSegment,
    remoteDebugLogChannelIdRef,

    loadingLimit,
    loadingPlayer,


    handleRetry,
    handleClickBack,
    handleClickShowSeason,
    disposeVis,
    handlePlayerReady,
    handleEndVjs,


    //season
    setEpisode,
    setSeason,

    dataSeasonById,
    isLoadingSeasonById,
    isLoadingContentSeason,
    isShowSubscription
  }

  return <PlayerContext.Provider value={value}>{children}</PlayerContext.Provider>
}

PlayerProvider.propTypes = {
  children: PropTypes.object
}

PlayerProvider.defaultProps = {
  children: {}
}

export const usePlayerContext = () => {
  return useContext(PlayerContext)
}
