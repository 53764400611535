import videojs from 'video.js';
import ReactDOM from 'react-dom';
import LiveEventAfterReact from './components/after';

const Component = videojs.getComponent('Component');
const ModalDialog = videojs.getComponent('ModalDialog');

class LiveEventAfterDialog extends ModalDialog {
  constructor(player, options) {
    let options_ = videojs.mergeOptions({}, options);
    options_.uncloseable = true;

    super(player, options_);
    this.mount = this.mount.bind(this);

    this.mount();
    this.on('dispose', () => {
      ReactDOM.unmountComponentAtNode(this.el());
    });
    // this.show();
  }

  buildCSSClass() {
    return `vjs-live-event vjs-live-event-before ${super.buildCSSClass()}`;
  }

  mount() {
    const { content, handleClickBack } = this.options_;
    ReactDOM.render(<LiveEventAfterReact handleClickBack={handleClickBack} content={content} player={this.player_} />, this.el());
  }
}

Component.registerComponent('LiveEventAfterDialog', LiveEventAfterDialog);
export default LiveEventAfterDialog;
