import SplashScreen from 'components/SplashScreen'
import HomePage from 'features/home/pages/HomePage'
import LoginPage from 'features/home/pages/LoginPage'
import Profile from 'features/home/pages/ProfilePage'
import ExitPage from 'features/home/pages/ExitPage'
import Content from 'features/content/components/Content'
// import Player from 'features/player/components/Player'
import { PlayerWrapper } from 'features/player/views'
import PlayerLive from 'features/player/components/PlayerLive'
import PlayerEpg from 'features/player/components/PlayerEpg'
import Viewmore from 'features/viewmore/components/Viewmore'
import Season from 'features/season/components/Season'

const routes = [
  { path: '/', component: SplashScreen, exact: true },
  { path: '/login', component: LoginPage, exact: true },
  { path: '/profile', component: Profile, exact: true },
  { path: '/home', component: HomePage, exact: false },
  { path: '/exit', component: ExitPage, exact: false },
  { path: '/content', component: Content, exact: false },
  { path: '/viewmore', component: Viewmore, exact: false },
  { path: '/season', component: Season, exact: false },
  { path: '/player', component: PlayerWrapper, exact: false },
  { path: '/playerlive', component: PlayerLive, exact: false },
  { path: '/playerepg', component: PlayerEpg, exact: false }
]

export default routes
