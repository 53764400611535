import { osName } from 'react-device-detect'
import config from './config'
let verisonOs: any = ''
let manufacturerOS: any = ''

export interface windowInterface {
  platform?: string
  OSVersion?: string
  tizen?: any
  device?: any
  manufacturerOS?: any
  innerWidth?: any
}

export const getVerisonOs = () => {
  let osVer: any = ''
  if (verisonOs) return verisonOs?.toString()
  if ((window as windowInterface)?.tizen?.systeminfo) {
    osVer = (window as windowInterface)?.tizen?.systeminfo.getCapability(
      'http://tizen.org/feature/platform.version'
    )
  }

  if ((window as windowInterface).platform === 'tv_webos') {
    osVer = (window as windowInterface).OSVersion
  }
  verisonOs = osVer
  return osVer
}


export const getManufacturer = () => {
  let manufacturer: any = ''
  if (manufacturerOS) return manufacturerOS?.toString()
  if ((window as windowInterface)?.tizen?.systeminfo) {
    manufacturer = (window as windowInterface)?.tizen?.systeminfo.getCapability(
      'http://tizen.org/system/manufacturer'
    )
  }

  if ((window as windowInterface).platform === 'tv_webos') {
    manufacturer = (window as windowInterface)?.manufacturerOS
  }
  manufacturerOS = manufacturer
  return manufacturer
}

export const getUserAgent = () => {
  let agent = ""
  const packageName =
      (window as windowInterface).platform === 'tv_tizen'
      // ? 'vn.sctvonline.ctv.tizen'
      // : 'vn.sctvonline.ctv.webos'
      ? 'io.vimai.sctvonline.tizen'
      : 'io.vimai.sctvonline.webos'
  const device =
      ((window as windowInterface).platform === 'web'
          ? 'web'
          : (window as windowInterface).platform === 'tv_webos'
              ? 'LG'
              : 'SAMSUNG') || window.navigator.platform
  const OSName = osName
  const osVer = getVerisonOs()
  const deviceType = 'tv'
  const manufacturer = getManufacturer()
  const VERSION = config.version || '1.1.21'
  // console.log('VERSION', VERSION)
  // eslint-disable-next-line max-len
  agent = `${packageName}/${VERSION} (${OSName}/${osVer}; ${device}; ${deviceType}; ${manufacturer})`
  // console.log('------------------ userAgent', userAgent)
  // samsum : com.ondemandkorea.ctv.tizenos/2.29.0 (Tizen/6.0; SAMSUNG; tv)
  // LG: 'com.ondemandkorea.ctv.webos/2.29.0 (webOS/6.3.2; LG; tv)'
  if ((window as windowInterface).platform === 'web') {
    // agent = 'vn.sctvonline.ctv.webos/1.19.0 (webOS/6.3.2; LG; tv)'
    agent = 'io.vimai.sctvonline.webos/1.19.0 (webOS/6.3.2; LG; tv)'
  }
    
  return agent
}

