/* eslint-disable max-len */
import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from 'react-query'
import { keyCode, getData, scrollVertical, findCurrentMenuIndex, addRemoteLogging, removeRemoteLogging, addShakaLib } from 'common/utils'
import { toggleOpenMenu, toggleNavigateMenu, toggleNavigateRibbon, setRibbonSelected } from 'common/slice'
import { setSearchTextReducer } from 'features/search/searchSlice'
import { setIsShowLogin } from 'features/home/loginSlice'
import { setMenu, setCurrentMenu, setMenuList, setPreSelectedMenu, setIsEnbaleJSDebugMode } from '../menuSlice'
import config from '../../../common/config'
import useProfile from 'hooks/useProfile'
import { icSearch, icUser } from '../../../constant/images'
import { DEFAULT_MENU_INDEX } from '../../../constant/settings'
import '../styles/menu.scss'

import MenuOpen from './MenuOpen'
import MenuClose from './MenuClose'

const Menu = () => {
  const dispatch = useDispatch()
  const enterCountRef = useRef(null)
  const requestProfile = useRef(false)
  const indexCurrentIdxMenu = useRef(DEFAULT_MENU_INDEX)
  const timeoutResetEnterCountRef = useRef(null)
  const { getProfile } = useProfile()

  const navigateMenu = useSelector((state) => state.root.navigateMenu)
  const openMenu = useSelector((state) => state.root.openMenu)
  const currentMenu = useSelector((state) => state.menu.currentMenu)
  const isShowLogin = useSelector((state) => state.login.isShowLogin)
  const account = useSelector((state) => state.login.account)
  const isLogin = useSelector((state) => state.login.isLogin)
  const menuList = useSelector((state) => state.menu.menuList)

  const isEnbaleJSDebugMode = useSelector((state) => state.menu.isEnbaleJSDebugMode)

  const [data, setData] = useState([])
  const [active, setActive] = useState(DEFAULT_MENU_INDEX)
  const [focusMenuIndex, setFocusMenuIndex] = useState(DEFAULT_MENU_INDEX)

  const { data: dataMenu } = useQuery('getMenuId', async () => {
    return await getData('GET', `menu/${config.rootMenu}`)
  })

  // Trả về trạng thái navigate menu
  // Nếu đúng mới attach các event
  const eventListener = useCallback(() => {
    return navigateMenu
  }, [data, active, navigateMenu, isShowLogin, account, isLogin, currentMenu, focusMenuIndex, isEnbaleJSDebugMode])

  useEffect(() => {
    if (eventListener()) {
      document.addEventListener('keydown', handleKey)
      return () => {
        document.removeEventListener('keydown', handleKey)
      }
    }
  }, [eventListener])

  useEffect(() => {
    if (dataMenu) {
      const dataTemp = [...dataMenu]

      let accountButtonText = 'Tài khoản'
      if (account) {
        accountButtonText = account.profile.first_name || account.profile.email || account.profile.phone_number
      }

      dataTemp.splice(0, 0, {
        icon: icUser,
        id: 'login',
        name: accountButtonText,
        slug: 'login',
        sub_menu: []
      })

      dataTemp.splice(1, 0, {
        icon: icSearch,
        id: 'search',
        name: 'Tìm Kiếm',
        slug: 'search',
        sub_menu: []
      })

      dispatch(setMenuList(dataTemp))

      if (currentMenu) {
        dispatch(setMenu(currentMenu))
        const currentMenuIndex = findCurrentMenuIndex(dataTemp, currentMenu)
        setActive(currentMenuIndex)
        setFocusMenuIndex(currentMenuIndex)
      } else {
        dispatch(setMenu(dataTemp[DEFAULT_MENU_INDEX]))
        setActive(DEFAULT_MENU_INDEX)
        setFocusMenuIndex(DEFAULT_MENU_INDEX)
        dispatch(setCurrentMenu(dataTemp[DEFAULT_MENU_INDEX]))
      }
      setData(dataTemp)
    }
  }, [dataMenu?.length, isLogin])

  useEffect(() => {
    addShakaLib()
  }, [])

  useEffect(() => {
    if (getProfile?.isLoading) {
      requestProfile.current = true
    }
    if (currentMenu?.slug !== 'search') {
      dispatch(setSearchTextReducer(''))
    }
    if (isLogin && currentMenu?.slug !== 'login' && !requestProfile.current) {
      getProfile.mutateAsync()
    }
    if (!getProfile?.isLoading) {
      requestProfile.current = false
    }
  }, [currentMenu?.slug, getProfile?.isLoading])


  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */
  const handleClickMenu = (item, index) => {
    if (index === 0) {
      dispatch(setIsShowLogin(true))
      setFocusMenuIndex(indexCurrentIdxMenu.current)
    } else {
      indexCurrentIdxMenu.current = index
      setActive(index)
      dispatch(setPreSelectedMenu(menuList[index]))
      dispatch(setMenu(item))
      dispatch(setCurrentMenu(item))
      dispatch(setRibbonSelected(null))
    }
  }

  // useEffect(() => {
  //   if (currentMenu) {
  //     const currentMenuIndex = findCurrentMenuIndex(menuList, currentMenu)
  //     setActive(currentMenuIndex)
  //     setFocusMenuIndex(currentMenuIndex)
  //   }
  // }, [currentMenu])

  const increaseCount = () => {
    enterCountRef.current++
  }

  const createDebugModeCounter = () => {
    if (enterCountRef.current >= 10) {
      enterCountRef.current = 0
      if (timeoutResetEnterCountRef.current) {
        clearTimeout(timeoutResetEnterCountRef.current)
        timeoutResetEnterCountRef.current = null
      }

      if (isEnbaleJSDebugMode) {
        dispatch(setIsEnbaleJSDebugMode(false))
        removeRemoteLogging()
      } else {
        dispatch(setIsEnbaleJSDebugMode(true))
        const advertisingId = localStorage.getItem('advertisingId')
        addRemoteLogging(advertisingId)
      }
    } else {
      if (timeoutResetEnterCountRef.current) {
        clearTimeout(timeoutResetEnterCountRef.current)
        timeoutResetEnterCountRef.current = null
      }

      timeoutResetEnterCountRef.current = setTimeout(() => {
        enterCountRef.current = 0
      }, 1000)
    }
  }

  const handleKey = (e) => {
    e.preventDefault()

    if (!isShowLogin) {
      switch (e.keyCode) {
        // Navigate up menu
        case keyCode.UP: {
          if (focusMenuIndex > 0) {
            const menuFocusIdx = focusMenuIndex - 1
            scrollVertical('menu-scroll', `menu-item-${menuFocusIdx}`, 'UP')
            setFocusMenuIndex(menuFocusIdx)
          }
          break
        }
        // Navigate down menu
        case keyCode.DOWN: {
          if (focusMenuIndex < (data.length - 1)) {
            const menuFocusIdx = focusMenuIndex + 1
            scrollVertical('menu-scroll', `menu-item-${menuFocusIdx}`, 'DOWN')
            setFocusMenuIndex(menuFocusIdx)
          }
          break
        }
        // Từ menu --> ribbon
        case keyCode.RIGHT: {
          dispatch(toggleOpenMenu())
          dispatch(toggleNavigateMenu())
          dispatch(toggleNavigateRibbon())
          break
        }
        case keyCode.LEFT: {
          // increaseCount()
          // createDebugModeCounter()
          break
        }
        case keyCode.ENTER: {
          if (openMenu) {
            handleClickMenu(menuList[focusMenuIndex], focusMenuIndex)
          }
          break
        }
      }
    }
  }

  const handleMouseOverMenu = () => {
    if (!navigateMenu) {
      dispatch(toggleOpenMenu())
      dispatch(toggleNavigateMenu())
      dispatch(toggleNavigateRibbon())
    }
  }
  const handleMouseLeaveMenu = () => {
    if (navigateMenu) {
      dispatch(toggleOpenMenu())
      dispatch(toggleNavigateMenu())
      dispatch(toggleNavigateRibbon())
    }
  }

  return (
    <div id="menu" className="menu col-auto col-md-1 col-xl-1 px-sm-2 px-0 sidebar-wrapper"
      onMouseLeave={handleMouseLeaveMenu}>

      {openMenu
        ? (
          <MenuOpen
            handleMouseLeaveMenu={handleMouseLeaveMenu}
            handleClickMenu={handleClickMenu}
            handleMouseOverMenu={handleMouseOverMenu}
            data={data}
            focusMenuIndex={focusMenuIndex}
            active={active}
          />
          )
        : (
          <MenuClose
            handleClickMenu={handleClickMenu}
            handleMouseOverMenu={handleMouseOverMenu}
            data={data}
            focusMenuIndex={focusMenuIndex}
            active={active} 
          />
        )
      }
    </div>
  )
}

export default Menu
