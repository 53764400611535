import React, { useEffect, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { keyCode } from 'common/utils'
import { setIsShowLogin } from 'features/home/loginSlice'
import '../styles/exit.scss'

const ExitPage = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [active, setActive] = useState('exit')

  const eventListener = useCallback(() => {
  }, [active])

  useEffect(() => {
    document.addEventListener('keydown', handleKey)
    return () => {
      document.removeEventListener('keydown', handleKey)
    }
  }, [eventListener])

  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */
  const handleKey = (e) => {
    e.preventDefault()

    switch (e.keyCode) {
      case keyCode.ENTER: {
        if (active === 'exit') {
          if (window.platform === 'web') {
            window.location.reload()
          } else if (window.platform === 'tv_webos') {
            window.close()
          } else {
            // eslint-disable-next-line no-undef
            tizen.application.getCurrentApplication().exit()
          }
        }
        if (active === 'cancel') {
          dispatch(setIsShowLogin(false))
          history.goBack()
        }
        break
      }
      case keyCode.LEFT: {
        if (active === 'exit') {
          setActive('cancel')
        }
        if (active === 'cancel') {
          setActive('exit')
        }
        break
      }
      case keyCode.RIGHT: {
        if (active === 'exit') {
          setActive('cancel')
        }
        if (active === 'cancel') {
          setActive('exit')
        }
        break
      }
    }
  }

  const handleClickExit = () => {
    if (window.platform === 'web') {
      window.location.reload()
    } else if (window.platform === 'tv_webos') {
      window.close()
    } else {
      // eslint-disable-next-line no-undef
      tizen.application.getCurrentApplication().exit()
    }
  }

  const handleClickCancel = () => {
    dispatch(setIsShowLogin(false))
    history.goBack()
  }

  return (
    <div className="container-fluid">
      <div className="exit-modal-container">
        <p className="exit-modal-text">Bạn muốn thoát ứng dụng?</p>

        <div className="text-center exit-button-container">
          <button
            type="button"
            className={`btn ${active === 'exit' ? 'exit-button-bg-active' : 'exit-button-bg'} me-2 exit-button`}
            onClick={handleClickExit}
          >
            THOÁT
          </button>
          <button
            type="button"
            className={`btn ${active === 'cancel' ? 'exit-button-bg-active' : 'exit-button-bg'} ms-2 exit-button`}
            onClick={handleClickCancel}
          >
            HỦY
          </button>
        </div>
      </div>
    </div>
  )
}

export default ExitPage
