import { PlayerProvider } from 'contexts/playerContext'
import Player from '../components/Player'

export const PlayerWrapper = props => {
  return (
    <PlayerProvider {...props}>
        <Player {...props} />
    </PlayerProvider>
  )
}
