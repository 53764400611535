

const MenuClose = ({
  handleClickMenu,
  handleMouseOverMenu,
  data,
  focusMenuIndex,
  active
}) => {
  return (
    <div id="menu-scroll" className="d-flex flex-column align-items-center align-items-sm-start pt-2 text-white min-vh-100 menu-scroll menu-scroll-remove-padding-left">
    <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start menu-margin-top" id="menu">
      {data && data.map((item, index) => {
        return (
          <li id={`menu-item-${index}`} key={index} className="nav-item">
            <a
              className={`nav-link align-middle px-0 ${index === focusMenuIndex ? 'active' : ''}`}
              onClick={(e) => handleClickMenu( item, index)}
              onMouseOver={handleMouseOverMenu}
            >
              {active === index && (<div className="nav-item-active" />)}
              <img className="sidebar-img" src={item.icon} width="25" height="25" />
            </a>
          </li>
        )
      })}
    </ul>
  </div>
  )
}

export default MenuClose
