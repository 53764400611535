import { memo } from 'react'
import { imgholder, HomePlayIconWhite, HomePlayIconBlack} from 'constant/images'
import AgeRatingBadge from 'components/badge/ageContentRating'


const AgeRating = ({ contentRatings }) => {
  if (!contentRatings?.length) return null
  return contentRatings.map((items, index) => {
    if(!items?.image_url) return null
    return (
      <div className='ribbon-banner-age-rating-badge'
        key={`ribbon-banner-age-rating-badge-${index}`}
      >
        <img
          className='ribbon-banner-age-rating-badge-image'
          src={items?.image_url}
        />
      </div>
    )
  })
}

const Banner = (props) => {
  const {
    banners,
    bannerRef,
    handleClickBanner,
    active,
    isLoading
  } = props

  if (!isLoading && banners && banners.length > 0) {
    return (
      <div ref={bannerRef} className="carousel slide carousel-fade ps-2 pe-2 ribbon-banner">
        <div className='carousel-inner'>
          <div onClick={handleClickBanner} className={`banner-button-watch ${active.item === 'banner' ? 'active' : ''}`}>
            <img
              src={active.tag === 'banner' ? HomePlayIconWhite : HomePlayIconBlack}
            />
            <span>Xem Ngay</span>
          </div>
          {banners && banners.slice(0 , 6).map((item, index) => {
            let banner = item.images ? (item.images.banner && item.images.banner) !== '' ? item.images.banner : '' : ''

            return (
              <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                <div className='ribbon-banner-image-wrapper'>
                  <img
                    className="ribbon-banner-image"
                    // eslint-disable-next-line no-unneeded-ternary
                    src={banner ? banner : imgholder }
                    onError={(e) => {
                      e.target.onerror = null
                      e.target.src = imgholder
                    }}
                  />
                </div>
                <AgeRatingBadge content={item} clss='banner' />
              </div>
            )
          })}
        </div>
      </div>
    )
  }
  return null
}

export default memo(Banner)

