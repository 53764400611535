// import { isDVRRewatchEnabled } from 'core/method/contentMethod';
import '../styles/after.scss'

const LiveEventAfterReact = (props)  => {
  const { content, handleClickBack } = props;
  // const rewatch = isDVRRewatchEnabled.call(content);
  // const handleClick = () => {
  //   player?.liveEvent && player.liveEvent().trigger('rewatch');
  // };

  return (
    <div className="live-event-after" >
      <div className="vjs-live-event-react">
        <div className="title">TRỰC TIẾP KẾT THÚC</div>
        <div className="content-name">{content?.title}</div>
        <div className="txt">Cảm ơn bạn đã theo dõi</div>
        {/* {rewatch && (
          <button onClick={handleClick} className="rewatch">
            xem lại
          </button> 
        )} */}
      </div>
    </div>
  );
}

export default LiveEventAfterReact;
