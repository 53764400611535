import { useEffect, useCallback, forwardRef, useState, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { keyCode } from 'common/utils'
import Modal from 'bootstrap/js/dist/modal'
import '../styles/forcedLogout.scss'
import { setShowWarningForcedLogout } from 'features/modal/modalSlice'
import useProfile from 'hooks/useProfile'


const ForcedLogout = forwardRef((props, ref) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { handleLogout } = useProfile()
  
  const isShowWarningForcedLogout = useSelector((state) => state.modal.isShowWarningForcedLogout)

  const [modal, setModal] = useState(null)

  const eventListener = useCallback(() => {
  }, [isShowWarningForcedLogout])

  useEffect(() => {
    document.addEventListener('keydown', handleKey)
    return () => {
      document.removeEventListener('keydown', handleKey)
    }
  }, [eventListener])

  useEffect(() => {
    if (ref && ref.current) {
      setModal(new Modal(ref.current, {
        backdrop: 'static'
      }))
    }
  }, [ref])

  useEffect(() => {
    if (modal) {
      ref.current.disposeModal = () => {
        if (modal._element) {
          modal.dispose()
        }
      }
    }

    return () => {
      if (modal && modal._element) {
        modal.dispose()
      }
    }
  }, [modal])

  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */
  const handleKey = (e) => {
    e.preventDefault()
    if (!isShowWarningForcedLogout) return

    switch (e.keyCode) {
      case keyCode.RETURN:
      case keyCode.ENTER: {
        handleClickClose()
        break
      }
    }
  }

  const handleClickClose = () => {
    handleLogout()
    history.push('/home')
    setTimeout(() => {
      dispatch(setShowWarningForcedLogout(false))
    }, 1000)
  }

  useEffect(() => {
    if (isShowWarningForcedLogout) {
      if (modal && modal._element) {
        modal.show()
      }
    } else {
      if (modal && modal._element) {
        modal.hide()
      }
    }
  }, [isShowWarningForcedLogout, modal])

  useEffect(() => {
    document.addEventListener('force-logout', () => {
      dispatch(setShowWarningForcedLogout(true))
    })
    return () => {
      document.removeEventListener('force-logout', () => {
        dispatch(setShowWarningForcedLogout(false))
      })
    }
  }, [])


  return (
    <div ref={ref} className="modal" tabIndex="-1">
      <div className="modal-dialog modal-fullscreen">
        <div id="modal-forced-logout" className="modal-forced-logout">
          <div id="modal-forced-logout-body" className="modal-body modal-forced-logout-background">
            <div className="container-fluid">
              <div className='modal-forced-logout-container'>
                <div className='modal-forced-logout-header'>
                  <h3 className='title'>Thiết bị của bạn đã bị đăng xuất</h3>
                </div>
                <div className='modal-forced-logout-body'>
                  <p className='description'>
                    Tài khoản của bạn trên thiết bị này đã bị đăng xuất bởi một thiết bị khác. Vui lòng đăng nhập để tiếp tục trải nghiệm dịch vụ.
                  </p>
                </div>
                <div className="text-center modal-forced-logout-actions">
                  <button
                    type="button"
                    className="btn modal-forced-logout-btn"
                    onClick={() => handleClickClose()}
                  >
                    Đồng ý
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

ForcedLogout.displayName = 'ForcedLogout'

function isEqual(prevProps, nextProps) {
  return (
    prevProps.isShowWarningForcedLogout === nextProps.isShowWarningForcedLogout
  )
}

export default memo(ForcedLogout, isEqual)
