import  { useEffect, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useQuery } from 'react-query'
import { keyCode, getDataTenant, formatDataChunk, scrollVertical, checkVipBadge } from 'common/utils'
import { toggleOpenMenu, toggleNavigateMenu, toggleNavigateRibbon } from 'common/slice'
import { setSearchTextReducer } from 'features/search/searchSlice'

import { icSpace } from '../../../constant/images'
import '../styles/search.scss'
import config from '../../../common/config'
import { setIsLoadingOverlay } from 'features/modal/modalSlice'
import useFollowUpgrade from 'hooks/useFollowUpgrade'
import useBlockKeyboard from 'hooks/useBlockKeyboard'

import Thumbnail from 'components/thumbnail'

const Search = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const openMenu = useSelector((state) => state.root.openMenu)
  const navigateRibbon = useSelector((state) => state.root.navigateRibbon)
  const login = useSelector((state) => state.login)
  const isShowSubscription = useSelector((state) => state.subscription.isShowSubscription)
  const isShowWarningLogin = useSelector((state) => state.modal.isShowWarningLogin)
  const isShowLogin = useSelector((state) => state.login.isShowLogin)
  const isLoadingOverlay = useSelector((state) => state.modal.isLoadingOverlay)
  const searchTextReducer = useSelector((state) => state.search.searchText)

  const [data, setData] = useState([])
  const [active, setActive] = useState({
    tag: 'alphabet',
    category: 0,
    item: 0
  })
  const [selectedContent, setSelectedContent] = useState({})
  const [searchText, setSearchText] = useState(searchTextReducer)
  const [infoText, setInfoText] = useState('Tìm kiếm phim, diễn viên, ...')

  const listAlphabet = [
    ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l'],
    ['m', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x'],
    ['y', 'z', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
  ]
  const { handleClick, isLoadingUpgrade } = useFollowUpgrade()
  const { isBlockKeyboard } = useBlockKeyboard()

  const { data: dataSearch } = useQuery(['getSearch', searchText], async () => {
    if (searchText !== '') {
      const keyword = encodeURIComponent(searchText.trim())
      return await getDataTenant('GET', `search/?q=${keyword}&page=0&limit=20&tenant_slug=${config.tenantSlug}`)
    }
  })

  const eventListener = useCallback(() => {
    return navigateRibbon
  }, [data, active, searchText, navigateRibbon, isShowSubscription, isShowWarningLogin, isShowLogin, isLoadingOverlay, isBlockKeyboard])

  useEffect(() => {
    if (isLoadingUpgrade) {
      return dispatch(setIsLoadingOverlay(true))
    }
    dispatch(setIsLoadingOverlay(false))
  }, [isLoadingUpgrade])
  
  useEffect(() => {
    if (eventListener()) {
      document.addEventListener('keydown', handleKey)
      return () => {
        document.removeEventListener('keydown', handleKey)
      }
    }
  }, [eventListener])

  useEffect(() => {
    if (dataSearch) {
      if (dataSearch.items.length === 0) {
        setInfoText('Không tìm thấy kết quả phù hợp')
      }

      const dataTemp = formatDataChunk(dataSearch, 4)
      setData(dataTemp)
    }
  }, [dataSearch])

  useEffect(() => {
    if (searchText === '') {
      setInfoText('Tìm kiếm phim, diễn viên, ...')
    }
  }, [searchText])

  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */
  const handleKey = (e) => {
    e.preventDefault()
    if (isBlockKeyboard || isShowSubscription || isShowWarningLogin || isShowLogin || isLoadingOverlay) return

    switch (e.keyCode) {
      case keyCode.ENTER: {
        if (active.tag === 'alphabet') {
          if (active.category === 'action') {
            if (active.item === 'space') {
              setSearchText(searchText.concat(' '))
            }
            if (active.item === 'backspace') {
              setSearchText(searchText.slice(0, -1))
            }
          } else {
            setSearchText(searchText.concat(listAlphabet[active.category][active.item]))
          }
        } else if (active.tag === 'search_result') { // navigate search result
          const ribbonItem = data[active.category] && data[active.category][active.item]
          setSelectedContent(ribbonItem)
          const isBadge = checkVipBadge(ribbonItem, login)
          checkContent(ribbonItem, isBadge)
          dispatch(setSearchTextReducer(searchText))

          // dispatch(setRibbonContent(ribbonItem))
          // history.push('/content')
        }
        break
      }
      // Từ search result --> alphabet
      // Navigate alphabet
      // Navigate search result
      case keyCode.UP: {
        if (active.tag === 'alphabet') {
          if (active.category > 0) {
            setActive({
              tag: 'alphabet',
              category: active.category - 1,
              item: active.item
            })
          }
          if (active.category === 'action') {
            setActive({
              tag: 'alphabet',
              category: 2,
              item: 0
            })
          }
        } else if (active.tag === 'search_result') { // navigate search result
          if (active.category === 0) {
            setActive({
              tag: 'alphabet',
              category: 'action',
              item: 'space'
            })
          } else {
            if (active.category > 0) {
              if (active.category === 1) {
                scrollVertical('search', 'search', 'UP')
              } else {
                scrollVertical('search', `search-container-${active.category - 1}`, 'UP')
              }
              setActive({
                tag: 'search_result',
                category: active.category - 1,
                item: active.item
              })
            }
          }
        }
        break
      }
      // Từ alphabet --> search result
      // Navigate alphabet
      // Navigate search result
      case keyCode.DOWN: {
        if (active.tag === 'alphabet') {
          if (active.category === 'action' && data && data.length > 0) {
            setActive({
              tag: 'search_result',
              category: 0,
              item: 0
            })
          } else {
            if (active.category < 2) {
              setActive({
                tag: 'alphabet',
                category: active.category + 1,
                item: active.item
              })
            } else {
              setActive({
                tag: 'alphabet',
                category: 'action',
                item: 'space'
              })
            }
          }
        } else if (active.tag === 'search_result') { // navigate search result
          if (active.category < (data.length - 1)) {
            scrollVertical('search', `search-container-${active.category + 1}`, 'DOWN')
            setActive({
              tag: 'search_result',
              category: active.category + 1,
              item: active.item
            })
          }
        }
        break
      }
      // Từ nội dung --> menu
      // Navigate alphabet
      // Navigate search result
      case keyCode.LEFT: {
        if (active.tag === 'alphabet') {
          if (active.category === 'action') {
            if (active.item === 'space') {
              dispatch(toggleOpenMenu())
              dispatch(toggleNavigateMenu())
              dispatch(toggleNavigateRibbon())
            } else if (active.item === 'backspace') {
              setActive({
                tag: 'alphabet',
                category: 'action',
                item: 'space'
              })
            }
          } else { // không phải nút space, backspace
            if (active.item === 0) {
              dispatch(toggleOpenMenu())
              dispatch(toggleNavigateMenu())
              dispatch(toggleNavigateRibbon())
            } else {
              setActive({
                tag: 'alphabet',
                category: active.category,
                item: active.item - 1
              })
            }
          }
        } else if (active.tag === 'search_result') { // navigate search result
          if (active.item === 0) {
            dispatch(toggleOpenMenu())
            dispatch(toggleNavigateMenu())
            dispatch(toggleNavigateRibbon())
          } else {
            setActive({
              tag: 'search_result',
              category: active.category,
              item: active.item - 1
            })
          }
        }
        break
      }
      // Navigate alphabet
      // Navigate search result
      case keyCode.RIGHT: {
        if (active.tag === 'alphabet') {
          if (active.category === 'action') {
            if (active.item === 'space') {
              setActive({
                tag: 'alphabet',
                category: 'action',
                item: 'backspace'
              })
            }
          } else { // không phải nút space, backspace
            if (active.item < 11) {
              setActive({
                tag: 'alphabet',
                category: active.category,
                item: active.item + 1
              })
            }
          }
        } else if (active.tag === 'search_result') { // navigate search result
          if (active.item < (data[active.category] ? (data[active.category].length - 1) : 2)) {
            setActive({
              tag: 'search_result',
              category: active.category,
              item: active.item + 1
            })
          }
        }
        break
      }
    }
  }

  const handleClickAlphabet = (e, index, indexChild) => {
    setSearchText(searchText.concat(listAlphabet[index][indexChild]))
  }

  const handleClickSpace = () => {
    setSearchText(searchText.concat(' '))
  }

  const handleClickBackspace = () => {
    setSearchText(searchText.slice(0, -1))
  }

  const handleClickResult = (e, index, indexChild, isBadge) => {
    const ribbonItem = data[index] && data[index][indexChild]

    setSelectedContent(ribbonItem)
    checkContent(ribbonItem, isBadge)
    dispatch(setSearchTextReducer(searchText))
  }

  const checkContent = (ribbonItem, isBadge) => {
    handleClick(ribbonItem, isBadge)
  }

  return (
    // <div id="search" className={`col-auto col-md-${openMenu ? '8' : '11'} col-xl-${openMenu ? '9' : '11'} search`}>
    <div id="search" className={`col-auto col-md-${openMenu ? '11' : '11'} col-xl-${openMenu ? '11' : '11'} search`}>
      <div className="row">
        <div className="col-md-12">
          <input className="form-control mb-5" value={searchText} onChange={() => {}} />
        </div>
      </div>

      {listAlphabet.map((item, index) => {
        return (
          <div key={index} className="row search-alphabet-container">
            {item.map((itemChild, indexChild) => {
              return (
                <div
                  key={indexChild}
                  className={`col-md-1 search-alphabet \
                    ${(!openMenu && active.tag === 'alphabet' && active.category === index && active.item === indexChild) ? 'active' : ''} \
                    ${indexChild === item.length - 1 ? 'search-border-right' : ''} \
                    ${index === listAlphabet.length - 1 ? 'search-border-bottom' : ''}`
                  }
                  onClick={e => handleClickAlphabet(e, index, indexChild)}
                >
                  {itemChild}
                </div>
              )
            })}
          </div>
        )
      })}

      <div className="row ps-5 pe-5">
        <div className="col-md-5 offset-md-5">
          <div
            className={`d-inline-block me-4 ps-4 pe-4 search-alphabet-action ${(!openMenu && active.tag === 'alphabet' && active.category === 'action' && active.item === 'space') ? 'active' : ''}`}
            onClick={handleClickSpace}
          >
            <img src={icSpace} />
          </div>
          <div
            className={`d-inline-block ms-4 ps-4 pe-4 search-alphabet-action ${(!openMenu && active.tag === 'alphabet' && active.category === 'action' && active.item === 'backspace') ? 'active' : ''}`}
            onClick={handleClickBackspace}
          >
            Xóa
          </div>
        </div>
      </div>

      {(data && data.length === 0) &&
        <div className="row">
          <div className="col col-md-12 search-info-text">
            {infoText}
          </div>
        </div>
      }

      <div className="row mt-4">
        <div className="col col-md-12">
          {data && data.map((item, index) => {
            return (
              <div id={`search-container-${index}`} key={index} className={`row ${index === 0 ? 'mt-4' : 'mt-4'}`}>
                {item && item.map((itemChild, indexChild) => {
                  const isBadge = checkVipBadge(itemChild, login)
                  return (
                    <div
                      key={indexChild}
                      className="col col-md-3 ps-2 pe-2 search-item-wrapper"
                      onClick={e => handleClickResult(e, index, indexChild, isBadge)}
                    >
                      <Thumbnail
                        isBadge={isBadge}
                        itemChild={itemChild}
                        clss={`search-result-item ${(!openMenu && active.tag === 'search_result' && active.category === index && active.item === indexChild) ? 'active' : ''}`}
                      />
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>

    </div>
  )
}

export default Search
