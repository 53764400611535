import { useRef } from 'react';
import Spinner from 'components/Spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import '../styles/cwl.scss'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Cwl = ({ handleRetry, loadingLimit, activeCwl}) => {
  const nodeRef = useRef(null);

  return (
    <div className='vjs-cwl'>
      <div className="player-loading">
        {loadingLimit ? <Spinner /> : null}
      </div>
      <div className='vjs-cwl-back'>
        <FontAwesomeIcon
          className={`back ${activeCwl === 'back' ? 'active' : ''}`}
          icon={faChevronLeft}
        />
      </div>
      <div ref={nodeRef} className="vjs-cwl-container">
        <h3 className="title">Số thiết bị xem vượt giới hạn</h3>
        <p className="description">Tài khoản của bạn đã vượt quá số lượng thiết bị xem đồng thời. Vui lòng đóng ứng dụng/trình duyệt trên thiết bị khác và thử lại.</p>
        <button
          className={`btn vjs-cwl-btn ${activeCwl === 'btn' ? 'active' : ''}`} onClick={() => handleRetry()}>Thử Lại</button>
      </div>
    </div>
  );
}

export default Cwl;
