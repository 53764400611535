/* eslint-disable no-undef */
const config = {
  domain: process.env.REACT_APP_API_BASE_URL,
  domainRoot: process.env.REACT_APP_API_BASE_URL_ROOT,
  tenantSlug: window.tenant ? window.tenant : process.env.REACT_APP_TENANT_SLUG,
  rootMenu: process.env.REACT_APP_ROOT_MENU,
  appEnv: process.env.REACT_APP_ENV,
  ga4TizenMeasurementId: process.env.REACT_APP_GA4_TIZEN_MEASUREMENT_ID,
  ga4TizenApiSecret: process.env.REACT_APP_GA4_TIZEN_API_SECRET,
  ga4WebOSMeasurementId: process.env.REACT_APP_GA4_WEBOS_MEASUREMENT_ID,
  ga4WebOSApiSecret: process.env.REACT_APP_GA4_WEBOS_API_SECRET,
  version: process.env.REACT_APP_VERSION,
  tenantPlatform: process.env.REACT_APP_TENANT_PLATFORM,
  versionRelease: process.env.REACT_APP_VERSION_PRERELEASE
}

export default config
