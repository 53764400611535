/* eslint-disable no-undef */
// const rootImage = process.env.REACT_APP_ENV === 'PROD' ? process.env.PUBLIC_URL :  process.env.REACT_APP_DOMAIN || process.env.PUBLIC_URL
const rootImage = process.env.REACT_APP_DOMAIN

export const imgholder = `${rootImage}/static/media/imgholder.png`
export const viewmore = `${rootImage}/static/media/viewmore.png`
export const icSearch = `${rootImage}/static/media/ic_search.png`
export const logMenu = `${rootImage}/static/media/logo-menu.png`
export const icSpace = `${rootImage}/static/media/ic_space.png`
export const icInfo = `${rootImage}/static/media/ic_info.png`
export const icUser = `${rootImage}/static/media/ic_user.png`
export const icHome = `${rootImage}/static/media/ic_home.png`
export const icTelevison = `${rootImage}/static/media/ic_televison.png`
export const icLive = `${rootImage}/static/media/ic_live.png`
export const logoSplashscreen = `${rootImage}/static/media/logo_splashscreen.png`
export const star = `${rootImage}/static/media/contents/star.svg`

export const top1 = `${rootImage}/static/media/top/1.png`
export const top2 = `${rootImage}/static/media/top/2.png`
export const top3 = `${rootImage}/static/media/top/3.png`
export const top4 = `${rootImage}/static/media/top/4.png`
export const top5 = `${rootImage}/static/media/top/5.png`
export const top6 = `${rootImage}/static/media/top/6.png`
export const top7 = `${rootImage}/static/media/top/7.png`
export const top8 = `${rootImage}/static/media/top/8.png`
export const top9 = `${rootImage}/static/media/top/9.png`
export const top10 = `${rootImage}/static/media/top/10.png`
export const topNumber = `${rootImage}/static/media/top/`
export const contentImg = `${rootImage}/static/media/content/`

export const logoMenu = `${rootImage}/static/media/menu_logo/sctv.png`
export const HomePlayIconWhite = `${rootImage}/static/media/home/play-white.svg`
export const HomePlayIconBlack = `${rootImage}/static/media/home/play-black.svg`
export const LogoLogin = `${rootImage}/static/media/login/iconModal.png`

//player
export const seek = `${rootImage}/static/media/player/seek.svg`
export const back = `${rootImage}/static/media/player/back-white.svg`
export const backActive = `${rootImage}/static/media/player/back-active.svg`
export const season = `${rootImage}/static/media/player/season-white.svg`
export const seasonActive = `${rootImage}/static/media/player/season-active.svg`

export const spinnerHDGif = `${rootImage}/static/media/spinerHD.svg`
export const spinnerRedHDGif = `${rootImage}/static/media/spinerRedHD.svg`
// export const spinnerHDGif = `./static/media/spinerHD.svg`
// export const spinnerRedHDGif = `./static/media/spinerRedHD.svg`