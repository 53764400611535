const CONTENT_TYPE = {
  MOVIE : 1,
  SHOW : 2,
  SEASON: 3,
  EPISODE : 4,
  LIVE : 5,
  CLIP : 6,
  CURATED_LIVE : 7,
  TRAILER : 8,
  LIVE_EVENT : 9,
  LIVE_PREMIERE : 'content-premiere'
}

const MOVIE = CONTENT_TYPE.MOVIE
const SHOW = CONTENT_TYPE.SHOW
const SEASON= CONTENT_TYPE.SEASON
const EPISODE = CONTENT_TYPE.EPISODE
const LIVE = CONTENT_TYPE.LIVE
const CLIP = CONTENT_TYPE.CLIP
const CURATED_LIVE = CONTENT_TYPE.CURATED_LIVE
const TRAILER = CONTENT_TYPE.TRAILER
const LIVE_EVENT = CONTENT_TYPE.LIVE_EVENT
const LIVE_PREMIERE = CONTENT_TYPE.LIVE_PREMIERE

const CONTENT_TYPE_FOR_VOD = [MOVIE, SHOW, CLIP, TRAILER]
const CONTENT_TYPE_FOR_LIVE = [LIVE, CURATED_LIVE, LIVE_EVENT, LIVE_PREMIERE]

export {
  MOVIE,
  SEASON,
  SHOW,
  EPISODE, 
  CLIP,
  TRAILER,
  LIVE_EVENT,
  LIVE,
  LIVE_PREMIERE,
  CONTENT_TYPE_FOR_LIVE,
  CONTENT_TYPE_FOR_VOD,
  CONTENT_TYPE,
}