import { useQuery } from 'react-query'
import {  getDataTenant } from 'common/utils'

const useFetchPlayer = ({ ribbonLive }) => {
  const { data: dataLive, isLoading } = useQuery(['getContent', ribbonLive], async () => {
    if (ribbonLive && ribbonLive.slug) {
      return await getDataTenant('GET', `contents/${ribbonLive.slug}/view?platform=ctv`)
    }
  })

  const { data: dataLiveDetail, isLoading: isLoadingDetail } = useQuery(['getContentDetail', ribbonLive], async () => {
    if (ribbonLive && ribbonLive.id) {
      return await getDataTenant('GET', `contents/${ribbonLive.id}/detail`)
    }
  })


  return {
    dataLive,
    dataLiveDetail,

    isLoading,
    isLoadingDetail
  }
}

export default useFetchPlayer
