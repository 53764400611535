import React from 'react'
import './styles/new-badge.scss'

const NewReleaseBadge = (props) => {
  // eslint-disable-next-line react/prop-types
  const { isViewMore } = props

  return (
    <div className={isViewMore ? 'ribbon-new-badge-viewmore' : 'ribbon-new-badge'}>Mới</div>
  )
}

export default NewReleaseBadge
