import { memo } from 'react'
import { imgholder, topNumber } from 'constant/images'

import NewReleaseBadge from 'components/NewReleaseBadge'
import AgeRatingBadge from 'components/badge/ageContentRating'
import LiveBadge from 'components/badge/live'

import './styles/thumbnail.scss'

const _renderTop10Icon = top10Index => {
  if (top10Index <= 10 && top10Index > 0) {
    return (
      <div
        className='top-ten-content'
        id={`top-ten-content-${top10Index}`}
      >
        <img
          className='ribbon-banner-image'
          src={`${topNumber}${top10Index}.png`}
        />
      </div>
    )
  }

  return null
}


const VipBadge = ({isBadge, freeContent}) => {
  if (isBadge) {
    return (
      <div className='vip-badge'><span>VIP</span></div>
    )
  }
  return null
}


const TopRight = ({ isBadge, itemChild }) => {
  return (
    <div className='top-right'>
      <AgeRatingBadge content={itemChild} />
      <VipBadge isBadge={isBadge} freeContent={itemChild?.has_free_content} />
      <LiveBadge content={itemChild} />
    </div>
  )
}


const Thumbnail = (props) => {
  const {
    itemChild,
    clss,
    isBadge,
    children
  } = props

  const image =  itemChild?.images?.thumbnail

  return (
  <>
      <figure className={clss}>
        <div
          className='img-wrapper'
        >
          <img
          className='figure-img img-fluid rounded'
          src={image || imgholder }
          onError={(e) => {
            e.target.onerror = null
            e.target.src = imgholder
          }}
        />
        </div>
        <TopRight isBadge={isBadge} itemChild={itemChild} />
        {children}
      </figure>
      {itemChild?.is_new_release ? <NewReleaseBadge /> : null}
      {_renderTop10Icon(itemChild?.top_index >= 0 ? itemChild.top_index + 1 : 0)}
  </>
  )
}


// function isEqual(prevProps, nextProps) {
//   return (
//     prevProps.clss === nextProps.clss
//   )
// }

export default memo(Thumbnail)

