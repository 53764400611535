import { useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import { AGE_RATING_TYPE_K } from 'constant/ageContentRating'
import { getContentRatingList, getAgeContentRating } from 'core/method/content'
import AgeRatingBadge from 'components/badge/ageContentRating'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons' 
import '../styles/age-rating.scss'

const maxRecursive = 3;
const stepTime = 10 * 60 * 1000;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const AgeContentRating = ({ content, player, showInfo }) => {
  const nodeRef = useRef(null);
  const timeRef = useRef();
  const totalTimeRef = useRef(0);
  const countDownTime = 5000;
  const currentDisplayIndexRef = useRef(0);
  const [openWarning, setOpenWaring] = useState(false);
  const ageRating = getAgeContentRating.call(content);
  const contentRatingList = getContentRatingList.call(content);

  const getTitle = () => {
    const title =
      ageRating?.min_age === AGE_RATING_TYPE_K ? 'Cảnh Báo' : `Nội Dung ${ageRating?.min_age}+`;
    return title;
  };

  const getDescription = () => {
    const descriptions = [];
    const firstSentence = isEmpty(contentRatingList)
      ? ''
      : `${contentRatingList
          .filter(item => item.name)
          .map(item => item.name.replace('.', ''))
          .join(', ')}`;

    if (firstSentence !== '') {
      descriptions.push(`Nội dung này có tính chất: ${firstSentence}`);
    }

    if (ageRating?.disclaimer) {
      descriptions.push(`${ageRating.disclaimer.replace('.', '')}`);
    }
    return descriptions.join('. ');
  };

  const appendTimeInterval = () => {
    timeRef.current = setInterval(() => {
      if (currentDisplayIndexRef.current >= maxRecursive) {
        return clearInterval(timeRef.current);
      }
      if (player?.paused() === true) {
        return;
      }
      totalTimeRef.current += 1000;

      if (totalTimeRef.current % stepTime === 0) {
        setOpenWaring(true);
      }
    }, 1000);
  };
  useEffect(() => {
    appendTimeInterval();
    if (currentDisplayIndexRef.current === 0) {
      setOpenWaring(true)
    }

    return () => {
      clearInterval(timeRef.current);
    };
  }, []);

  useEffect(() => {
    if (openWarning) {
      currentDisplayIndexRef.current += 1;
    }
    const countDownTimeout = setTimeout(() => {
      setOpenWaring(false);
    }, countDownTime);

    return () => {
      clearTimeout(countDownTimeout);
    };
  }, [openWarning]);

  if (!ageRating) {
    return null
  }

  return (
    <div className={`age-rating ${!showInfo ? 'active' : ''}`}>
      <AgeRatingBadge content={content} clss={'player'}/>

      {openWarning && (
        <div ref={nodeRef} className="warning">
          <div className="left">
          <FontAwesomeIcon icon={faExclamationCircle} />
          </div>
          <div className="right">
            <div className="wrap-title">
              <h3 className="title">{getTitle()}</h3>
            </div>
            <p className="description">{getDescription()}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default AgeContentRating;
