import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isShowLogin: false,
  isLogin: false,
  isVip: false,
  subTier: null,
  account: null
}

const LoginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setIsShowLogin (state, action) {
      state.isShowLogin = action.payload
    },
    setLoginAccount (state, action) {
      const account = action.payload

      let isVip = false
      let subTier = null
      let isLogin = false

      if (account) {
        isLogin = true
        state.account = account
        isVip = account?.profile?.subscription_plan_info?.support_for_android_tv_box
        subTier = account?.profile?.subscription_plan_info?.sub_tier || null
      } else {
        state.account = null
      }

      state.isLogin = isLogin
      state.isVip = isVip
      state.subTier = subTier
    },
    setVIPAccount (state, action) {
      // state.isVip = action.payload
    }
  }
})
export const {
  setIsShowLogin,
  setLoginAccount,
  setVIPAccount
} = LoginSlice.actions
export default LoginSlice.reducer
