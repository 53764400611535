import React from 'react'
import { spinnerHDGif } from 'constant/images'
import classnames from 'classnames'

const Spinner = props => {
  const color = props.color || 'text-danger'
  const { classCustomise } = props

  return (
    <div
      style={{ zoom: window.scalingPercent }}
      className={classnames(color, classCustomise, 'spinner-ui')}>
      <img
        src={spinnerHDGif}
        style={{
          width: '100%',
          height: '100%'
        }}
      />
    </div>
  )
}

export default Spinner
