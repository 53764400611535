/* eslint-disable no-undef */
import { useEffect, useRef, useCallback } from 'react'
import { pushPlayerEvent, setEventData } from 'common/event.ts'
import { useSelector } from 'react-redux'
import videojs from 'video.js'
import jquery from 'jquery'

const useEventFirebase = (playerRef) => {
  const ribbonSelected = useSelector((state) => state.root.ribbonSelected)
  const playerData = useSelector((state) => state.player.playerData)
  const oldTime = useRef(0)
  const datas = useRef(null)
  const watchingTime = useRef(0)
  const isFirstPlay = useRef(false)
  const isLogFiveSecond = useRef(false)

  const handlePushTimeEvent = (player, skip = false) => {
    const playingContent = datas.current
    const $elAgeRating = jquery('.age-rating')
    // eslint-disable-next-line max-len
    if (player?.ads?.inAdBreak && player?.ads?.inAdBreak() === true || (player.ima && player.ima?.controller?.sdkImpl?.adPlaying)) {
      if (!$elAgeRating?.hasClass('hide')) {
        $elAgeRating.addClass('hide')
      }
      return
    }
    if ($elAgeRating?.hasClass('hide')) {
      $elAgeRating.removeClass('hide')
    }
    
    watchingTime.current = watchingTime.current + 1
    let timeViews = watchingTime.current

    const isLog = Math.floor(timeViews) % 300

    if (Math.floor(timeViews) % 5 === 0 && !isLogFiveSecond.current) {
      isLogFiveSecond.current = true
      pushPlayerEvent({ playingContent, ribbonSelected, type: 'videoFiveSecPlay'})
    }
    if (isLog === 0 || skip) {
      pushPlayerEvent({ playingContent, ribbonSelected, type: 'viewhourLog', watchingTime: isLog === 0 ? 300 : isLog})
    }
  }

  useEffect(() => {
    datas.current = playerData
    if (playerRef.current && isFirstPlay.current && playerData?.id) {
      handlePushTimeEvent(playerRef.current, true)
    }
    isFirstPlay.current = false
    isLogFiveSecond.current = false
    // console.log('playerData', datas.current)
  }, [playerData])



  const checkLogEvent = useCallback((player, target = 'vod') => {
    if (!player) return
    const playingContent = datas.current

    player.on('firstplay', () => {
      pushPlayerEvent({ playingContent, ribbonSelected, type: 'videoFirstPlay'})
    })

    player.on('ready', () => {
      // console.log('playing')
    })


    player.on('loadstart', () => {
      pushPlayerEvent({ playingContent, ribbonSelected, type: 'playerLoad' })
      watchingTime.current = 0
      isLogFiveSecond.current = false
    })

    player.on('loadeddata', () => {
      // console.log('loadeddata')
    })
    

    player.on('playing', () => {
      // console.log('playing')
    })

    player.on('seeked', () => {
    })

    player.on('pause', () => {
      // console.log('pause')
    })

    player.on('waiting', () => {
      // console.log('waiting')
    })

    player.on('dispose', () => {
      // console.log('dispose')
      player?.dispose()
      handlePushTimeEvent(player, true)
      watchingTime.current = 0
    })

    player.on('error', () => {
      pushPlayerEvent({ playingContent, ribbonSelected, type: 'videoPlayStartFail'})
    })

    player.on('canplaythrough', () => {
      if (!isFirstPlay.current) {
        isFirstPlay.current = true
        pushPlayerEvent({ playingContent, ribbonSelected, type: 'videoPlayStart'})
      }

      // console.log('canplaythrough')
    })

    // player.on('adskip', () => {
    //   const eventData = setEventData({
    //     playingContent,
    //     type: 'adskipped',
    //     ribbonSelected,
    //     player: player
    //   })
    //   pushPlayerEvent(eventData)
    // })

    // player.on('ads-manager', response => {
    //   const eventTypes = Object.keys(google.ima.AdEvent.Type)
    //   eventTypes.map(item => {
    //     player.ima.addEventListener(google.ima.AdEvent.Type[item], res => {
    //       const currentAds = response.adsManager?.getCurrentAd()
    //       if (google.ima.AdEvent.Type[item] === 'complete') {
    //         const adPod = currentAds?.getAdPodInfo()
    //         const eventData = setEventData({
    //           playingContent,
    //           type: 'adcomplete',
    //           ribbonSelected,
    //           player: player,
    //           adPod
    //         })
    //         pushPlayerEvent(eventData)
    //       }
    //       if (google.ima.AdEvent.Type[item] === 'impression') {
    //         const adPod = currentAds?.getAdPodInfo()
    //         const eventData = setEventData({
    //           playingContent,
    //           type: 'adimpression',
    //           ribbonSelected,
    //           player: player,
    //           adPod
    //         })
    //         pushPlayerEvent(eventData)
    //       }
    //     })
    //   })
    // })

    player.on('durationchange', () => {
      if(!player?.duration()) return
      player?.mux?.emit('hb', {video_duration: player?.duration()})
    })

    player.on('timeupdate', () => {
      let time = 0
      if (!player?.player_) return
      const durationPlayer = player.player_.duration()
      if (player.player_.ended()) {
        time = durationPlayer
      } else {
        time = player.player_.scrubbing()
          ? player.player_.getCache().currentTime
          : player.player_.currentTime()
      }
      const timeConvert = videojs.formatTime(time)
      if (oldTime.current === timeConvert) {
        return
      }
      oldTime.current = timeConvert
      handlePushTimeEvent(player)
    })
  }, [playerData?.id])

  

  return {
    checkLogEvent
  }
}

export default useEventFirebase
