import React, { useEffect, useCallback, forwardRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { keyCode } from 'common/utils'
import Modal from 'bootstrap/js/dist/modal'
import '../styles/warningLogin.scss'
import { setIsShowWarningLogin } from 'features/modal/modalSlice'
import { setIsShowLogin } from 'features/home/loginSlice'
import { LogoLogin } from '../../../constant/images'
import {  setMessageSubscription } from '../../subscription/subscriptionSlice'
import { useHistory } from 'react-router-dom'

const Title = ({ Text }) => {
  if (Text) {
    return (<div className="warning-login-button-description" dangerouslySetInnerHTML={{ __html: Text }} />)
  }
  return <>
    <img
      className='warning-login-img'
      width={183}
      height={183}
      src={LogoLogin}
    />
    <p className='warning-login-title'>Vui lòng đăng nhập để xem nội dung này.</p>
  </>

}

const WarningLogin = forwardRef((props, ref) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const isShowWarningLogin = useSelector((state) => state.modal.isShowWarningLogin)
  const subscription = useSelector((state) => state.subscription)
  const { message } = subscription

  const [modal, setModal] = useState(null)
  const [focus, setFocus] = useState(1)

  const Text = React.useMemo(() => {
    const txt = message
    return txt
  }, [message])

  const eventListener = useCallback(() => {
  }, [isShowWarningLogin, focus])

  useEffect(() => {
    document.addEventListener('keydown', handleKey)
    return () => {
      document.removeEventListener('keydown', handleKey)
    }
  }, [eventListener])

  useEffect(() => {
    if (ref && ref.current) {
      setModal(new Modal(ref.current, {
        backdrop: 'static'
      }))
    }
  }, [ref])

  useEffect(() => {
    if (modal) {
      ref.current.disposeModal = () => {
        if (modal._element) {
          modal.dispose()
        }
      }
    }

    return () => {
      if (modal && modal._element) {
        modal.dispose()
      }
    }
  }, [modal])

  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */
  const handleKey = (e) => {
    e.preventDefault()
    if (!isShowWarningLogin) return

    switch (e.keyCode) {
      case keyCode.RETURN: {
        handleClickCancel()
        break
      }
      case keyCode.ENTER: {
        handleClickEnter()
        break
      }
      case keyCode.LEFT: {
        setFocus(0)
        break
      }
      case keyCode.RIGHT: {
        setFocus(1)
        break
      }
    }
  }

  useEffect(() => {
    if (isShowWarningLogin) {
      if (modal && modal._element) {
        modal.show()
      }
    } else {
      if (modal && modal._element) {
        modal.hide()
      }
    }
  }, [isShowWarningLogin])


  const handleClickCancel = () => {
    const block = ['/season', '/content']
    dispatch(setIsShowWarningLogin(false))
    const pathname = history.location?.pathname
    if (block.includes(pathname)) return
  }
  const handleClickConfirm = () => {
    dispatch(setIsShowLogin(true))
    handleClickCancel()
  }

  const handleClickEnter = () => {
    // console.log('focus', focus)
    if (focus === 0) return handleClickCancel()
    handleClickConfirm()
    handleClickCancel()
  }

  return (
    <div ref={ref} className="modal" tabIndex="-1">
      <div className="modal-dialog modal-fullscreen">
        <div id="modal-content" className="modal-content-warning-login">
          <div id="modal-body" className="modal-body modal-warning-login-background">
            <div className="container-fluid">
              <div className={`warning-login-modal-container ${Text ? 'warning-login-premium' : ''}`}>
                <div className="text-center warning-login-button-container">
                  <Title Text={Text}/>
                  <div className="text-center warning-login-wrap-btn">
                    <button
                      type="button"
                      className={`warning-login-button ${focus === 0 && 'warning-login-button-active'}`}
                      onClick={() => handleClickCancel()}
                    >
                      Đóng
                    </button>
                    <button
                      type="button"
                      className={`warning-login-button ${focus === 1 && 'warning-login-button-active'}`}
                      onClick={() => handleClickConfirm()}
                    >
                      Đăng nhập
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

WarningLogin.displayName = 'WarningLogin'

export default WarningLogin
