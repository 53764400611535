
import { memo } from 'react'
import { logoMenu } from '../../../constant/images'

const MenuOpen = ({
  handleMouseLeaveMenu,
  handleClickMenu,
  handleMouseOverMenu,
  data,
  focusMenuIndex,
  active
}) => {
  return (
    <div className="menu-scroll-overlay d-flex flex-column align-items-center align-items-sm-start pt-2 text-white min-vh-100 menu-scroll">
      <img className='menu-app-logo-sctv' src={logoMenu} />
      <ul onMouseLeave={handleMouseLeaveMenu} className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start menu-margin-top" id="menu">
        {data && data.map((item, index) => {
          return (
            <li id={`menu-item-${index}`} key={index} className="nav-item">
              <a
                className={`nav-link align-middle px-0 ${index === focusMenuIndex ? 'active' : ''}`}
                onClick={(e) => handleClickMenu( item, index)}
                onMouseOver={handleMouseOverMenu}
              >
                {active === index && (<div className="nav-item-active" />)}
                <img className="sidebar-img" src={item.icon} width="25" height="25" />
                <span className="ms-1 d-none d-sm-inline sidebar-text">{item.name}</span>
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}


function areEqual(prevProps, nextProps) {
  return (
    prevProps.data?.length === nextProps.data?.length &&
    prevProps.focusMenuIndex === nextProps.focusMenuIndex &&
    prevProps.active === nextProps.active
  )
}

export default memo(MenuOpen, areEqual)


