/* eslint-disable max-len */
import { CONTENT_TYPE, CONTENT_TYPE_FOR_LIVE } from 'constant/content'
import config from 'common/config'

export const muxConfig = (content, account) => {
  const advertisingId = localStorage.getItem('advertisingId')

  const muxKey = process.env.REACT_APP_MUX_KEY || 'c9raqdc1k7gn96qt3r9pqurtj'
  const VERSION = config?.version || 'local'

  const valueMux = initValue(content)
  const dataMux = {
    env_key: muxKey,
    viewer_user_id: account?.profile?.id || advertisingId || null,
    sub_property_id: account?.profile?.subscription_plan_info?.name || null, // subcription
    video_language_code: 'vi',
    page_type: 'watchpage',
    player_name: 'SCTV-SmartTV',
    player_version: VERSION,
    ...valueMux
  }

  // console.log('dataMux', dataMux)
  // if (window.platform === 'web') return {}

  return {
    mux: {
      debug: false,
      data: {
        ...dataMux
      }
    }
  }
}

const initValue = (content) => {
  let videoCdn = content?.link_play || content?.linkPlay
  if (videoCdn) {
    videoCdn = new URL(videoCdn || '') || {}
    videoCdn = videoCdn?.hostname
  }

  const dataMux = {
    video_content_type: contentType(content),
    player_init_time: Date.now(),
    video_id: content?.defaultEpisodeSlug || content?.defaultEpisodeId || content?.slug || content?.id,
    video_title: content?.content_title ? content?.content_title : content?.title,
    video_series: content?.titleProgram || content?.slug || content?.id,
    video_duration: '',
    video_stream_type: CONTENT_TYPE_FOR_LIVE.includes(content?.content_type) ? 'live' : 'on-demand',
    video_producer: content?.cpName,
    video_cdn: videoCdn,
  }
  return dataMux
}

const contentType = item => {
  let statusText = 'movie'
  if (item) {
    switch (item?.type || item?.content_type) {
      case CONTENT_TYPE.SHOW:
      case CONTENT_TYPE.SEASON:
      case CONTENT_TYPE.EPISODE:
        statusText = 'episode'
        break
      case CONTENT_TYPE.LIVE:
        statusText = 'channel'
        break
      case CONTENT_TYPE.CURATED_LIVE:
        statusText = 'curated_live_channel'
        break
      case CONTENT_TYPE.LIVE_PREMIERE:
      case CONTENT_TYPE.LIVE_EVENT:
          statusText = 'live_event'
          break
      case CONTENT_TYPE.CLIP:
        statusText = 'clip'
        break
      case CONTENT_TYPE.TRAILER:
        statusText = 'trailer'
        break
    }
  }

  return statusText
}

const contentTypeLiveWithTxt = item => {
  let statusText = 'channel'
  if (item) {
    switch (item?.type || item?.content_type) {
      case CONTENT_TYPE.LIVE:
        statusText = 'channel'
        break
      case CONTENT_TYPE.CURATED_LIVE:
        statusText = 'Curated Live Channel'
        break
      case CONTENT_TYPE.LIVE_PREMIERE:
      case CONTENT_TYPE.LIVE_EVENT:
          statusText = 'Live Event'
          break
    }
  }

  return statusText
}

export const setInitMux = (content, vjs) => {
  const dataMux = initValue(content)
  // if (window.platform === 'web') return
  console.log('init new Mux', dataMux)
  vjs?.mux?.emit('videochange', { ...dataMux })

}