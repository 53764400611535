import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faListAlt, faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import AgeContentRating from './ageContentRating'
import { contentImg, back, backActive, season, seasonActive} from 'constant/images'
import { getContentLivePremiere } from 'core/method/content'

import '../styles/top-controlbar.scss'

const Live = ({ showInfo, handleClickBack, handleClickShowEpg, isLiveEventType, active, title, isBlockKeyBoardLiveEvent, handleClickShowInfo, content }) => {
  const isLivePremiere = getContentLivePremiere.call(content)
  return (
    <>
      <div className="top-controlbar-left">
        <div className='player-icon-wrap'>
        <FontAwesomeIcon
          className={`player-live-back-icon ${showInfo ? 'visibility-visible' : 'visibility-hidden'} ${active === 'back' ? 'active' : ''}`}
          icon={faChevronLeft}
          onClick={handleClickBack}
          />
          {active === 'back' ? <p className={`${showInfo ? 'visibility-visible' : 'visibility-hidden'}`}>Quay lại</p> : null}
        </div>
        {isLiveEventType ?
          <div className='player-icon-wrap'>
            <img
              src={active === 'infoLiveEvent' ? `${contentImg}faListActive.svg`  :  `${contentImg}faList.svg` }
              onClick={handleClickShowInfo}
              className={`player-live-info-icon ${showInfo ? 'visibility-visible' : 'visibility-hidden'} ${active === 'infoLiveEvent' ? 'active' : ''}`}
            />
            {active === 'infoLiveEvent' ? <p className={`${showInfo ? 'visibility-visible' : 'visibility-hidden'}`}>Thông tin</p> : null}
          </div>
          :
          <FontAwesomeIcon
          className={`player-live-schedule-icon ${showInfo ? 'visibility-visible' : 'visibility-hidden'} ${active === 'schedule' ? 'active' : ''}`}
          icon={faCalendarAlt}
          onClick={handleClickShowEpg}
        />}
        {isBlockKeyBoardLiveEvent ? null :
          <div className={`player-icon-wrap ${showInfo ? 'visibility-visible' : 'visibility-hidden'}`}>
            <div className='player-live-icon-white-dot' />
            <div className='player-live-icon-text'>{isLivePremiere ? 'PREMIERE' :'Trực tiếp'}</div>
          </div>
        }

      </div>
      <div className='top-controlbar-right'>
        <div className={`player-live-title ${showInfo && !isBlockKeyBoardLiveEvent ? 'visibility-visible' : 'visibility-hidden'}`}>
          {title}
        </div>
      </div>
    </>
  )
}

const Vod = ({
  handleClickBack,
  handleClickShowSeason,
  showInfo,
  active,
  playerData,
  title,
}) => {
  return (
    <>
      <div className="top-controlbar-left">
        <div className='player-icon-wrap player-icon-wrap-vod '>
          <img
            className={`player-back-icon ${showInfo ? 'visibility-visible' : 'visibility-hidden'} ${active === 'back' ? 'active' : ''}`}
            onClick={handleClickBack}
            src={active === 'back' ? backActive  : back}
          />
          {active === 'back' ? <p className={`${showInfo ? 'visibility-visible' : 'visibility-hidden'}`}>Quay lại</p> : null}
        </div>

        {(playerData && playerData.season) &&
        //   <FontAwesomeIcon
        //     className={`player-season-icon ${showInfo ? 'visibility-visible' : 'visibility-hidden'} ${active === 'season' ? 'active' : ''}`}
        //     icon={faListAlt}
        //     onClick={handleClickShowSeason}
        // />
          <div className='player-icon-wrap player-icon-wrap-vod'>
            <img
              className={`player-season-icon ${showInfo ? 'visibility-visible' : 'visibility-hidden'} ${active === 'season' ? 'active' : ''}`}
              onClick={handleClickShowSeason}
              src={active === 'season' ? seasonActive  : season}
            />
              {active === 'season' ? <p style={{marginTop: '16px'}} className={`${showInfo ? 'visibility-visible' : 'visibility-hidden'}`}>Tập phim</p> : null}
          </div>
        } 
        </div>
      <div className='top-controlbar-right'>
        <div className={`player-title ${showInfo ? 'visibility-visible' : 'visibility-hidden'}`}>{title}</div>
      </div>
    </>
  )
}

const TopControlBar = (props) => {
  return (
    <div className='top-controlbar'>
      {
        props?.isLive ? <Live {...props} /> :  <Vod {...props} />
      }

      {((props?.isLive && props?.statusLiveEvent?.isLiveEvent) || !props?.isLive)
        ? <AgeContentRating content={props?.content} player={props?.player} showInfo={props?.showInfo} />
        : null
      }
    </div>
  )
}

export default TopControlBar

