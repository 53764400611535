import React, { useEffect, useCallback, forwardRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { keyCode } from 'common/utils'
import Modal from 'bootstrap/js/dist/modal'
import { setIsShowSubscription } from 'features/subscription/subscriptionSlice'
import '../styles/subscription.scss'

const SubscriptionPage = forwardRef((props, ref) => {
  const dispatch = useDispatch()
  const subscription = useSelector((state) => state.subscription)
  
  const login = useSelector((state) => state.login)
  const { isLogin } = login
  const { isShowSubscription, message } = subscription

  const [modal, setModal] = useState(null)

  const Text = React.useMemo(() => {
    const txt = message
    return txt
  }, [isLogin, message])

  const eventListener = useCallback(() => {
  }, [isShowSubscription])

  useEffect(() => {
    document.addEventListener('keydown', handleKey)
    return () => {
      document.removeEventListener('keydown', handleKey)
    }
  }, [eventListener])

  useEffect(() => {
    if (ref && ref.current) {
      setModal(new Modal(ref.current, {
        backdrop: 'static'
      }))
    }
  }, [ref])

  useEffect(() => {
    if (modal) {
      ref.current.disposeModal = () => {
        if (modal._element) {
          modal.dispose()
        }
      }
    }

    return () => {
      if (modal && modal._element) {
        modal.dispose()
      }
    }
  }, [modal])

  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */
  const handleKey = (e) => {
    e.preventDefault()

    switch (e.keyCode) {
      case keyCode.ENTER: {
        if (isShowSubscription) {
          dispatch(setIsShowSubscription(false))
        }
        break
      }
      case keyCode.RETURN: {
        if (isShowSubscription) {
          dispatch(setIsShowSubscription(false))
        }
        break
      }
    }
  }

  useEffect(() => {
    if (isShowSubscription) {
      if (modal && modal._element) {
        modal.show()
      }
    } else {
      if (modal && modal._element) {
        modal.hide()
      }
    }
  }, [isShowSubscription])

  const handleClickCancel = () => {
    dispatch(setIsShowSubscription(false))
  }

  return (
    <div ref={ref} className="modal" tabIndex="-1">
      <div className="modal-dialog modal-fullscreen">
        <div id="modal-content" className="modal-content-subscription">
          <div id="modal-body" className="modal-body modal-subscription-background">
            <div className="container-fluid">
              <div className="subscription-modal-container">
                <div className="text-center subscription-button-container">
                  <div className="subscription-button-description" dangerouslySetInnerHTML={{ __html: Text }} />
                  <button
                    type="button"
                    className="subscription-button-bg-active"
                    onClick={() => handleClickCancel()}
                  >
                    Đồng ý
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

SubscriptionPage.displayName = 'SubscriptionPage'

export default SubscriptionPage
