import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isShowSeason: false,
  linkPlay: '',
  dataSeason: []
}

const seasonSlice = createSlice({
  name: 'season',
  initialState,
  reducers: {
    setIsShowSeason (state, action) {
      state.isShowSeason = action.payload
    },
    setLinkPlay (state, action) {
      state.linkPlay = action.payload
    },
    setDataSeason (state, action) {
      state.dataSeason = action.payload
    }
  }
})

export const {
  setIsShowSeason,
  setLinkPlay,
  setDataSeason
} = seasonSlice.actions
export default seasonSlice.reducer
