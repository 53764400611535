import React from 'react'
import './styles/vip-badge.scss'

const VipBadge = (props) => {
  // eslint-disable-next-line react/prop-types
  const { isViewMore } = props
  return (
    <div className={isViewMore ? 'ribbon-vip-badge-viewmore' : 'ribbon-vip-badge'}>VIP</div>
  )
}

export default VipBadge
