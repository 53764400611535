import  { useEffect, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { keyCode, getDataTenant } from 'common/utils'
import '../styles/login.scss'
import { useMutation } from 'react-query'

const ProfilePage = (props) => {
  const history = useHistory()

  const [active, setActive] = useState({
    field: 'email',
    tag: 'form',
    item: -1,
    category: 0
  })
  const [isLoading, setIsLoading] = useState(false)
  const [usernameText, setUsernameText] = useState('')
  const [passwordText, setPasswordText] = useState('')
  const [isShiftActive, setIsShiftActive] = useState(false)

  const eventListener = useCallback(() => {
  }, [active])

  const { mutate: handleLoginAction } = useMutation(
    async () => await getDataTenant('POST', 'accounts/user_account_login', { username: usernameText, password: passwordText }),
    {
      onSuccess: loginResponse => {
        setIsLoading(false)
        localStorage.setItem('user_profile', JSON.stringify(loginResponse))
      },
      onError: error => {
        setIsLoading(false)
        console.log('error', error)
      }
    }
  )

  const listAlphabet = [
    ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l'],
    ['m', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x'],
    ['y', 'z', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
  ]

  const listAlphabetShift = [
    ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'],
    ['M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X'],
    ['Y', 'Z', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
  ]

  useEffect(() => {
    document.addEventListener('keydown', handleKey)
    return () => {
      document.removeEventListener('keydown', handleKey)
    }
  }, [eventListener])

  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */
  const handleKey = (e) => {
    e.preventDefault()

    switch (e.keyCode) {
      case keyCode.ENTER: {
        if (active.tag === 'form') {
          if (active.field === 'email' || active.field === 'password') {
            setActive({
              ...active,
              item: 0,
              tag: 'alphabet'
            })
          }

          if (active.field === 'loginBtn') {
            if (!isLoading) {
              setIsLoading(true)
              handleLoginAction()
            }
          }

          if (active.field === 'cancelBtn') {
            handleClickBack()
          }
        }

        if (active.tag === 'alphabet') {
          if (!Number.isInteger(active.category) && active.category === 'action') {
            if (active.item === 'backspace') {
              handleClickBackspace()
            }

            if (active.item === 'done') {
              handleClickDone()
            }

            if (active.item === 'shift') {
              handleClickShift()
            }
          } else {
            if (active.field === 'email') {
              const key = isShiftActive ? listAlphabetShift[active.category][active.item] : listAlphabet[active.category][active.item]
              setUsernameText(text => text.concat(key))
            }
            if (active.field === 'password') {
              const key = isShiftActive ? listAlphabetShift[active.category][active.item] : listAlphabet[active.category][active.item]
              setPasswordText(text => text.concat(key))
            }
          }
        }
        break
      }
      case keyCode.DOWN: {
        if (active.tag === 'form') {
          if (active.field === 'email') {
            setActive({
              ...active,
              field: 'password'
            })
          }

          if (active.field === 'password') {
            setActive({
              ...active,
              field: 'loginBtn'
            })
          }

          if (active.field === 'loginBtn') {
            setActive({
              ...active,
              field: 'cancelBtn'
            })
          }
        }

        if (active.tag === 'alphabet') {
          if (active.category < 2) {
            setActive({
              ...active,
              category: active.category + 1,
              item: active.item
            })
          } else {
            setActive({
              ...active,
              category: 'action',
              item: 'shift'
            })
          }
        }
        break
      }
      case keyCode.UP: {
        if (active.tag === 'form') {
          if (active.field === 'password') {
            setActive({
              ...active,
              field: 'email'
            })
          }

          if (active.field === 'loginBtn') {
            setActive({
              ...active,
              field: 'password'
            })
          }

          if (active.field === 'cancelBtn') {
            setActive({
              ...active,
              field: 'loginBtn'
            })
          }
        }

        if (active.tag === 'alphabet') {
          if (Number.isInteger(active.category) && active.category > 0) {
            setActive({
              ...active,
              category: active.category - 1,
              item: active.item
            })
          }

          if (active.category === 'action') {
            setActive({
              ...active,
              category: 2,
              item: 0
            })
          }
        }
        break
      }
      case keyCode.RIGHT: {
        if (active.tag === 'alphabet') {
          if (!Number.isInteger(active.category) && active.category === 'action') {
            // if (active.item === 'space') {
            //   setActive({
            //     ...active,
            //     item: 'backspace'
            //   })
            // }

            if (active.item === 'backspace') {
              setActive({
                ...active,
                item: 'done'
              })
            }

            if (active.item === '@') {
              setActive({
                ...active,
                item: '.'
              })
            }

            if (active.item === '.') {
              setActive({
                ...active,
                item: 'backspace'
              })
            }

            if (active.item === 'shift') {
              setActive({
                ...active,
                item: '@'
              })
            }
          } else { // không phải nút space, backspace
            if (active.item < 11) {
              setActive({
                ...active,
                item: active.item + 1
              })
            }
          }
        }
        break
      }
      case keyCode.LEFT: {
        if (active.tag === 'alphabet') {
          if (active.category === 'action') {
            if (active.item === 'done') {
              setActive({
                ...active,
                item: 'backspace'
              })
            }

            if (active.item === 'backspace') {
              setActive({
                ...active,
                item: '.'
              })
            }

            // if (active.item === 'space') {
            //   setActive({
            //     ...active,
            //     item: '.'
            //   })
            // }

            if (active.item === '.') {
              setActive({
                ...active,
                item: '@'
              })
            }

            if (active.item === '@') {
              setActive({
                ...active,
                item: 'shift'
              })
            }
          } else { // không phải nút space, backspace
            if (Number.isInteger(active.category) && active.item > 0) {
              setActive({
                ...active,
                item: active.item - 1
              })
            }
          }
        }
      }
    }
  }

  const handleClickShift = () => {
    setIsShiftActive(prev => !prev)
  }

  const handleClickBackspace = () => {
    if (active.field === 'email') {
      setUsernameText(text => text.slice(0, -1))
    }

    if (active.field === 'password') {
      setPasswordText(text => text.slice(0, -1))
    }
  }

  const handleClickDone = () => {
    setActive({
      ...active,
      field: 'email',
      tag: 'form',
      item: -1,
      category: 0
    })
  }

  const handleClickBack = () => {
    if (localStorage.getItem('user_profile')) {
      localStorage.removeItem('user_profile')
      history.goBack()
    }
  }

  return (
    <div className="container-fluid">
      <div className="login-container col-md-3">
        <button
          onClick={handleClickBack}
          className={`btn btn-primary back-btn ${active.field === 'cancelBtn' ? 'active' : ''}`}
        >
          Đăng Xuất
        </button>
      </div>
    </div>
  )
}

export default ProfilePage
