import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { LIVE_EVENT } from 'constant/content'

const DEFAULT = {
  isLiveEvent: false,
  isBeforeLiveEvent: false,
  isAfterLiveEvent: false
}
const useLiveEvent = ({ playerRef }) => {

  const content = useSelector((state) => state.content.content)

  const [statusLiveEvent, setStatusLiveEvent] = useState(DEFAULT)

  const isBlockKeyBoardLiveEvent = useMemo(() => {
    if (statusLiveEvent?.isBeforeLiveEvent || statusLiveEvent?.isAfterLiveEvent) return true
    return false
  }, [statusLiveEvent])


  const isLiveEventType = useMemo(() => {
    return content?.type === LIVE_EVENT
  }, [content?.type])

  const checkLogEvent = ((player) => {
    if (!player) return

    player.on('before-live', () => {
      setStatusLiveEvent({
        ...DEFAULT,
        isBeforeLiveEvent: true
      })
    })

    player.on('after-live', () => {
      setStatusLiveEvent({
        ...DEFAULT,
        isAfterLiveEvent: true
      })
    })

    player.on('live-event', () => {
      setStatusLiveEvent({
        ...DEFAULT,
        isLiveEvent: true
      })
    })
  })

  useEffect(() => {
    const player = playerRef?.current
    checkLogEvent(player)
  }, [playerRef?.current])

  return {
    statusLiveEvent,
    isBlockKeyBoardLiveEvent,
    isLiveEventType
  }
}

export default useLiveEvent
