import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import axios from 'axios'
import queryString from 'query-string'

const useDrmQnet = ({ playerRef, setLoadingLimit }) => {
  const [tokenGlobal, setTokenGlobal] = useState(false)
  const [limitDrm, setLimitDrm] = useState(false)

  const timeOutLimit = useRef(null)
  const limitRef = useRef(false)

  const playerData = useSelector((state) => state.player.playerData)

  const pingOrRefreshToken = async() => {
    /* eslint-disable camelcase */
    const { session, operator_id, session_id, limit_ccu_ping_url, limit_ccu_end_url } = playerData.cwl_info
  
    let params = {
      sessionId: session_id,
      operatorId: operator_id || null,
      session: session
    }
  
    const queryParams = queryString.stringify(params)
    let linkApi = `${limit_ccu_ping_url}?${queryParams}`
  
    try {
      const tokenData = await axios.get(linkApi, { validateStatus: () => true })
      const { data, status } = tokenData
      // data.token && setTokenGlobal(data.token)
      if (status) {
        switch (status) {
          case 401:
          case 405:
          case 400: {
            limitRef.current = true
            break
          }
          case 426: {
            break
          }
          default:
            limitRef.current = false
            break
        }
      }
      const paramsend = {
        token: data.token
      }
      const queryParamEnd = queryString.stringify(paramsend)
      if (data.token) {
        await axios.get(`${limit_ccu_end_url}?${queryParamEnd}`, { validateStatus: () => true })
      }
    } catch (error) {
      console.error(error, 'error')
    }
  }

  const checkLogEvent = ((player) => {
    if (!player) return
    player.on('drm-error', () => {
      setLimitDrm(true)
    })
  })


  useEffect(() => {
    const player = playerRef?.current
    checkLogEvent(player)
  }, [playerRef?.current])


  const handleRetryDrm = async () => {
    setLoadingLimit(true)
    try {
      await pingOrRefreshToken()
    } catch (error) {
      console.log('error', error)
    }
    finally {
      timeOutLimit.current = setTimeout(() => {
        setLoadingLimit(false)
        setLimitDrm(limitRef.current)
        clearTimeout(timeOutLimit.current)
      }, 1000);
    }
  }
  
  return ({
    handleRetryDrm,
    limitDrm
  })
}
export default useDrmQnet

