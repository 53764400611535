import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isShowSubscription: false,
  message: ''
}

const SubscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setIsShowSubscription (state, action) {
      state.isShowSubscription = action.payload
    },
    setMessageSubscription (state, action) {
      state.message = action.payload
    }
  }
})
export const {
  setIsShowSubscription,
  setMessageSubscription
} = SubscriptionSlice.actions
export default SubscriptionSlice.reducer
