import { useMemo } from 'react'
import { useSelector } from 'react-redux'

const useBlockKeyboard = () => {
  const isShowWarningForcedLogout = useSelector((state) => state.modal.isShowWarningForcedLogout)

  const isBlockKeyboard = useMemo(() => {
    if (isShowWarningForcedLogout) return true
    return false
  },[isShowWarningForcedLogout])
  return {
    isBlockKeyboard
  }
}

export default useBlockKeyboard
