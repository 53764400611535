import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { logoSplashscreen } from 'constant/images'
import {  setLoginAccount } from 'features/home/loginSlice'
import { useDispatch } from 'react-redux'

import './styles/splash-screen.scss'

const SplashScreen = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    const profile = localStorage.getItem('user_profile')
    if (profile) {
      const userProfile = JSON.parse(profile)
      dispatch(setLoginAccount(userProfile))
    }
    setTimeout(() => {
      history.replace('/home')
    }, 1500)
  }, [])

  return (
    <div
      style={{
        backgroundImage: `url(${logoSplashscreen})`
      }}
      className="splash-screen"></div>
  )
}

export default SplashScreen
