
import { LIVE_EVENT, LIVE } from 'constant/content'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons' 

import { activeLiveEvent, getContentLivePremiere } from 'core/method/content'

const LiveBadge = ({ content, clss }) => {
  const arr = [LIVE_EVENT, LIVE]
  if (getContentLivePremiere.call(content)) {
    return (
      <div className='live-badge active'>
        <FontAwesomeIcon icon={faCircle}  />
        <span>PREMIERE</span>
      </div>
    )
  }
  if (arr.includes(content?.type)) {
    const activeClss = content?.type === LIVE || activeLiveEvent.call(content)
    return (
      <div className={`live-badge ${activeClss ? 'active' : 'deactive'}`}>
        <FontAwesomeIcon icon={faCircle}  />
        <span>live</span>
      </div>
    )
  }
  return null
};

export default LiveBadge;
