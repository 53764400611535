/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, forwardRef, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Modal from 'bootstrap/js/dist/modal'
// getDataTenant
import { keyCode, getDataTenant, hardLogin } from 'common/utils'
import { useMutation } from 'react-query'
import { setIsShowLogin, setLoginAccount } from 'features/home/loginSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import ProfileModal from './ProfileModal'
// import useProfile from 'hook/useProfile'
import { setCurrentMenu } from '../../menu/menuSlice'
import { DEFAULT_MENU_INDEX } from '../../../constant/settings'
import { toggleOpenMenu, toggleNavigateMenu, toggleNavigateRibbon } from '../../../common/slice'
import config from 'common/config'
import '../styles/login.scss'

const LoginModal = forwardRef((props, ref) => {
  // const { getProfile } = useProfile()

  const dispatch = useDispatch()
  const login = useSelector((state) => state.login)
  const { isLogin } = login

  const isShowLogin = useSelector((state) => state.login.isShowLogin)
  const menuList = useSelector((state) => state.menu.menuList)
  const preSelectedMenu = useSelector((state) => state.menu.preSelectedMenu)
  const VERSION = config.version || 'local'
  const isVersionShow = useMemo(() => {
    const versionReleaseTmp = config.versionRelease?.toString()
    console.log('versionRelease', config.versionRelease)
    console.log('VERSION', VERSION)
    if (config.versionRelease !== 'prod' || versionReleaseTmp !== 'prod') {
      return true
    }
    return false
  }, [])
  const [modal, setModal] = useState(null)

  const [active, setActive] = useState({
    field: 'email',
    tag: 'form',
    item: -1,
    category: 0
  })

  const [isLoading, setIsLoading] = useState(false)
  const [usernameText, setUsernameText] = useState('')
  const [passwordText, setPasswordText] = useState('')

  // const [usernameText, setUsernameText] = useState('yd1@gmail.com')
  // const [passwordText, setPasswordText] = useState('123456')
  const [isShiftActive, setIsShiftActive] = useState(false)

  const [errorText, setErrorText] = useState('')

  const txtPremiumRequired = 'Tài khoản của bạn không hỗ trợ đăng nhập trên TV, vui lòng nâng cấp gói dịch vụ SCTVOnline Premium.'

  const eventListener = useCallback(() => {
  }, [active , modal, isShowLogin, menuList, isLogin])

  // For test login account
  // const { mutate: handleLoginAction } = useMutation(
  //   async () => await hardLogin('d9600e9f-a0df-4be0-ba5d-88ccf61f59f8'),
  //   {
  //     onSuccess: async loginResponse => {
  //       dispatch(setLoginAccount(loginResponse))
  //       await localStorage.setItem('user_profile', JSON.stringify(loginResponse))
  //       dispatch(toggleOpenMenu())
  //       dispatch(toggleNavigateMenu())
  //       dispatch(toggleNavigateRibbon())

  //       dispatch(setIsShowLogin(false))
  //       setIsLoading(false)
  //     },
  //     onError: () => {
  //       setIsLoading(false)
  //       setErrorText('Thông tin tài khoản đăng nhập không đúng, vui lòng kiểm tra lại.')
  //     }
  //   }
  // )

  const { mutate: handleLoginAction } = useMutation(
    async () => await getDataTenant('POST', 'accounts/user_account_login?platform=ctv', { username: usernameText, password: passwordText }),
    {
      onSuccess: async loginResponse => {
        dispatch(setLoginAccount(loginResponse))
        await localStorage.setItem('user_profile', JSON.stringify(loginResponse))
        dispatch(toggleOpenMenu())
        dispatch(toggleNavigateMenu())
        dispatch(toggleNavigateRibbon())

        dispatch(setIsShowLogin(false))
        setIsLoading(false)
      },
      onError: (err) => {
        const data = err.response.data
        setIsLoading(false)
        if (data?.error_code === 'restricted_access_to_ctv_by_subscription_exception') return setErrorText(txtPremiumRequired)
        setErrorText('Thông tin tài khoản đăng nhập không đúng, vui lòng kiểm tra lại.')
      }
    }
  )

  const listAlphabet = [
    ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l'],
    ['m', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x'],
    ['y', 'z', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
  ]

  const listAlphabetShift = [
    ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'],
    ['M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X'],
    ['Y', 'Z', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
  ]

  useEffect(() => {
    setUsernameText('')
    setPasswordText('')
  }, [isLogin])

  useEffect(() => {
    document.addEventListener('keydown', handleKey)
    return () => {
      document.removeEventListener('keydown', handleKey)
    }
  }, [eventListener])

  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */
  const handleKey = (e) => {
    e.preventDefault()
    if (isLogin) return
    if (isShowLogin) {
      switch (e.keyCode) {
        case keyCode.RETURN: {
          dispatch(setIsShowLogin(false))
          break
        }
        case keyCode.ENTER: {
          if (active.tag === 'form') {
            if (active.field === 'email' || active.field === 'password') {
              setActive({
                ...active,
                item: 0,
                tag: 'alphabet'
              })
              setErrorText('')
            }

            if (active.field === 'loginBtn') {
              if (!isLoading) {
                if (usernameText && passwordText) {
                  setErrorText('')
                  setIsLoading(true)
                  handleLoginAction()
                } else {
                  setErrorText('Vui lòng nhập đầy đủ thông tin')
                }
              }
            }

            if (active.field === 'cancelBtn') {
              handleClickBack()
            }
          }

          if (active.tag === 'alphabet') {
            if (!Number.isInteger(active.category) && active.category === 'action') {
              if (active.item === 'backspace') {
                handleClickBackspace()
              }

              if (active.item === 'done') {
                handleClickDone()
              }

              if (active.item === 'shift') {
                handleClickShift()
              }

              if (active.item === '.') {
                handleClickDot()
              }

              if (active.item === '@') {
                handleClickACong()
              }
            } else {
              if (active.field === 'email') {
                const key = isShiftActive ? listAlphabetShift[active.category][active.item] : listAlphabet[active.category][active.item]
                setUsernameText(text => text.concat(key))
              }
              if (active.field === 'password') {
                const key = isShiftActive ? listAlphabetShift[active.category][active.item] : listAlphabet[active.category][active.item]
                setPasswordText(text => text.concat(key))
              }
            }
          }
          break
        }
        case keyCode.DOWN: {
          if (active.tag === 'form') {
            if (active.field === 'email') {
              setActive({
                ...active,
                field: 'password'
              })
            }

            if (active.field === 'password') {
              setActive({
                ...active,
                field: 'loginBtn'
              })
            }

            if (active.field === 'cancelBtn') {
              setActive({
                ...active,
                field: 'email'
              })
            }
          }

          if (active.tag === 'alphabet') {
            if (active.category < 2) {
              setActive({
                ...active,
                category: active.category + 1,
                item: active.item
              })
            } else {
              setActive({
                ...active,
                category: 'action',
                item: 'shift'
              })
            }
          }
          break
        }
        case keyCode.UP: {
          if (active.tag === 'form') {
            if (active.field === 'password') {
              setActive({
                ...active,
                field: 'email'
              })
            }

            if (active.field === 'loginBtn') {
              setActive({
                ...active,
                field: 'password'
              })
            }

            if (active.field === 'email') {
              setActive({
                ...active,
                field: 'cancelBtn'
              })
            }
          }

          if (active.tag === 'alphabet') {
            if (Number.isInteger(active.category) && active.category > 0) {
              setActive({
                ...active,
                category: active.category - 1,
                item: active.item
              })
            }

            if (active.category === 'action') {
              setActive({
                ...active,
                category: 2,
                item: 0
              })
            }
          }
          break
        }
        case keyCode.RIGHT: {
          if (active.tag === 'alphabet') {
            if (!Number.isInteger(active.category) && active.category === 'action') {
              if (active.item === 'backspace') {
                setActive({
                  ...active,
                  item: 'done'
                })
              }

              if (active.item === '@') {
                setActive({
                  ...active,
                  item: '.'
                })
              }

              if (active.item === '.') {
                setActive({
                  ...active,
                  item: 'backspace'
                })
              }

              if (active.item === 'shift') {
                setActive({
                  ...active,
                  item: '@'
                })
              }
            } else { // không phải nút space, backspace
              if (active.item < 11) {
                setActive({
                  ...active,
                  item: active.item + 1
                })
              }
            }
          }
          break
        }
        case keyCode.LEFT: {
          if (active.tag === 'alphabet') {
            if (active.category === 'action') {
              if (active.item === 'done') {
                setActive({
                  ...active,
                  item: 'backspace'
                })
              }

              if (active.item === 'backspace') {
                setActive({
                  ...active,
                  item: '.'
                })
              }

              if (active.item === '.') {
                setActive({
                  ...active,
                  item: '@'
                })
              }

              if (active.item === '@') {
                setActive({
                  ...active,
                  item: 'shift'
                })
              }
            } else { // không phải nút space, backspace
              if (Number.isInteger(active.category) && active.item > 0) {
                setActive({
                  ...active,
                  item: active.item - 1
                })
              }
            }
          }
        }
      }
    }
  }

  const handleClickLogin = () => {
    if (!isLoading) {
      if (usernameText && passwordText) {
        setErrorText('')
        setIsLoading(true)
        handleLoginAction()
      } else {
        setErrorText('Vui lòng nhập đầy đủ thông tin')
      }
    }
  }

  const handleClickShift = () => {
    setIsShiftActive(prev => !prev)
  }

  const handleClickAlphabet = (e, index, indexChild) => {
    if (active.field === 'email') {
      const key = isShiftActive ? listAlphabetShift[index][indexChild] : listAlphabet[index][indexChild]
      setUsernameText(text => text.concat(key))
    }

    if (active.field === 'password') {
      const key = isShiftActive ? listAlphabetShift[index][indexChild] : listAlphabet[index][indexChild]
      setPasswordText(text => text.concat(key))
    }
  }

  const handleClickBackspace = () => {
    if (active.field === 'email') {
      setUsernameText(text => text.slice(0, -1))
    }

    if (active.field === 'password') {
      setPasswordText(text => text.slice(0, -1))
    }
  }

  const handleClickInput = (type) => {
    setErrorText('')
    setActive({
      ...active,
      tag: 'alphabet',
      field: type
    })
  }

  const handleClickDone = () => {
    setActive({
      ...active,
      field:  active.field === 'email' ? 'email' : 'password',
      tag: 'form',
      item: -1,
      category: 0
    })
  }

  const handleClickACong = () => {
    setUsernameText(text => text.concat('@'))
  }

  const handleClickDot = () => {
    setUsernameText(text => text.concat('.'))
  }

  const handleClickBack = () => {
    dispatch(toggleOpenMenu())
    dispatch(toggleNavigateMenu())
    dispatch(toggleNavigateRibbon())

    // if (preSelectedMenu) {
    //   dispatch(setCurrentMenu(preSelectedMenu))
    // } else {
    //   dispatch(setCurrentMenu(menuList[DEFAULT_MENU_INDEX]))
    // }

    dispatch(setIsShowLogin(false))
    setActive({
      field: 'email',
      tag: 'form',
      item: -1,
      category: 0
    })
  }

  useEffect(() => {
    if (ref && ref.current) {
      setModal(new Modal(ref.current, {
        backdrop: 'static'
      }))
    }
  }, [ref])

  useEffect(() => {
    if (modal) {
      ref.current.disposeModal = () => {
        if (modal._element) {
          modal.dispose()
        }
      }
    }

    return () => {
      if (modal && modal._element) {
        modal.dispose()
      }
    }
  }, [modal])

  useEffect(() => {
    if (isShowLogin) {
      if (modal && modal._element) {
        modal.show()
      }
    } else {
      if (modal && modal._element) {
        modal.hide()
      }
    }
  }, [isShowLogin])


  const renderPassWord = () => {
    if (passwordText && passwordText.length > 0) {
      const makeText = passwordText.split('').map((char) => {
        return '*'
      })
      return makeText
    }
    return ''
  }

  return (
    <div ref={ref} className="modal" tabIndex="-1">
      <div className="modal-dialog modal-fullscreen">
        <div id="modal-content" className="modal-content">
          <div id="modal-body" className="modal-body modal-login-background">
            {isLogin
              ? <ProfileModal />
              : (
                <div className="container-fluid">
                  <div className="row">
                    <div className="col col-md-12">
                      <div id="login-back-button" className="login-back-button">
                        <div id='login-back-button-wrapper' className={`login-back-button-wrapper ${active.field === 'cancelBtn' ? 'active' : ''}`}>
                          <FontAwesomeIcon
                            className='login-back-icon'
                            icon={faChevronLeft}
                            onClick={() => handleClickBack()}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="margin-top-login" />
                  <div className="row">
                    <div className="col-md-4 login-container">
                      <div className="mb-4 login-title">Đăng Nhập</div>
                      <div className="mb-4">
                        <label className="form-label label">Số điện thoại hoặc Email</label>
                        <div
                          onClick={e => handleClickInput('email')}
                          className={`form-control email-input ${active.field === 'email' ? 'active' : ''}`}
                        >{usernameText}</div>
                      </div>
                      <div className="mb-4">
                        <label className="form-label label">Mật khẩu</label>
                        <div
                          onClick={e => handleClickInput('password')}
                          className={`form-control password-input ${active.field === 'password' ? 'active' : ''}`}
                        >{renderPassWord()}</div>
                      </div>
                      {errorText
                        ? (<div className="error-text">{errorText}</div>)
                        : null}
                      <button
                        onClick={handleClickLogin}
                        className={`btn btn-primary login-btn ${active.field === 'loginBtn' ? 'active' : ''}`}
                      >
                        {
                          isLoading
                            ? '...'
                            : 'Đăng nhập'
                        }
                      </button>
                      {/* <button
                        onClick={handleClickBack}
                        className={`btn btn-primary back-btn ${active.field === 'cancelBtn' ? 'active' : ''}`}
                      >
                        Trở lại
                      </button> */}
                    </div>

                    <div className="col-md-6">
                      <div className="keyboard">
                        {listAlphabet.map((item, index) => {
                          return (
                            <div key={index} className="row login-alphabet-container">
                              {item.map((itemChild, indexChild) => {
                                return (
                                  <div
                                    key={indexChild}
                                    className={`col-md-1 login-alphabet \
                                      ${active.tag === 'alphabet' && active.category === index && active.item === indexChild ? 'active' : ''} \
                                      ${indexChild === item.length - 1 ? 'login-border-right' : ''} \
                                      ${index === listAlphabet.length - 1 ? 'login-border-bottom' : ''}`
                                    }
                                    onClick={e => handleClickAlphabet(e, index, indexChild)}
                                  >
                                    {isShiftActive ? listAlphabetShift[index][indexChild] : listAlphabet[index][indexChild]}
                                  </div>
                                )
                              })}
                            </div>
                          )
                        })}

                        <div className="row mt-0 ps-6 pe-6 login-alphabet-action-wrapper">
                          <div className="col-md-9 offset-md-3">
                            <div
                              className={`d-inline-block me-2 ps-2 pe-2 login-alphabet-action ${(active.tag === 'alphabet' && active.category === 'action' && active.item === 'shift') || isShiftActive ? 'active' : ''}`}
                              onClick={handleClickShift}
                            >
                              Shift
                            </div>
                            <div
                              className={`d-inline-block me-2 ps-2 pe-2 login-alphabet-action ${active.tag === 'alphabet' && active.category === 'action' && active.item === '@' ? 'active' : ''}`}
                              onClick={handleClickACong}
                            >
                              @
                            </div>
                            <div
                              className={`d-inline-block me-2 ps-2 pe-2 login-alphabet-action ${active.tag === 'alphabet' && active.category === 'action' && active.item === '.' ? 'active' : ''}`}
                              onClick={handleClickDot}
                            >
                              .
                            </div>
                            <div
                              className={`d-inline-block ms-2 ps-2 pe-2 login-alphabet-action ${active.tag === 'alphabet' && active.category === 'action' && active.item === 'backspace' ? 'active' : ''}`}
                              onClick={handleClickBackspace}
                            >
                              Xóa
                            </div>
                            <div
                              className={`d-inline-block ms-2 ps-2 pe-2 login-alphabet-action ${active.tag === 'alphabet' && active.category === 'action' && active.item === 'done' ? 'active' : ''}`}
                              onClick={handleClickDone}
                            >
                              Xong
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="login-container col-md-3">
                    <div className="mb-3 login-title">Đăng Nhập</div>
                    <div className="mb-3">
                      <label className="form-label label">Số điện thoại hoặc Email</label>
                      <div
                        onClick={e => handleClickInput('email')}
                        className={`form-control email-input ${active.field === 'email' ? 'active' : ''}`}
                      >{usernameText}</div>
                    </div>
                    <div className="mb-4">
                      <label className="form-label label">Mật khẩu</label>
                      <div
                        onClick={e => handleClickInput('password')}
                        className={`form-control password-input ${active.field === 'password' ? 'active' : ''}`}
                      >{passwordText}</div>
                    </div>
                    {errorText
                      ? (<div className="error-text">{errorText}</div>)
                      : null}
                    <button
                      onClick={handleClickLogin}
                      className={`btn btn-primary login-btn ${active.field === 'loginBtn' ? 'active' : ''}`}
                    >
                      {
                        isLoading
                          ? '...'
                          : 'Đăng nhập'
                      }
                    </button>
                    <button
                      onClick={handleClickBack}
                      className={`btn btn-primary back-btn ${active.field === 'cancelBtn' ? 'active' : ''}`}
                    >
                      Trở lại
                    </button>
                  </div>

                  <div className="keyboard">
                    {listAlphabet.map((item, index) => {
                      return (
                        <div key={index} className="row login-alphabet-container">
                          {item.map((itemChild, indexChild) => {
                            return (
                              <div
                                key={indexChild}
                                className={`col-md-1 login-alphabet \
                                  ${active.tag === 'alphabet' && active.category === index && active.item === indexChild ? 'active' : ''} \
                                  ${indexChild === item.length - 1 ? 'login-border-right' : ''} \
                                  ${index === listAlphabet.length - 1 ? 'login-border-bottom' : ''}`
                                }
                                onClick={e => handleClickAlphabet(e, index, indexChild)}
                              >
                                {isShiftActive ? listAlphabetShift[index][indexChild] : listAlphabet[index][indexChild]}
                              </div>
                            )
                          })}
                        </div>
                      )
                    })}

                    <div className="row mt-2 ps-6 pe-6">
                      <div className="col-md-9 offset-md-3">
                        <div
                          className={`d-inline-block me-4 ps-4 pe-4 login-alphabet-action ${(active.tag === 'alphabet' && active.category === 'action' && active.item === 'shift') || isShiftActive ? 'active' : ''}`}
                          onClick={handleClickShift}
                        >
                          Shift
                        </div>
                        <div
                          className={`d-inline-block me-4 ps-4 pe-4 login-alphabet-action ${active.tag === 'alphabet' && active.category === 'action' && active.item === '@' ? 'active' : ''}`}
                          onClick={handleClickACong}
                        >
                          @
                        </div>
                        <div
                          className={`d-inline-block me-4 ps-4 pe-4 login-alphabet-action ${active.tag === 'alphabet' && active.category === 'action' && active.item === '.' ? 'active' : ''}`}
                          onClick={handleClickDot}
                        >
                          .
                        </div>
                        <div
                          className={`d-inline-block ms-4 ps-4 pe-4 login-alphabet-action ${active.tag === 'alphabet' && active.category === 'action' && active.item === 'backspace' ? 'active' : ''}`}
                          onClick={handleClickBackspace}
                        >
                          Xóa
                        </div>
                        <div
                          className={`d-inline-block ms-4 ps-4 pe-4 login-alphabet-action ${active.tag === 'alphabet' && active.category === 'action' && active.item === 'done' ? 'active' : ''}`}
                          onClick={handleClickDone}
                        >
                          Xong
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                )
              }
          </div>
        </div>
      </div>

      <div className="login-modal-back-instruction">
        Bấm &nbsp;<FontAwesomeIcon className="content-button-shadow" icon={faArrowLeft} /> Back &nbsp;trên điều khiển để trở về
      </div>
      <div className="login-modal-version">
        {isVersionShow ? VERSION : ''}
      </div>
    </div>
  )
})

LoginModal.displayName = 'LoginModal'

export default LoginModal
