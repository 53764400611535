import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  content: null
}

const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    setContent (state, action) {
      state.content = action.payload
    }
  }
})

export const {
  setContent
} = contentSlice.actions
export default contentSlice.reducer
