import  { useEffect, useState, useCallback, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useInfiniteQuery } from 'react-query'
import useIntersectionObserver from 'common/hook'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import {
  keyCode,
  formatDataChunk,
  scrollVertical,
  getDataTenant,
  checkVipBadge,
  callApiRoot
} from 'common/utils'
import Spinner from 'components/Spinner'
import '../styles/viewmore.scss'
import { setIsLoadingOverlay } from 'features/modal/modalSlice'
import useFollowUpgrade from 'hooks/useFollowUpgrade'
import Thumbnail from 'components/thumbnail'
import config from 'common/config'

const Viewmore = (props) => {
  const history = useHistory()
  const loadMoreRef = useRef()
  const dispatch = useDispatch()
  const ribbonViewmore = useSelector((state) => state.ribbon.ribbonViewmore)
  const login = useSelector((state) => state.login)
  const { isLogin, isVip, subTier } = login
  const isShowSubscription = useSelector((state) => state.subscription.isShowSubscription)

  const isShowWarningLogin = useSelector((state) => state.modal.isShowWarningLogin)
  const isShowLogin = useSelector((state) => state.login.isShowLogin)
  const isLoadingOverlay = useSelector((state) => state.modal.isLoadingOverlay)
  const menu = useSelector((state) => state.menu.menu)
  const account = useSelector(state => state.login.account)

  const [data, setData] = useState([])
  // const [ribbonData, setRibbonData] = useState(null)
  const [title, setTitle] = useState('')
  const [active, setActive] = useState({
    tag: 'viewmore',
    page: 0,
    category: 0,
    item: 0
  })
  const [selectedContent, setSelectedContent] = useState({})
  const { handleClick, isLoadingUpgrade } = useFollowUpgrade()

  const {
    data: dataViewmore,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage
  } = useInfiniteQuery(['getViewmore', ribbonViewmore], async ({ pageParam = 0 }) => {
    if (ribbonViewmore && ribbonViewmore.id) {
      if (ribbonViewmore.type === 5) {
        const data = await callApiRoot('GET', `tenants/${config.tenantSlug}/accounts/${account?.profile?.id}/tenant_pages/${menu.slug}/personalized_ribbons/${ribbonViewmore.slug}?page=${pageParam}&limit=20`)
        return data.data
      }
      return await getDataTenant('GET', `tenant_pages/${menu.slug}/ribbons/${ribbonViewmore.id}?page=${pageParam}&limit=20`)
    }
  }, {
    getNextPageParam: lastPage => {
      const currentCountContent =
          (lastPage?.metadata?.page + 1) * lastPage?.metadata?.limit
      if (currentCountContent < lastPage?.metadata?.total) {
        return lastPage?.metadata?.page + 1
      } else {
        return false
      }
    }
  })

  const eventListener = useCallback(() => {
  }, [data?.pages, active, isFetchingNextPage, isShowSubscription, isShowWarningLogin, isShowLogin, isLoadingOverlay])


  useEffect(() => {
    window.onpopstate = () => {
      history.goBack()
    }
  }, [])

  useEffect(() => {
    if (isLoadingUpgrade) {
      return dispatch(setIsLoadingOverlay(true))
    }
    dispatch(setIsLoadingOverlay(false))
  }, [isLoadingUpgrade])

  useEffect(() => {
    document.addEventListener('keydown', handleKey)
    return () => {
      document.removeEventListener('keydown', handleKey)
    }
  }, [eventListener])

  useEffect(() => {
    if (dataViewmore) {
      setData(() => dataViewmore)
      setTitle(dataViewmore?.pages[0]?.name)
    }
  }, [dataViewmore])

  const renderPeople = (peoples, role) => {
    if (peoples && peoples.length > 0) {
      const temp = []
      // eslint-disable-next-line array-callback-return
      peoples.map((item, index) => {
        if (item.role === role) {
          temp.push(item.name)
        }
      })

      if (temp.length > 0) {
        return temp.join(', ')
      }

      return null
    }

    return null
  }

  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */
  const handleKey = (e) => {
    e.preventDefault()
    if (isShowSubscription || isShowWarningLogin || isShowLogin || isLoadingOverlay) return

    switch (e.keyCode) {
      case keyCode.RETURN: {
        if (!isShowSubscription) {
          history.goBack()
        }
        break
      }
      case keyCode.EXIT: {
        if (!isShowSubscription) {
          history.goBack()
        }
        break
      }
      case keyCode.ENTER: {
        if (!isShowSubscription) {
          if (active.tag === 'back') {
            history.goBack()
          } else {
            const dataChunk = formatDataChunk(data.pages[active.page], 4)
            const ribbonItem = dataChunk[active.category] && dataChunk[active.category][active.item]
            const isBadge = checkVipBadge(ribbonItem, login)

            setSelectedContent(ribbonItem)
            handleClick(ribbonItem, isBadge)

            // dispatch(setRibbonContent(ribbonItem))
            // history.push('/content')
          }
        }
        break
      }
      // Từ danh sách lên nút Back
      // Navigation up danh sách
      case keyCode.UP: {
        if (!isShowSubscription) {
          if (!isFetchingNextPage) { // khi không loading nextpage
            if (active.tag === 'viewmore') {
              if (active.page === 0 && active.category === 0) {
                setActive({
                  tag: 'back',
                  page: 0,
                  category: 0,
                  item: 0
                })
              } else if (active.page === 0 && active.category === 1) {
                scrollVertical('viewmore', 'viewmore', 'UP')
                setActive({
                  tag: 'viewmore',
                  page: 0,
                  category: 0,
                  item: active.item
                })
              } else {
                if (active.category === 0) { // Khi đến top của 1 trang
                  scrollVertical('viewmore', `viewmore-container-${active.page - 1}-4`, 'UP')
                  setActive({
                    tag: 'viewmore',
                    page: active.page - 1,
                    category: 4,
                    item: 0
                  })
                } else {
                  scrollVertical('viewmore', `viewmore-container-${active.page}-${active.category - 1}`, 'UP')
                  setActive({
                    tag: 'viewmore',
                    page: active.page,
                    category: active.category - 1,
                    item: 0
                  })
                }
              }
            }
          }
        }
        break
      }
      // Từ nút Back xuống danh sách
      // Navigation down danh sách
      case keyCode.DOWN: {
        if (!isShowSubscription) {
          if (active.tag === 'back') {
            setActive({
              tag: 'viewmore',
              page: 0,
              category: 0,
              item: 0
            })
          } else {
            if (!isFetchingNextPage) { // khi không loading nextpage
              if ((active.page < (data.pages.length - 1)) && active.category === 4) {
                // Chỉ scroll khi có dữ liệu
                if (data.pages[active.page + 1] && data.pages[active.page + 1].items && data.pages[active.page + 1].items.length > 0) {
                  scrollVertical('viewmore', `viewmore-container-${active.page + 1}-0`, 'DOWN')
                  setActive({
                    tag: 'viewmore',
                    page: active.page + 1,
                    category: 0,
                    item: 0
                  })
                }
              } else {
                const dataChunk = formatDataChunk(data.pages[active.page], 4)
                if (dataChunk && dataChunk[active.category + 1]) { // kiểm tra còn item không
                  scrollVertical('viewmore', `viewmore-container-${active.page}-${active.category + 1}`, 'DOWN')
                  setActive({
                    tag: 'viewmore',
                    page: active.page,
                    category: active.category + 1,
                    item: 0
                  })
                }
              }
            }
          }
        }
        break
      }
      // Navigation left danh sách
      case keyCode.LEFT: {
        if (!isShowSubscription) {
          if (active.tag === 'viewmore') {
            if (!isFetchingNextPage) { // khi không loading nextpage
              if (active.item > 0) {
                setActive({
                  tag: 'viewmore',
                  page: active.page,
                  category: active.category,
                  item: active.item - 1
                })
              }
            }
          }
        }
        break
      }
      // Navigation right danh sách
      case keyCode.RIGHT: {
        if (!isShowSubscription) {
          if (active.tag === 'viewmore') {
            if (!isFetchingNextPage) { // khi không loading nextpage
              const dataChunk = formatDataChunk(data.pages[active.page], 4)
              if (dataChunk[active.category] && dataChunk[active.category][active.item + 1]) {
                setActive({
                  tag: 'viewmore',
                  page: active.page,
                  category: active.category,
                  item: active.item + 1
                })
              }
            }
          }
        }
        break
      }
    }
  }

  const handleClickBack = () => {
    history.goBack()
  }

  const handleClickItem = (e, category, item, isBadge) => {
    setActive({
      page: 0,
      category: category,
      item: item
    })

    const dataChunk = formatDataChunk(data.pages[active.page], 4)
    const ribbonItem = dataChunk[category] && dataChunk[category][item]

    setSelectedContent(ribbonItem)
    checkContent(ribbonItem, isBadge)

    // dispatch(setRibbonContent(ribbonItem))
    // history.push('/content')
  }

  useIntersectionObserver({
    target: loadMoreRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage
  })

  const checkContent = (ribbonItem, isBadge) => {
    handleClick(ribbonItem, isBadge)
  }


  return (
    <div id="viewmore-wrapper" className={`container-fluid ${isFetchingNextPage ? 'overlay' : ''} viewmore-bg-color`}>
      {isLoading &&
        <Spinner />
      }

      {!isLoading &&
        <div
          id="viewmore"
          className="viewmore"
        >
          <div className="row">
            <div className="col col-md-12">
              <p id="viewmore-title" className="viewmore-title">
                <FontAwesomeIcon
                  className={`viewmore-back-icon ${active.tag === 'back' ? 'active' : ''}`}
                  icon={faChevronLeft}
                  onClick={handleClickBack}
                />
                {title}
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col col-md-12">

              {data && data.pages && data.pages.map((page, indexPage) => {
                const dataChunk = formatDataChunk(page, 4)

                return (
                  <div key={indexPage}>
                    {dataChunk && dataChunk.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="row pt-4"
                          id={`viewmore-container-${indexPage}-${index}`}
                        >
                          {item && item.map((itemChild, indexChild) => {
                            let timeDetail = ''
                            if (itemChild?.type === 2) {
                              timeDetail =
                                itemChild?.released_episode_count > 0 && itemChild?.released_episode_count === itemChild?.runtime
                                  ? (
                                      `Trọn bộ ${itemChild?.released_episode_count} tập`
                                    )
                                  : (
                                      <>{itemChild?.released_episode_count + '/' + itemChild?.runtime}</>
                                    )
                            }

                            const isBadge = checkVipBadge(itemChild, login)
                            return (
                              <div
                                onClick={(e) => handleClickItem(e, index, indexChild, isBadge)}
                                key={indexChild}
                                className="col-md-3 ps-2 pe-2 viewmore-item-wrapper"
                              >
                                <Thumbnail
                                  isBadge={isBadge}
                                  itemChild={itemChild}
                                  clss={`viewmore-item ${(active.tag === 'viewmore' && active.page === indexPage && active.category === index && active.item === indexChild) ? 'active' : ''}`}
                                >
                                <div className='viewmore-content-description'>
                                  <div className='viewmore-content-timeDetail'>{timeDetail}</div>

                                  {renderPeople(itemChild?.people, 'DIRECTOR')
                                    ? (
                                        <div className='viewmore-content-description-row'>
                                          <span className='viewmore-content-description-label'>Đạo diễn:</span> <span className='viewmore-content-description-value'>{renderPeople(itemChild?.people, 'DIRECTOR')}</span>
                                        </div>
                                      )
                                    : null}

                                  {renderPeople(itemChild?.people, 'ACTOR')
                                    ? (
                                        <div className='viewmore-content-description-row'>
                                          <span className='viewmore-content-description-label'>Diễn viên:</span> <span className='viewmore-content-description-value'>{renderPeople(itemChild?.people, 'ACTOR')}</span>
                                        </div>
                                      )
                                    : null}
                                  </div>
                                </Thumbnail>
                              </div>
                            )
                          })}
                        </div>
                      )
                    })}
                  </div>
                )
              })}

              <div ref={loadMoreRef}></div>

              {isFetchingNextPage &&
                <Spinner />
              }
            </div>
          </div>
        </div>
      }

    </div>
  )
}

export default Viewmore




