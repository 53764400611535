/* eslint-disable no-async-promise-executor */
import { useState, useEffect, useCallback, useMemo } from 'react'
import { useQuery } from 'react-query'
import { getDataTenant, checkTierAvaiable } from 'common/utils'

import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setPlayerData } from 'features/player/playerSlice'
import { setLinkPlay } from 'features/season/seasonSlice'
import { setIsShowSubscription, setMessageSubscription } from '../../subscription/subscriptionSlice'
import { setDataSeason } from 'features/season/seasonSlice'


const useFetchApiSeason = ({ type }) => {

  const dispatch = useDispatch()
  const history = useHistory()
  const login = useSelector((state) => state.login)
  const { isVip, subTier, isLogin } = login
  const [episode, setEpisode] = useState(null)
  const [season, setSeason] = useState(null)

  const content = useSelector((state) => state.content.content)
  const playerData = useSelector((state) => state.player.playerData)
  const dataSeason = useSelector((state) => state.season.dataSeason)
  const message = useSelector((state) => state.subscription.message)

  const { data: dataSeasonById, isLoadingSeasonById } = useQuery(['getSeasonById', season], async () => {
    if (season) {
      return await getDataTenant('GET', `seasons/${season.id}/`)
    }
  })

    const { data: dataContent, isFetching: isLoadingContentSeason } = useQuery(['getContentEpisode', episode], async () => {
    if (episode) {
      return await getDataTenant('GET', `contents/${episode.slug}/view?platform=ctv`)
    }
  })

  const isNextContent = useMemo(() => {
    if(!dataSeason?.length) return 0
    const indx = dataSeason.findIndex((ele) => {
      return ele.id === playerData?.currentEpisodes?.id
    })
    const idxEps = indx + 1
    if (idxEps === dataSeason?.length) return 0
    const episodeData = dataSeason[idxEps]

    const isCanplay = checkTierAvaiable(subTier, episodeData?.min_sub_tier, isVip)
    const isPlay = isCanplay || episodeData?.can_preview || episodeData?.has_free_content
    // return isPlay ? idxEps : 0
    return idxEps
  }, [dataSeason, isVip, playerData])

  const isPrevContent = useMemo(() => {
    if(!dataSeason?.length) return false
    const indx = dataSeason.findIndex((ele) => {
      return ele.id === playerData?.currentEpisodes?.id
    })
    const idxEps = indx - 1
    if (idxEps < 0) return false
    return idxEps
  }, [dataSeason, isVip, playerData])

  useEffect(() => {
    if (dataSeasonById) {
      dispatch(setDataSeason(dataSeasonById.episodes))
    }
  }, [dataSeasonById])

  useEffect(() => {
    if (dataContent) {
      const isCanplay = checkTierAvaiable(subTier, dataContent?.min_sub_tier, isVip)
      if (isLogin && !isCanplay && !dataContent?.is_watchable) {
        dispatch(setIsShowSubscription(true))
        return
      }
  
      const dataContents = {
        ...playerData,
        defaultEpisodeId: dataContent?.id,
        defaultEpisodeSlug: dataContent && dataContent?.slug,
        title: `Tập ${episode?.episode}`,
        content_title: dataContent?.title,
        linkPlay: dataContent?.link_play,
        drmSession: dataContent.drm_session_info ? {
          ...dataContent.drm_session_info,
          ...dataContent.cwl_info
        } : null,
        playInfo: dataContent?.play_info?.data,
        currentEpisodes: dataContent,
        progress: dataContent?.progress,
        cwl_info: dataContent.cwl_info,
      }

      dispatch(setPlayerData(dataContents))
      if (type === 'page' || history.location.pathname === '/content') {
        history.push('/player')
      } else {
        dispatch(setLinkPlay(dataContent.link_play))
      }
    }
  }, [dataContent])

  const handleNextEps = useCallback(() => {
    if(!dataSeason?.length) return 0
    const episodeData = dataSeason[isNextContent]
    const isCanplay = checkTierAvaiable(subTier, episodeData?.min_sub_tier, isVip)
    const isPlay = isCanplay || episodeData?.can_preview || episodeData?.has_free_content
    if (!isPlay && !message) dispatch(setMessageSubscription(content.warning_message))
    if (isPlay) {
      return setEpisode(episodeData)
    } 
    dispatch(setIsShowSubscription(true))
  }, [dataSeason, playerData, isVip, isNextContent])

  const handlePrevEps = useCallback(() => {
    if(!dataSeason?.length) return 0
    const episodeData = dataSeason[isPrevContent]
    if (isPrevContent < 0 && !message) dispatch(setMessageSubscription(content.warning_message))
    if (isPrevContent >= 0) {
      return setEpisode(episodeData)
    } 
    dispatch(setIsShowSubscription(true))
  }, [dataSeason, playerData, isVip, isPrevContent])


  return {
    setEpisode,
    setSeason,
    handleNextEps,
    handlePrevEps,

    episode,
    season,
    dataSeasonById,
    isLoadingSeasonById,
    isNextContent,
    isPrevContent,
    isLoadingContentSeason
  }
}

export default useFetchApiSeason
