interface loggger {
  enableLogger: () => void;
  disableLogger: () => void;
  enableClear: () => void;
  disableClear: () => void; 
}


export const addIma3 = () => {
  try {
    const bodyDoc = document.getElementsByTagName("body")[0]

    const cons = document.createElement("script")
    cons.type = "text/javascript"
    cons.src = `https://imasdk.googleapis.com/js/sdkloader/ima3.js`
    cons.id = "consolerescript"
    bodyDoc.appendChild(cons)
    cons.onload = () => {}
  } catch (e) {
    console.log('ima3 add error', e)
  }
}

export const logger = () => {
  let oldConsoleLog: any = null
  let oldClearLog: any = null
  const pub: loggger = {
    enableLogger: function (): void {
      if (oldConsoleLog == null) return
      window['console']['log'] = oldConsoleLog
    },
    disableLogger: function (): void {
      oldConsoleLog = console.log
      window['console']['log'] = function () {}
    },
    enableClear: function (): void {
      if (oldClearLog == null) return
      window['console']['clear'] = oldClearLog
    },
    disableClear: function (): void {
      oldClearLog = console.clear
      window['console']['clear'] = function () {}
    }
  }
  return pub
}


