import * as shaka from 'shaka-player'
import axios from 'axios'
import queryString from 'query-string'
import config from 'common/config'
import jquery from 'jquery'

let video
let player
let vjsPlayer
let detail = {}
let globalToken = ''
let timer = null
let manifestUrl = ''
const language = 'en'
let modal = null
let method = null
let isFreshToken = false

const APIPROD = 'https://api.msky.vn'
const APITEST = 'https://sandbox.msky.vn'

const api = config.appEnv === 'PROD' || config.appEnv === 'RC' ? APIPROD : APITEST

const contents = {
  en: {
    txt400: 'Sorry, this content has been expired. Please try watching other contents!',
    txt401: 'Sorry, there is some problem with the authentication of the encrypted stream. Please try again!',
    txt405: 'Sorry, your number of concurrent streams exceeds the limit. Please try again!'
  },
  vi: {
    txt400: 'Xin lỗi, nội dung này đã hết hạn. Vui lòng tham khảo & xem các nội dung khác!',
    txt401: 'Xin lỗi, có lỗi xảy ra trong quá trình xác thực của nội dung mã hóa. Vui lòng thử lại!',
    txt405: 'Xin lỗi, số lượng lượt xem đồng thời của tài khoản này đã vượt quá giới hạn cho phép. Vui lòng đóng ứng dụng trên thiết bị khác và thử lại!'
  }
}

function setToken(params) {
  globalToken = params
  method?.setTokenGlobal(params)
}

function setClosePlayer (value, message) {
  // modal = vjsPlayer.createModal(message)
  // modal.addClass('vjs-drm-error')
  vjsPlayer.trigger('drm-error')
  clearInterval(timer)
}

async function pingOrRefreshToken (value = null) {
  /* eslint-disable camelcase */
  const { session, operator_id, session_id, limit_ccu_ping_url, limit_ccu_refresh_url, user_id} = detail.drmSession

  let params = {
    sessionId: session_id,
    operatorId: operator_id || null,
    session: session
  }
  if (globalToken && (!value || isFreshToken)) {
    params = { token: globalToken }
  }

  const queryParams = queryString.stringify(params)
  let linkApi = `${limit_ccu_ping_url}?${queryParams}`
  if (value === 'refresh') {
    isFreshToken = false
    linkApi = `${limit_ccu_refresh_url}?${queryParams}`
  }

  try {
    const tokenData = await axios.get(linkApi, { validateStatus: () => true })
    const { data, status } = tokenData
    data.token && setToken(data.token)
    if (status) {
      switch (status) {
        case 401: {
          setClosePlayer(true, contents[language].txt401)
          break
        }
        case 405: {
          setClosePlayer(true, contents[language].txt405)
          break
        }
        case 400: {
          setClosePlayer(true, contents[language].txt400)
          break
        }
        case 426: {
          pingOrRefreshToken('refresh')
          break
        }
        default:
          // modal && modal.close()
          break
      }
    }
    return data && data.token
  } catch (error) {
    console.error(error, 'error')
  }
  return globalToken
}

export async function endDrmToday(token = globalToken, endCcu = null) {
  const apiEnd = endCcu || detail?.drmSession?.limit_ccu_end_url
  const params = {
    token: token
  }
  clearInterval(timer)
  if (!token) return
  // let data = {
  //   token: null,
  //   content_id: detail.id,
  //   slug: detail.slug,
  //   baseUrl: window.location.href,
  //   time: moment().unix(),
  //   remove: true
  // }
  // if (token) store.dispatch(actions.setTokenDrm(data))
  const queryParams = queryString.stringify(params)
  const linkApi = `${apiEnd}?${queryParams}`
  const tokenData = await axios.get(linkApi, { validateStatus: () => true })
  const { status } = tokenData
  if (status === '426' || status === 426) {
    setToken(token)
    const newTokenData = await pingOrRefreshToken('refresh')
    await endDrmToday(newTokenData || null)
    return
  }
  console.log('----')
  setToken('')
}

async function getWidevineCert () {
  /* eslint-disable camelcase */
  const { widevide_license_path, user_id, session, merchant, auth_token } = detail.drmSession
  const message = new Uint8Array(2)
  // in order our drm server to redirect this to google we need to send empty 2 bytes with values 8 and 4
  message[0] = 8
  message[1] = 4
  const request = new XMLHttpRequest()
  request.open(
    'POST',
    widevide_license_path
  )
  request.responseType = 'arraybuffer'

  let params = {
    userId: user_id,
    sessionId: session,
    merchant: merchant || 'qnet'
  }

  params = btoa(JSON.stringify(params))

  request.setRequestHeader('dt-custom-data', params)

  await request.addEventListener('error', function (ev) {
    console.log('error', ev)
  })

  await request.addEventListener('load', function (ev) {
    console.log('data', ev)
  })

  request.send(message)
  return message
}

function setWidevineDataResponse (response) {
  const wrappedArray = new Uint8Array(response.data)

  // Convert it to a string.
  const wrappedString = String.fromCharCode.apply(null, wrappedArray)

  // Parse the JSON string into an object.
  let wrapped
  try {
    wrapped = JSON.parse(wrappedString)
  } catch (err) {
    console.log('err', err)
    throw new Error('Error while parsing JSON: ' + err)
  }
  // This is a base64-encoded version of the raw license.
  const rawLicenseBase64 = wrapped['license']
  // wrapped['license']
  // Decode it to a string.
  const rawLicenseString = atob(rawLicenseBase64)

  // Convert that string into a Uint8Array and replace the response data to
  // feed it to the Widevine CDM.
  response.data = new Uint8Array(rawLicenseString.length)
  for (let i = 0; i < rawLicenseString.length; ++i) {
    response.data[i] = rawLicenseString.charCodeAt(i)
  }
}

async function setupDRMToday () {
  /* eslint-disable camelcase */
  await getCenc()
  try {
    // timer = setInterval(async () => {
    const token = await pingOrRefreshToken()
    if (token || globalToken) {
      clearInterval(timer)
      playStream()
      // await getWidevineCert()
      timer = setInterval(async () => {
        await pingOrRefreshToken()
      }, 60000)
    }
    // }, 5000)
  } catch {
    console.log('err')
    return
  }
}

async function getCenc() {
  const { widevide_license_path, user_id, session, merchant , auth_token} = detail.drmSession
  
  player.configure({
    drm: {
      servers: {
        'com.widevine.alpha': widevide_license_path
        // 'com.microsoft.playready': playready_license_path
      },
      advanced: {
        'com.widevine.alpha': {
          videoRobustness: 'SW_SECURE_DECODE',
          audioRobustness: 'SW_SECURE_CRYPTO'
        }
      }
    }
  })
  // function getAllMethods(net) {
  //   for (var key in net) {
  //     jquery('#debug-shakaaaa').append(`<span>func: ${JSON.stringify(key)}</span> `)
  
  //   }
  // }
  const net = player.getNetworkingEngine()
  const requestTypes = shaka.net.NetworkingEngine.RequestType
  // jquery('#debug-shakaaaa').append(`<p>net: ${JSON.stringify(net)}</p> `)
  // getAllMethods(net)
  // Setting up the License Request

  net.registerRequestFilter(function (type, request) {
    // jquery('#debug-shakaaaa').append(`<p>registerRequestFilter : ${type}</p> `)
    
    if (type === requestTypes.LICENSE) {
      let drmTodayData = {
        userId: user_id,
        sessionId: session,
        merchant
      }
      if (type == shaka.net.NetworkingEngine.RequestType.LICENSE) {
        if (auth_token) {
          request.headers['x-dt-auth-token'] = auth_token;
        }
        drmTodayData = btoa(JSON.stringify(drmTodayData))
        request.headers['dt-custom-data'] = drmTodayData
        request.headers['x-dt-custom-data'] = drmTodayData;
      }
    }
  })

  // Setting up the license response
  net.registerResponseFilter(function (type, response) {
    // jquery('#debug-shakaaaa').append(`<p>registerResponseFilter response: ${JSON.stringify(response)}</p> `)
    
    if (type === requestTypes.LICENSE) {
      const keySystem = player.keySystem()
      if (keySystem === 'com.widevine.alpha') {
        setWidevineDataResponse(response)
      }

      // For Playready the data returned by DRMToday doesn't need handling and
      // can be sent directly to the CDM.
    }
  })
  return new Promise(resolve => {
    setTimeout(() => {
      return resolve()
    }, 1000);
  })
}

function playStream() {
  player?.unload?.()?.then(function () {
    return player?.load(manifestUrl)
  }).then(function () {
    console.log('Playing stream')
    if (vjsPlayer.paused()) {
      const playPromise = vjsPlayer?.play()
      if (playPromise !== undefined) {
        playPromise.then(_ => {
          jquery(`#${vjsPlayer.id_}`).focus()
        })
      }
      setTimeout(() => {
        jquery(`#${vjsPlayer.id_}`).focus()
        vjsPlayer?.play()
      }, 1000)
    }
  }).catch(onError)
}

function initDrm (players, playerData, methods) {
  // Install built-in polyfills to patch browser incompatibilities.
  shaka.polyfill.installAll()
  vjsPlayer = players
  detail = playerData
  method = methods
  manifestUrl = playerData?.playInfo?.dash_link_play || playerData.linkPlay

  // Check to see if the browser supports the basic APIs Shaka needs.
  if (shaka.Player.isBrowserSupported()) {
    // Everything looks good!
    initPlayer(methods)
    // loadAppEvents()
  } else {
    // This browser does not have the minimum set of APIs we need.
    console.error('Browser not supported!')
  }
}

function initPlayer (methods) {
  const src = vjsPlayer.src
  vjsPlayer.src = function (value) {
    if (value !== undefined) {
      src.call(vjsPlayer, value)
    } else {
      return manifestUrl
    }
  }

  video = vjsPlayer.el_.querySelector('video')
  player = new shaka.Player(video)
  // setupDRMToday()

  if (detail?.drmSession) {
    setupDRMToday()
  }

  vjsPlayer.on('ended', function () {
    endDrmToday()
  })

  vjsPlayer.on('dispose', function () {
    clearInterval(timer)
    endDrmToday()
    const net = player.getNetworkingEngine()
    // Setting up the license response
    net?.clearAllRequestFilters()
    net?.clearAllResponseFilters()
    net?.destroy()
    player?.unload(manifestUrl)
    player?.destroy()
    video = null
    player = null
    vjsPlayer = null
    detail = {}
    globalToken = ''
    timer = null
    manifestUrl = ''
    // method = null
    isFreshToken = false
  })

  player.addEventListener('error', onErrorEvent)
}

function onErrorEvent (event) {
  // Extract the shaka.util.Error object from the event.
  onError(event.detail)
}

function onError (error) {
  // Log the error.
  console.error('Error code', error.code, 'object', error)
}

export default initDrm
