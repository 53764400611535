/* eslint-disable max-len */
/* eslint-disable no-undef */
import {  useRef, useMemo, useEffect } from 'react'
import { useQuery } from 'react-query'
import { getDataTenant } from 'common/utils'
import { useSelector } from 'react-redux'
import config from 'common/config'
import axios from 'axios'
import { CONTENT_TYPE_FOR_VOD } from 'constant/content'
import { getVerisonOs } from 'common/userAgent'
import  { XMLParser, XMLBuilder }  from 'fast-xml-parser'
// eslint-disable-next-line @typescript-eslint/no-var-requires, no-undef

const DEBUG_IMA = false
const useImaAds = () => {
  const playerRef = useRef(null)
  const listCueAdsPoint = useRef(null)
  const adsVideo = useRef(null)
  const adsXmlRef = useRef(null)
  const adsCompleteTimer = useRef(false)
  const currentPosition = useRef(0)
  const durationRef = useRef(0)
  const adsCuePoint = useRef([])

  const playerData = useSelector((state) => state.player.playerData)
  // const login = useSelector((state) => state.login)
  // const { isVip, subTier } = login
  const idContent = useMemo(() => playerData?.defaultEpisodeId  || playerData?.id , [playerData])
  const versionOs = useMemo(() => Math.floor(getVerisonOs()) || 5, [])

  const { data: dataAds, isFetching: isFetchingAds } = useQuery(['getAds',idContent], async() => {
    // eslint-disable-next-line no-undef
    const path = `platforms/${config?.rootMenu}/video_ads/?content_id=${idContent}`
    const data = await getDataTenant('GET', path)
    let adTagXml = await adsTagXml(data?.ad_tag?.ad_tag_url)
    adTagXml = await settingImaAds(adTagXml)
    adsXmlRef.current = adTagXml
    return data
  })

  // const initIma = function init(options) {
  //   this.ima = new videojsIma.default(this, options);
  // };

  const registerPlugin = () => {
    // const register = videojs.registerPlugin || videojs.plugin;
    // register('ads', contribAds.default);
    // register('ima', initIma);
  }

  const initImaAds = async (player, content, handleEndVjs, hls = {}) => {
    // const isCanplay = checkTierAvaiable(subTier, content?.min_sub_tier, isVip)
    const adTagUrl = getAdsTag()
    const os3 =  getVerisonOs().startsWith('3') // 5
    const oSVer = os3
    const adTagXml = adsXmlRef.current

    if (oSVer || !CONTENT_TYPE_FOR_VOD.includes(content.type) || !adTagUrl || adTagXml === 'Playlist') {
      return new Promise(function (resolve) {
        if (adTagXml) {
          console.log('adTagUrl not support', adsVideo.current)
          return resolve()
        }
        console.log('adTagUrl not found so ima library is not init', adsVideo.current)
        return resolve()
      })
    }
    let options = {
      adTagUrl,
      adsManagerLoadedCallback: () => adsManagerLoadedCallback(handleEndVjs, hls),
      debug: DEBUG_IMA,
      disableAdControls: true,
      useMediaCuePoints: true,
        // adsResponse: adTagXml,
    }
    if (!adTagUrl) {
      return new Promise(function (resolve) {
        loggerIma('no ads')
        return resolve()
      })
    }
    // if (adsVideo.current?.type === 'VMAP' || adTagXml !== 'VAST') {
    if (adsVideo.current?.type === 'VMAP' && adTagXml !== 'VAST') {
      const timeout =  versionOs >= 5 ? 5000 : 8000
      options = {
        ...options,
        adsRenderingSettings: {
          loadVideoTimeout: timeout,
          enablePreloading: true,
          // playAdsAfterTime: 1,
          restoreCustomPlaybackStateOnAdBreakComplete: true
        },
        autoPlayAdBreaks: false,
        contribAdsSettings: {
          timeout: timeout,
          prerollTimeout: timeout,
          debug: DEBUG_IMA,
          stitchedAds: false,
          liveCuePoints: true,
          allowVjsAutoplay: true
        }
      };
    }

    loggerIma('options', options)
    player?.ima(options);
    player.ima.setAdBreakReadyListener(() => {
      loggerIma('setAdBreakReadyListener')
      player?.ima.playAdBreak()
    })
    player.ima.initializeAdDisplayContainer()
    player?.markers({
      markerStyle: {
          'width': '5px',
          'height': '10px',
          'background-color': '#ffbb1c',
          'top' : '0'
        }   
      })
    playerRef.current = player
    getCuePoint()
    handleEventAds()
    return new Promise(function (resolve) {
      loggerIma('init')
      return resolve()
    })
  }

  const getAdsTag = (num = -1) => {
    let adstag = adsVideo.current?.ad_tag?.ad_tag_url

    const arr = [
      'https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/single_ad_samples&sz=640x480&cust_params=sample_ct%3Dlinearvpaid2js&ciu_szs=300x250%2C728x90&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=', // vpaid
      'https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/single_ad_samples&sz=640x480&cust_params=sample_ct%3Dlinear&ciu_szs=300x250%2C728x90&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=', //vast
      'https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/vmap_ad_samples&sz=640x480&cust_params=sample_ar%3Dpremidpostpod&ciu_szs=300x250&gdfp_req=1&ad_rule=1&output=vmap&unviewed_position_start=1&env=vp&impl=s&cmsid=496&vid=short_onecue&correlator=' //vmap
    ]
    if(num === -1) return adstag
    return arr[num]
  }

  const getCuePoint = () => {
    playerRef.current.on('ads-manager', response => {
      console.log('vao')
      durationRef.current = durationRef.current || playerRef.current?.duration()
      loggerIma('durationRef.current', durationRef.current)
      loggerIma('ads-manager', response.adsManager)
      const cueAdsPoint = response.adsManager.getCuePoints()
      listCueAdsPoint.current = cueAdsPoint
      addAdMarkers(cueAdsPoint, playerRef.current?.duration());
    })
  }

  const adsManagerLoadedCallback = (handleEndVjs, hls) => {
    const player =  playerRef.current
    const eventTypes = Object.keys(google.ima.AdEvent.Type)

    let adsComplete = false
    let adsPostRoll = {
      status: false,
      error: false
    }
    let currentPostionAds = 0
    let timeOutCompleteAds = null
    let timeOutAdsTimout = null
    let timeOutAdsBuffer = null
    let snapShot = {}

    player.on('readyforpostroll', function () {
      adsPostRoll.status = true
      if (player.ima.controller.sdkImpl.adPlaying) {
        console.log('adsPostRoll')
        // player.trigger('nopostroll')
        adsPostRoll.error = true
        return
      }
    })

    player.on('dispose', () => { 
      clearTimeout(timeOutCompleteAds)
      clearTimeout(timeOutAdsTimout)
      clearTimeout(timeOutAdsBuffer)
    })
    const $elAgeRating = document.getElementsByClassName('age-rating')

    eventTypes.map(item => {
      player.ima.addEventListener(google.ima.AdEvent.Type[item], res => {
        loggerIma('event Ad', google.ima.AdEvent.Type[item])
        if ($elAgeRating.length && !$elAgeRating?.[0]?.classList?.contains('hide')) {
          $elAgeRating[0].classList.add('hide')
        }
        if (google.ima.AdEvent.Type[item] === 'allAdsCompleted') {
          clearTimeout(timeOutCompleteAds)
          clearTimeout(timeOutAdsTimout)
          clearTimeout(timeOutAdsBuffer)
          if (adsPostRoll.status === true) {
            handleEndVjs()
          }
        }

        if (google.ima.AdEvent.Type[item] === 'contentResumeRequested' && adsComplete) {
          clearTimeout(timeOutAdsTimout)
          clearTimeout(timeOutAdsBuffer)
          if (adsPostRoll.status) {
            if (adsPostRoll.error && Math.floor(currentPostionAds) !== Math.floor(durationRef.current)) {
              adsPostRoll = {
                status: false,
                error: false
              }
              return
            }
            if (Math.floor(currentPostionAds) === Math.floor(durationRef.current)) {
              loggerIma('trigger ended vjs')
              player.trigger('ended')
              return
            }
            return
          }

          if (player.paused()) {
            const el = player.el()
            hls?.attachMedia(el)
            player.play()
          }
          player.ads.endLinearAdMode();
          const adCuePointsArr = player?.markers.getMarkers()
          let tmp = adCuePointsArr?.length ? adCuePointsArr : adsCuePoint.current
          loggerIma('tmp', tmp)
          if (tmp) {
            player?.markers?.reset([...tmp])
          }
          let num = 0

          if (adsComplete) {
            adsCompleteTimer.current = setInterval(() => {
              player.play()
              player.ads.endLinearAdMode();
              num +=1
              // player.paused()
              if (player.el_.className.includes('vjs-error') || num === 10) {
                fetchVideoAndPlay(player)
                clearInterval(adsCompleteTimer.current)
                return
              }
              if (player.el_.className.includes('vjs-playing') && !player.el_.className.includes('vjs-seeking')) {
                clearInterval(adsCompleteTimer.current)
                return
              }
            }, 1000);
          }
        }
        if (google.ima.AdEvent.Type[item] === 'contentPauseRequested') {
          hls?.detachMedia()
          player?.ads?.startLinearAdMode?.()
        }

        if (google.ima.AdEvent.Type[item] === 'adBuffering') {
          clearTimeout(timeOutAdsBuffer)
          timeOutAdsBuffer = setTimeout(() => {
            adsTimeOutRequest()
            fetchVideoAndPlay(player, snapShot)
            clearTimeout(timeOutAdsBuffer)
          }, 30000)
        }

        if (google.ima.AdEvent.Type[item] === 'complete') {
          adsComplete = true
          timeOutCompleteAds = setTimeout(() => {
            timeOutAdsTimout = setTimeout(() => {
              fetchVideoAndPlay(player, snapShot)
              clearTimeout(timeOutAdsTimout)
            }, 30000)
            player?.trigger('contentResumeRequested')
            clearTimeout(timeOutCompleteAds)
            clearTimeout(timeOutAdsBuffer)
          }, 5000);
        }

        if (google.ima.AdEvent.Type[item] === 'log') {
          adsComplete = true
          player.trigger('contentResumeRequested')
          adsTimeOutRequest()
        }

        if (google.ima.AdEvent.Type[item] === 'thirdQuartile') {
          snapShot = player.ads?.snapshot
          if (Math.floor(currentPostionAds) === Math.floor(durationRef.current)) {
            player.ads._contentHasEnded = true
            return
          }
          player.ads._contentHasEnded = false
        }

        if (google.ima.AdEvent.Type[item] === 'impression') {
          currentPostionAds = removeAds(currentPostionAds)
        }

        if (google.ima.AdEvent.Type[item] === 'adCanPlay') {
          clearTimeout(timeOutCompleteAds)
          clearTimeout(timeOutAdsTimout)
        }

        if (google.ima.AdEvent.Type[item] === 'adBreakReady') {
          clearTimeout(timeOutCompleteAds)
          clearTimeout(timeOutAdsTimout)
          adsComplete = false
        }
      })
    })
  }

  const handleEventAds = () => {
    const player =  playerRef.current
    // player.on('contentchanged', function() {
    //   // in a real plugin, you might fetch new ad inventory here
    // });
    
    player.on('readyforpreroll', function () {
      loggerIma('readyforpreroll')
    })
    player.on(['adended', 'adend'], function () {
      player.ads.endLinearAdMode();
      loggerIma('adend')
    })

    player.on('ads-ad-started', function (e) {
      clearInterval(adsCompleteTimer.current)
      loggerIma('ads-ad-started')
    })
    // player.on('readyforpostroll', function () {
    //   loggerIma('readyforpostroll')
    // })

    // player.on('ads-request', function (e) {
    //   loggerIma('ads-request', e.AdsRequest)
    // })

    // player.on('adsready', function () {
    //   loggerIma('adsready')
    // })
    // player.on('contentresumed', e => {
    //   loggerIma('contentresumed', e)
    // })

    // player.on('contentResumeRequested', e => {
    //   loggerIma('contentResumeRequested', e)
    // })

    player.on('adtimeout', e => {
      loggerIma('adtimeout', e)
      player.ads.endLinearAdMode();
      player?.ima.playAdBreak()
    })

    player.on('adserror', (e) => {
      loggerIma('adserror', e)
    })

    player.on('dispose', () => {
      clearTimeout(adsCompleteTimer.current)
    })
  }


  const addAdMarkers = (adCuePointsArr, videoDuration) => {
    const player = playerRef.current
    const markersArr = []
    adCuePointsArr.map(el => {
      let time = el
      let timeReal = el
      if (el === -1) {
        time = videoDuration - 8
        timeReal = videoDuration
      }
      markersArr.push({ time, cuePoint: el, timeReal })
    })
    loggerIma('markersArr', markersArr)
    adsCuePoint.current = markersArr
    player?.markers?.reset([...markersArr])
  }

  const removeAds = (currentPostionAds) => {
    const player = playerRef.current
    let adCuePointsArr = player?.markers.getMarkers()
    const tmp = adCuePointsArr?.length ? adCuePointsArr : adsCuePoint.current
    const adsCuePointTmp = adsCuePoint.current
    adsCuePointTmp?.shift()
    adsCuePoint.current = adsCuePointTmp
    const adPodInfo = getAdPodInfo()
    // const currentTime = player.currentTime()
    let time = 0
    tmp.forEach((el, idx) => {
      if (adPodInfo?.timeOffset === el?.cuePoint) {
        currentPosition.current = el.time
        player?.markers?.remove([idx])
        time = el.timeReal
      }
    })
    return time || currentPostionAds
  }

  const getAdPodInfo = () => {
    const player = playerRef.current
    if(!player) return null
    const adsManager =  player?.ima.getAdsManager()
    const currentAds = adsManager.getCurrentAd()
    const adPodInfo = currentAds?.data?.adPodInfo
    return adPodInfo
  }

  const adsTimeOutRequest = () => {
    const player =  playerRef.current
    if (player.paused()) {
      player.play()
    }
    player.ads.endLinearAdMode();
  }

  const adsTagXml = async adsTag => {
    if (!adsTag) return ''
    try {

      const adsResponse = await axios({ method: 'GET', url: adsTag })

      let fixPrerollAds = adsResponse?.data || ''
      if (fixPrerollAds) {
        fixPrerollAds = fixPrerollAds?.replace(
          /timeOffset="(00:00:00|start)"/g,
          'timeOffset="00:00:05.100"'
        )
      }
      loggerIma('adsResponse?.data', adsResponse?.data)
      return adsResponse?.data
    } catch (e) {
      loggerIma('adsTagXml error', e)
      return ''
    }
  }

  const settingImaAds = async (adsDataXML) => {
    if (!adsDataXML) return Promise.resolve(null)
    let xmlDataStr = adsDataXML

    const parser = new XMLParser({ ignoreAttributes: false })
    const jsonObj = parser.parse(adsDataXML)

    if (jsonObj['VAST']) {
      return Promise.resolve('VAST')
    }

    if (jsonObj['Playlist']) {
      return Promise.resolve('Playlist')
    }

    let root = jsonObj['vmap:VMAP'] || {}
    let adBreaks = root['vmap:AdBreak'] || []
    const adBreaksWorkAround = []

    adBreaks.map(item => {
      if (item) {
        const timeOffsetArray = item['@_timeOffset']?.split(':')
        const timeOffsetPosition =
          Math.floor(timeOffsetArray[0] * 3600) +
          Math.floor(timeOffsetArray[1] * 60) +
          Math.floor(timeOffsetArray[2])
          adBreaksWorkAround.push(item)
      }
    })

    root['vmap:AdBreak'] = adBreaksWorkAround
    jsonObj['vmap:VMAP'] = root

    const builder = new XMLBuilder({ ignoreAttributes: false })
    xmlDataStr = builder.build(jsonObj)
    return Promise.resolve(xmlDataStr)
  }

  function fetchVideoAndPlay(player, snapShot = {}) {
    loggerIma(
      'Will reset source because playerRef.current cannot resume playback'
    )
    if (!player) return
    const oldSrc = snapShot?.currentSrc || player?.src()
    const currrentSnap = snapShot?.currentTime || currentPosition.current

    player.src(oldSrc)
    player.load()
    player.play()
    player.one('canplaythrough', () => {
      player.currentTime(currrentSnap)
    })
  }


  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (!dataAds) return
    adsVideo.current = dataAds
  }, [dataAds])

  useEffect(() => {

    return () => {
      const player = playerRef.current
      clearTimeout(adsCompleteTimer.current)

      if (!player) return
      player?.ima?.getAdsManager?.()?.destroy()
      playerRef.current = null
      window?.logger().enableLogger()
    }
  }, [])
  
  const loggerIma = (key, value = '') => {
    if (!DEBUG_IMA) return
    if (value) {
      console.log(key, value)
      return
    }
    console.log(key)
  }
  return {
    registerPlugin,
    initImaAds,
    handleEventAds,
    adsTagXml,
    isFetchingAds
  }
}

export default useImaAds
