const DebugVjs = ({ isDisplayDebugLog, obj }) => {
  if (!isDisplayDebugLog) return null;
  const {
    bandWidth,
    videoBitrateState,
    currentTimeDebug, 
    buffered, 
    videoBuffered, 
    seekable,
    bufferCount,
    currentSource,
    currentSegment,
    remoteDebugLogChannelIdRef
  } = obj
  const PLAYER_VERSION = window?.videojs?.VERSION

  return (
    <div className='debug-log-overlay'>
      <div className='measured-item'>
        <div className='measured-item-label'>Mac Address:</div>
        <div className='measured-item-value'>{remoteDebugLogChannelIdRef?.current}</div>
      </div>
      <div className='measured-item'>
        <div className='measured-item-label'>Current Playlist:</div>
        <div className='measured-item-value'>{currentSource}</div>
      </div>
      <div className='measured-item'>
        <div className='measured-item-label'>Current Segment:</div>
        <div className='measured-item-value'>{currentSegment?.resolvedUri}</div>
      </div>
      <div className='measured-item'>
        <div className='measured-item-label'>Measured Bitrate:</div>
        <div className='measured-item-value'>{bandWidth}</div>
      </div>
      <div className='measured-item'>
        <div className='measured-item-label'>Video Bitrate:</div>
        <div className='measured-item-value'>{videoBitrateState}</div>
      </div>
      <div className='measured-item'>
        <div className='measured-item-label'>Current Time:</div>
        <div className='measured-item-value'>{currentTimeDebug}</div>
      </div>
      <div className='measured-item'>
        <div className='measured-item-label'>Buffered:</div>
        <div className='measured-item-value'>{buffered}</div>
      </div>
      <div className='measured-item'>
        <div className='measured-item-label'>Video Buffered:</div>
        <div className='measured-item-value'>{videoBuffered}</div>
      </div>
      <div className='measured-item'>
        <div className='measured-item-label'>Seekable:</div>
        <div className='measured-item-value'>{seekable}</div>
      </div>
      <div className='measured-item'>
        <div className='measured-item-label'>Buffer Event:</div>
        <div className='measured-item-value'>{bufferCount}</div>
      </div>
      <div className='measured-item'>
        <div className='measured-item-label'>Player Version:</div>
        <div className='measured-item-value'>{PLAYER_VERSION}</div>
      </div>
    </div>
  )
}

export default DebugVjs