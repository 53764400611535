import axios from 'axios'

import { getHeader, handleEventForceLogout } from './utils'
import config from './config'
import { FORCE_LOGOUT_ERROR_CODE } from 'constant/settings'

const createAxios = (isNoCache: boolean) => {
  const instance = axios.create({
    baseURL: config.domainRoot,
    // timeout: 1000,
    headers: getHeader(),
    transformRequest: [
      (data, reqHeaders) => {
        const addHeader = (key: string, value: string) => !!value && (reqHeaders[key] = value)
        if (isNoCache) {
          addHeader("Cache-Control", 'no-cache')
        }
        return data
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...axios.defaults.transformRequest
    ]
  })
  instance.interceptors.response.use(function (response) {
    return response
  }, function (error) {
    const data = error?.response?.data
    if (error?.response?.status >= 400  && data?.error_code === FORCE_LOGOUT_ERROR_CODE) {
      handleEventForceLogout(error)
    }
    return Promise.reject(error);
  });
  return instance
}


export {
  createAxios,
}