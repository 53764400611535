import { useEffect, useRef } from 'react'

import { useMutation } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { setIsShowSubscription, setMessageSubscription } from 'features/subscription/subscriptionSlice'
import { setIsLoadingOverlay } from 'features/modal/modalSlice'
import { getDataTenant, checkTierAvaiable, checkVipBadge } from 'common/utils'
import { setRibbonContent,  setRibbonLive } from 'features/ribbon/ribbonSlice'
import { setPlayerData } from 'features/player/playerSlice'
import { useHistory } from 'react-router-dom'
import { setContent } from 'features/content/contentSlice'
import { FORCE_LOGOUT_ERROR_CODE } from 'constant/settings'

const CONTENT_TYPE_FOR_VOD = [2, 1, 6]
const LIVE = [5,9]


const useFollowUpgrade = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const login = useSelector((state) => state.login)
  const { isLogin, isVip, subTier } = login
  const currentMenu = useSelector((state) => state.menu.currentMenu)

  const isNavigate =useRef(false)
  const currentData =useRef(null)

  const { mutate: handleGetContentView, isLoading: isLoadingUpgrade } = useMutation(
    (contents) => getDataTenant('GET', `contents/${contents?.contentId}/view?platform=ctv`),
    {
      onSuccess: (contentView, contents) => {
        const selectedContents = contents?.selectedContent
        const content = { ...selectedContents, ...contentView }

  
        const isCanplay = checkTierAvaiable(subTier, contentView?.min_sub_tier, isVip)
        dispatch(setIsLoadingOverlay(false))
        dispatch(setMessageSubscription(contentView?.warning_message))
    
        if (isCanplay || (contentView.is_watchable && !contents?.isBadge)) {
          if (LIVE?.includes(selectedContents?.type)) {

            const dataContent = {
              id: content.id,
              slug: content.slug,
              defaultEpisodeId: null,
              season: false,
              listEpisode: [],
              content_title: '',
              title: content.title,
              views: content.views,
              linkPlay: content && content.link_play,
              drmSession: content.drm_session_info ? {
                ...content.drm_session_info,
                ...content.cwl_info
              } : null,
              playInfo: content?.play_info?.data,
              content_type: content?.type,
              cwl_info: content.cwl_info,
              titleProgram: content?.title,
              cp: content?.provider_slug,
              cpName: content?.metadata?.provider?.name
            }
            dispatch(setPlayerData(dataContent))
            dispatch(setContent(content))
            dispatch(setRibbonLive(selectedContents))
            history.push('/playerlive')
          } 
        } else {
          dispatch(setIsShowSubscription(true))
        }
      },
      onError: (error) => {
        const data = error?.response?.data
        dispatch(setIsLoadingOverlay(false))
        isNavigate.current = false
        if (error?.response?.status >= 400  && data?.error_code === FORCE_LOGOUT_ERROR_CODE) return 
        dispatch(setMessageSubscription('Không thể tải được nội dung, quý khách vui lòng thử lại sau.'))
        dispatch(setIsShowSubscription(true))
      }
    }
  )

  const handleClick = (selectedContent, isBadges = null) => {
    currentData.current = selectedContent
    if (CONTENT_TYPE_FOR_VOD.includes(selectedContent.type)) {
      dispatch(setRibbonContent(selectedContent))
      history.push('/content')
    } else {
      const isBadge = isBadges || checkVipBadge(selectedContent, login)
      isNavigate.current= true
      handleUpgradeForLive(selectedContent, isBadge)
    }
  }


  const handleUpgradeForLive = (selectedContent, isBadge) => {
    handleGetContentView({contentId: selectedContent.slug, isBadge, selectedContent})
  }

  useEffect(() => {
    isNavigate.current = false
  }, [currentMenu?.slug])

  useEffect(() => {
    if (!isLogin && isNavigate.current === false) return
    if (currentData.current) handleClick(currentData.current)
  }, [isLogin])

  return {
      isLoadingUpgrade: isLoadingUpgrade,
      handleClick,
      handleUpgradeForLive
    }
}

export default useFollowUpgrade
