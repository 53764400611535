import queryString from 'query-string'
import { googleAnalytics } from './utils'
import config  from './config'

export interface windowInterface {
  platform?: string
  innerWidth: number
  innerHeight: number
}

export interface windowInterface {
  platform?: string
}

export type dataPlayingContent = {
  [key: string]: any
}

interface ribbonSelected {
  [key: string]: any
}

export type dataEvent = {
  [key: string]: any,
  viewhour_seconds?: number | string | undefined,
  type: string
  episode_name:  number | string | undefined,
  program_name:  number | string | undefined,
  category: number | string | undefined,
  content_provider: number | string | undefined,
}


const membershipData = ['sctv-anonymous', 'sctv-basic']

export type dataQuery = {
  // slug : string
  [key: string]: any
}

export const pushPlayerEvent = (playingContent: any) => {
  let membership = membershipData[0]
  const localProfile = localStorage.getItem('user_profile')

  if (localProfile) {
    const userProfile = JSON.parse(localProfile)
    membership = membershipData[1]
    if (userProfile?.profile?.subscription_plan_info?.name) {
      membership = userProfile?.profile?.subscription_plan_info?.name
    }
  }

  const data = setEventData(playingContent)

  const eventData = {
    ...data,
    membership: membership,
    ui_language: 'vi'
  }
  // JSON.stringify(eventData)

  // const queryParams = queryString.stringify({ ...eventData })
  googleAnalytics(eventData)
}


export const setEventData = (datas: dataPlayingContent) => {
  const LIVE = [5,9]
  const { playingContent, ribbonSelected, type, watchingTime } = datas
  const isLiveContent = LIVE?.includes(playingContent?.content_type)

  const episodeSlug = isLiveContent ? undefined : playingContent?.currentEpisodes?.slug || playingContent?.slug
  const programSlug = playingContent?.slug
  let eventData: dataEvent = {
    episode_name: episodeSlug || undefined,
    program_name: programSlug || undefined,
    content_provider: playingContent?.cpName || undefined,
    category: ribbonSelected?.slug || undefined,
    type,
  }
  if (watchingTime) {
    eventData = {
      ...eventData,
      viewhour_seconds: watchingTime
    }
  }
  return eventData
}
