// import Toast from 'bootstrap/js/dist/toast'
import SeasonModal from 'features/season/components/SeasonModal'
import VideoJS from 'components/Video'
import '../styles/player.scss'

import DebugVjs from './debugVideojs'
import TopControlBar from './topControlBar'
import Cwl from './cwl'
import { usePlayerContext } from 'contexts/playerContext'
import Spinner from 'components/Spinner'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Player = () => {
  const {
    modalSeasonRef,

    showInfo,
    active,
    isLimit,
    playerData,
    title,
    content,
    player,
    src,
    activeCwl,

    isDisplayDebugLog,
    bandWidth, 
    videoBitrateState,
    currentTimeDebug, 
    buffered, 
    videoBuffered, 
    seekable,
    bufferCount,
    currentSource,
    currentSegment,
    remoteDebugLogChannelIdRef,

    loadingLimit,
    loadingPlayer,


    handleRetry,
    handleClickBack,
    handleClickShowSeason,
    disposeVis,
    handlePlayerReady,
    handleEndVjs,

    //ss
    setEpisode,
    setSeason,

    dataSeasonById,
    isLoadingSeasonById,
    isLoadingContentSeason
  } = usePlayerContext()


  if (isLimit) {
    return (<Cwl handleRetry={handleRetry} loadingLimit={loadingLimit} activeCwl={activeCwl} />)
  }

  return (
    <div className="player">
      <div className="row">
        <div className="col col-md-12 player-col">
          {isLoadingContentSeason ?
            <Spinner classname='player-loading'/>
          : null}
          <TopControlBar
            handleClickBack={handleClickBack}
            handleClickShowSeason={handleClickShowSeason}
            showInfo={showInfo}
            active={active}
            playerData={playerData}
            title={title}
            content={content}
            player={player}
          />
          <div className="video-js-container" style={{ width: '100%', height: window.innerHeight }}>
            {/* <video ref={playerRef} className="video-js vjs-default-skin vjs-fill"></video> */}
            { loadingPlayer &&
              <VideoJS src={src} disposeVis={disposeVis} onReady={handlePlayerReady} player={player} handleEndVjs={handleEndVjs} />
            }
          </div>

          {/* Trọn bộ */}
          {(playerData && playerData.season) &&
            <SeasonModal
              ref={modalSeasonRef}
              setEpisode={setEpisode}
              setSeason={setSeason}
              dataSeasonById={dataSeasonById}
              isLoadingSeasonById={isLoadingSeasonById}
            />
          }
        </div>
      </div>
      <DebugVjs
        isDisplayDebugLog={isDisplayDebugLog}
        obj={{
          bandWidth: bandWidth, 
          videoBitrateState: videoBitrateState,
          currentTimeDebug: currentTimeDebug, 
          buffered: buffered, 
          videoBuffered: videoBuffered, 
          seekable: seekable,
          bufferCount: bufferCount,
          currentSource: currentSource,
          currentSegment: currentSegment,
          remoteDebugLogChannelIdRef: remoteDebugLogChannelIdRef
      }}
      />

    </div>
  )
}

export default Player
