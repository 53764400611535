/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState, useCallback, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCheckCircle, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { keyCode, scrollVertical, checkVipBadge, checkTierAvaiable } from 'common/utils'
import Spinner from 'components/Spinner'
import VipBadge from '../../../components/VipBadge'
import { imgholder } from '../../../constant/images'
import { setIsShowSubscription } from '../../subscription/subscriptionSlice'
import '../styles/season.scss'
import {  setMessageSubscription } from '../../subscription/subscriptionSlice'
import useFetchApiSeason from '../hooks/useFetchSeason'

const Season = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const isNavigate =useRef(null)

  const message = useSelector((state) => state.subscription.message)
  const content = useSelector((state) => state.content.content)
  const login = useSelector((state) => state.login)
  const { isVip, subTier, isLogin } = login

  const isShowSubscription = useSelector((state) => state.subscription.isShowSubscription)
  const isShowWarningLogin = useSelector((state) => state.modal.isShowWarningLogin)
  const isShowLogin = useSelector((state) => state.login.isShowLogin)

  const [isLoading, setIsLoading] = useState(false) // trang thái loading khi lấy được content
  const [seasonList, setSeasonList] = useState([])
  const [data, setData] = useState([])
  const [title, setTitle] = useState('')
  // const [view, setView] = useState(0)
  // const [favourite, setFavourite] = useState(0)
  const [year, setYear] = useState('')

  const [active, setActive] = useState({
    category: 'season',
    item: 0
  })
  const [currentSeason, setCurrentSeason] = useState(0)
  const [currentEpisode, setCurrentEpisode] = useState(0)

  const {
    setEpisode,
    setSeason,

    dataSeasonById,
    isLoadingSeasonById
  } = useFetchApiSeason({ type: 'page' })

  const eventListener = useCallback(() => {
  }, [data, active, isShowSubscription, isShowWarningLogin, isShowLogin])

  useEffect(() => {
    setIsLoading(true)
    window.onpopstate = () => {
      history.goBack()
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', handleKey)
    return () => {
      document.removeEventListener('keydown', handleKey)
    }
  }, [eventListener])

  // Gọi sau khi lấy được nội dung content
  useEffect(() => {
    if (content) {
      console.log('content',content)
      setSeasonList(content.seasons)
      setSeason(content.seasons[0])
      setTitle(content.title)
      // setView(content.views)
      // setFavourite(content.favorites)
      setYear(content.release_date ? content.release_date.substr(0, content.release_date.indexOf('-')) : '')
      setIsLoading(false)
    }
  }, [content])

  useEffect(() => {
    if (dataSeasonById) {
      setData(dataSeasonById.episodes)
    }
  }, [dataSeasonById])



  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */
  const handleKey = (e) => {
    e.preventDefault()
    if (isShowSubscription || isShowWarningLogin || isShowLogin) return

    switch (e.keyCode) {
      case keyCode.RETURN: {
        if (!isShowSubscription) {
          history.goBack()
        }
        break
      }
      case keyCode.EXIT: {
        if (!isShowSubscription) {
          history.goBack()
        }
        break
      }
      case keyCode.ENTER: {
        if (!isShowSubscription) {
          if (active.category === 'back') {
            history.goBack()
          }
          if (active.category === 'episode') {
            handleClickEpisode(active.item)
          }
        }
        break
      }
      // Từ season --> nút Back
      // Navgiate season
      // Navigate episode
      case keyCode.UP: {
        if (!isShowSubscription) {
          if (active.category === 'season') {
            if (active.item === 0) {
              setActive({
                category: 'back',
                item: 0
              })
              setCurrentSeason(0)
            } else {
              scrollVertical('container-episode-title', `episode-title-${active.item - 1}`, 'UP')
              setActive({
                category: 'season',
                item: active.item - 1
              })
              setCurrentSeason(active.item - 1)
              setSeason(seasonList[active.item - 1])
            }
          }
          if (active.category === 'episode') {
            if (active.item === 0) {
              setCurrentEpisode(0)
            } else {
              scrollVertical('container-episode', `episode-${active.item - 1}`, 'UP')
              setActive({
                category: 'episode',
                item: active.item - 1
              })
              setCurrentEpisode(active.item - 1)
            }
          }
        }
        break
      }
      // Từ nút Back --> season
      // Navigate season
      // Navigate episode
      case keyCode.DOWN: {
        if (!isShowSubscription) {
          if (active.category === 'back') {
            setActive({
              category: 'season',
              item: 0
            })
            setCurrentSeason(0)
            setCurrentEpisode(0)
          }
          if (active.category === 'season') {
            if (active.item < (seasonList.length - 1)) {
              scrollVertical('container-episode-title', `episode-title-${active.item + 1}`, 'DOWN')
              setActive({
                category: 'season',
                item: active.item + 1
              })
              setCurrentSeason(active.item + 1)
              setSeason(seasonList[active.item + 1])
            }
          }
          if (active.category === 'episode') {
            if (active.item < (data.length - 1)) {
              scrollVertical('container-episode', `episode-${active.item + 1}`, 'DOWN')
              setActive({
                category: 'episode',
                item: active.item + 1
              })
              setCurrentEpisode(active.item + 1)
            }
          }
        }
        break
      }
      // Từ episode --> season
      case keyCode.LEFT: {
        if (!isShowSubscription) {
          if (active.category === 'episode') {
            setActive({
              category: 'season',
              item: currentSeason
            })
          }
        }
        break
      }
      // Từ season --> episode
      case keyCode.RIGHT: {
        if (!isShowSubscription) {
          if (active.category === 'season') {
            setActive({
              category: 'episode',
              item: currentEpisode
            })
          }
        }
        break
      }
    }
  }

  const handleClickBack = () => {
    history.goBack()
  }

  const handleClickSeason = (e, index) => {
    setActive({
      category: 'season',
      item: index
    })
    setSeason(seasonList[index])
  }

  const displayVIPBadge = useCallback((item) => {
    const isBadge = checkVipBadge(item, login)
    if (isBadge) {
      return (
        <VipBadge />
      )
    }
    return null
  }, [content])

  const handleClickEpisode = (index) => {
    const episodeData = data[index]
    const isCanplay = checkTierAvaiable(subTier, episodeData?.min_sub_tier, isVip)
    const isPlay = isCanplay || episodeData?.can_preview || episodeData?.has_free_content
    isNavigate.current = {
      upgrade: true,
      idx: index
    }

    if (!isPlay && !message) dispatch(setMessageSubscription(content.warning_message))

    // if (!isLogin) return dispatch(setIsShowWarningLogin(true))
    if (isPlay) {
      return setEpisode(episodeData)
    } 
    dispatch(setIsShowSubscription(true))
  }

  useEffect(() => {
    const currentData = isNavigate.current
    if (currentData?.upgrade && isLogin) {
      setIsLoading(true)
      handleClickEpisode(currentData?.idx)
    }
  }, [isLogin])


  if (isLoading) {
    return (
      <Spinner />
    )
  }

  return (
    <div className="container-fluid season-background">
      {!isLoading &&
        <div
          id="season"
          className="season"
        >
          <FontAwesomeIcon
            className={`season-back-icon ${active.category === 'back' ? 'active' : ''}`}
            icon={faChevronLeft}
            onClick={handleClickBack}
          />

          <div className="row col col-md-12 col-lg-12 ps-2 pe-2 season-container">
            <div className="col col-md-1 col-lg-1"></div>
            <div className="col col-md-4 col-lg-4 season-row">
              <div className="season-title">
                {title}
              </div>
              <div className="season-year">{year}</div>
              <div className="season-description">{content?.short_description}</div>

              <div id="container-episode-title" className="col col-md-7 col-lg-7 container-episode-title">
                {seasonList && seasonList.map((item, index) => {
                  return (
                    <div
                      key={index}
                      id={`episode-title-${index}`}
                      className={`season-item ${(active.category === 'season' && active.item === index) ? 'active' : ''}`}
                      onClick={e => handleClickSeason(e, index)}
                    >
                      {dataSeasonById?.id === item?.id
                        ? (<FontAwesomeIcon style={{ marginRight: 10 }} className="content-button-shadow" icon={faCheckCircle} />)
                        : null}
                      <span>{item.title}</span>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="col col-md-7 col-lg-7">
              {isLoadingSeasonById &&
                <Spinner />
              }

              {!isLoadingSeasonById &&
                <div id="container-episode" className="col col-md-12 col-lg-12 ps-2 pe-2 container-episode">
                  {data && data.map((item, index) => {
                    let thumbnail = item.images ? (item.images.thumbnail && item.images.thumbnail) !== '' ? item.images.thumbnail : '' : ''
                    // if (thumbnail) {
                    //   thumbnail = thumbnail.replace('https', 'http')
                    // }

                    return (
                      <div
                        key={index}
                        id={`episode-${index}`}
                        className={`row episode ${(active.category === 'episode' && active.item === index) ? 'active' : ''}`}
                        onClick={() => handleClickEpisode(index)}
                      >
                        <div className="col col-md-5 col-lg-5 ps-2 pe-2 season-item-wrapper">
                          <figure className="figure episode-item">
                            <img
                              className='figure-img img-fluid rounded'
                              // eslint-disable-next-line no-unneeded-ternary
                              src={thumbnail ? item?.images?.thumbnail : imgholder }
                              onError={(e) => {
                                e.target.onerror = null
                                e.target.src = imgholder
                              }}
                            />
                          </figure>

                          {displayVIPBadge(item)}
                        </div>
                        <div className="col col-md-7 col-lg-7 ps-2 pe-2 episode-info">
                          <p className="episode-title">{`Tập ${item.episode}`}</p>
                        </div>
                      </div>
                    )
                  })}
                </div>
              }
            </div>
          </div>
        </div>
      }

      <div className="season-back-instruction">
        Bấm &nbsp;<FontAwesomeIcon className="content-button-shadow" icon={faArrowLeft} /> Back &nbsp;trên điều khiển để trở về
      </div>

    </div>
  )
}

export default Season
