import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  searchText: '',
}

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchTextReducer (state, action) {
      state.searchText = action.payload
    },

  }
})

export const {
  setSearchTextReducer
} = searchSlice.actions
export default searchSlice.reducer
