import { useEffect, useMemo, useRef } from 'react'
import { keyCode } from 'common/utils'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { debounce } from 'lodash'
import videojs from 'video.js'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

const useKeyBoardPlayer = ({
  player,
  isBlockKeyboard,
  isLimit,
  isShowSeason,
  showInfo,
  active,
  setIsShowSeason,
  playerData,
  status,

  eventListener,
  handleKeyLimit,
  returnExitEvent,
  setShowInfo,
  setStatus,
  setActive,
  handleNextEps,
  handlePrevEps,
  handleLogProgress,

  isNextContent,
  isPrevContent,
  isLoadingContentSeason,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  // seek
  const numberSeekRef = useRef(0)
  const currentTimeSeek = useRef(0)
  const keyDownPressEnterTizen = useRef(0)
  const lastOnPressDown = useRef({
    lastTime: null
  })

  const seekToEnd = useRef(0)
  const debounceFuncRef = useRef(null)
  const currentFocus  = useRef(1)
  const currentPlayIndex  = useRef(1)
  const currentSeekEnter  = useRef(false)

  const topButton = ['back', 'season']
  const bottomButton = ['seekPrev', 'playPause', 'seekNext']
  
  const ARRAY_PART = useMemo(() => {
    let tmp = bottomButton
    if (isPrevContent >= 0 && isPrevContent !== false) {
      tmp = ['prevEpisode', ...bottomButton]
    }
    if (isNextContent) {
      tmp = [...tmp, 'nextEpisode']
    }
    return tmp
  }, [bottomButton, isNextContent, isPrevContent])


  useEffect(() => {
    const $btnNext = document.getElementsByClassName('vjs-next-eps')
    const $btnPrev = document.getElementsByClassName('vjs-prev-eps')
    const $btnSeekPrev = document.getElementsByClassName('vjs-seek-prev ')


    if (!$btnNext?.length || !$btnPrev.length) return
    if (ARRAY_PART[0] !== 'prevEpisode') {
      $btnPrev[0].classList.add('vjs-hidden')
      $btnSeekPrev[0].classList.add('first')
      currentPlayIndex.current = 1
    } else {
      $btnSeekPrev[0].classList.remove('first')
      $btnPrev[0].classList.remove('vjs-hidden')
      currentPlayIndex.current = 2
    }
    if (ARRAY_PART[ARRAY_PART.length - 1] !== 'nextEpisode') {
      $btnNext[0].classList.add('vjs-hidden')
    } else {
      $btnNext[0].classList.remove('vjs-hidden')
    }
    currentFocus.current = currentPlayIndex.current
    setActiveButton(ARRAY_PART[currentFocus.current])
  }, [ARRAY_PART.length, isPrevContent, isNextContent, player])

  
  useEffect(() => {
    document.addEventListener('keydown', handleKey)
    document.addEventListener('keyup', handleKeyUp)
    return () => {
      document.removeEventListener('keydown', handleKey)
      document.removeEventListener('keyup', handleKeyUp)
    }
  }, [eventListener, ARRAY_PART.length])


  useEffect(() => {
    return () => {
      clearIntervalKeyPressTizen()
    }
  }, [])

  const setActiveButton = (value) => {
    if (value === 'playPause' || topButton.includes(value)) {
      setActive(value)
    }
    handleActive(value)
    // setAttr()
    debouceFunc()
  }

  const debouceFunc = () => {
    debounceFuncRef.current?.cancel()
    debounceFuncRef.current = debounce(function () {
      setShowInfo(false)
    }, 5000)
    debounceFuncRef.current()
  }

  const handleActive = (value) => {
    const $btnPlay = document.getElementsByClassName('vjs-play-control')
    const $btnNext = document.getElementsByClassName('vjs-next-eps')
    const $btnPrev = document.getElementsByClassName('vjs-prev-eps')
    const $btnSeekNext = document.getElementsByClassName('vjs-seek-next')
    const $btnSeekPrev = document.getElementsByClassName('vjs-seek-prev')
    switch (value) {
      case 'nextEpisode': {
        if (!$btnNext.length) return
        $btnSeekNext[0].classList.remove('active')
        $btnNext[0].classList.add('active')
        break
      }
      case 'seekNext': {
        if (!$btnNext.length) return
        $btnNext[0].classList.remove('active')
        $btnPlay[0].classList.remove('active')
        $btnSeekNext[0].classList.add('active')
        break
      }
      case 'playPause': {
        if (!$btnPlay.length) return
        $btnNext[0].classList.remove('active')
        $btnPrev[0].classList.remove('active')
        $btnSeekPrev[0].classList.remove('active')
        $btnSeekNext[0].classList.remove('active')
        $btnPlay[0].classList.add('active')
        break
      }
      case 'seekPrev': {
        if (!$btnNext.length) return
        $btnPlay[0].classList.remove('active')
        $btnPrev[0].classList.remove('active')
        $btnSeekPrev[0].classList.add('active')
        break
      }
      case 'prevEpisode': {
        if (!$btnPrev.length) return
        $btnSeekPrev[0].classList.remove('active')
        $btnPrev[0].classList.add('active')
        break
      }
      default: {
        if (!$btnPlay.length) return

        $btnNext[0].classList.remove('active')
        $btnPrev[0].classList.remove('active')
        $btnPlay[0].classList.remove('active')
        $btnSeekPrev[0].classList.remove('active')
        $btnSeekNext[0].classList.remove('active')
      }
    }
  }
  

  const setAttr = () => {
    const $btnPlay = document.getElementsByClassName('vjs-play-control')
    if (!$btnPlay.length) return
    
    const title = $btnPlay[0].getAttribute('title')

    const att = document.createAttribute("data-content")
    att.value = title === 'Play' ? 'Phát' : 'Tạm dừng'
    $btnPlay[0].setAttributeNode(att);
  }

  const handleFocusBottom = () => {
    const number = currentFocus.current 
    setActiveButton(ARRAY_PART[number])
  }

  const detectSpeedKeyPress = (time = 100) => {
    const nowTime = new Date().getTime()
    if (lastOnPressDown?.current) {
      const onPressTime = nowTime - lastOnPressDown?.current?.lastTime
      if (onPressTime >= time) {
        lastOnPressDown.current = {
          lastTime: nowTime
        }
        return true
      }
      return false
    }
    lastOnPressDown.current = {
      lastTime: nowTime
    }
    return true
  }


  const handleSeek = (key = 'prev', type = 'click') => {
    currentTimeSeek.current = currentTimeSeek.current || player.currentTime()
    if (detectSpeedKeyPress(numberSeekRef.current * 200) && numberSeekRef.current < 5) {
      debounceFuncRef.current?.cancel()
      numberSeekRef.current = numberSeekRef.current + 1
    }
    if (type === 'click') {
      detectSeek(type, key)
      player.currentTime(currentTimeSeek.current)
      currentTimeSeek.current = 0
      handleLogProgress()
      return 
    }
    if (type === 'auto') {
      currentSeekEnter.current = true
      detectSeek(type, key)
      player.pause()
      const number = parseInt(currentTimeSeek.current)
      document.getElementsByClassName('vjs-current-time-display')[0].innerHTML = videojs.formatTime(number.toFixed())
      return
    }
  }

  const handleCancelSeek = () => {
    debounceFuncRef.current()
    if (numberSeekRef.current >= 1 && currentTimeSeek.current > 0 || currentSeekEnter.current) {
      const $timeEl = document.getElementsByClassName('vjs-current-time-display')
      if ($timeEl.length) {
        $timeEl[0].innerHTML = ''
      }
      player.play()
      player.currentTime(currentTimeSeek.current)
      handleLogProgress()
    }
    numberSeekRef.current = 0
    currentTimeSeek.current = 0
    currentSeekEnter.current = false
  }

  const detectSeek = (type = 'click', key) => {
    const duration = player.duration()
    const numberSeek = type === 'click' ? 60 : 10
    const value = key === 'prev' ? -numberSeek : numberSeek 
    
    if (type === 'click') {
      currentTimeSeek.current = currentTimeSeek.current + value
      if (currentTimeSeek.current >= duration - 5) {
        currentTimeSeek.current = duration - 5
      }
      if (currentTimeSeek.current <= 1) {
        currentTimeSeek.current = 1
      }
      return
    }
    if (currentTimeSeek.current >= duration -5 && key === 'next') {
      currentTimeSeek.current =  duration - 5
    } else {
      currentTimeSeek.current = currentTimeSeek.current + value * (numberSeekRef.current + 1)
    }
    if(currentTimeSeek.current <= 1) currentTimeSeek.current = 1
  }
  const handelEnterFocusBottom = () => {
    const number = currentFocus.current 
    switch (ARRAY_PART[number]) {
      case 'nextEpisode': {
        handleNextEps()
        break
      }
      case 'seekNext': {
        if (!keyDownPressEnterTizen.current && window.platform === "tv_tizen") {
          clearIntervalKeyPressTizen()
          handleSeek('next', 'click')
          keyDownPressEnterTizen.current = setInterval(() => {
            handleSeek('next', 'auto')
          }, 200);
          return
        }
        handleSeek('next', numberSeekRef.current > 1 ? 'auto': 'click')
        break
      }
      case 'playPause': {
        if (status === 'play') {
          player?.pause()
          setStatus('pause')
          return
        }
        if(status === 'pause') {
          player?.play()
          setStatus('play')
          return
        }
        break
      }
      case 'seekPrev': {
        if (!keyDownPressEnterTizen.current && window.platform === "tv_tizen") {
          clearIntervalKeyPressTizen()
          handleSeek('prev','click')
          keyDownPressEnterTizen.current = setInterval(() => {
            handleSeek('prev', 'auto')
          }, 200);
          return
        }
        handleSeek('prev', numberSeekRef.current > 1 ? 'auto': 'click')
        break
      }
      case 'prevEpisode': {
        handlePrevEps()
        break
      }
    }
  }

  const clearIntervalKeyPressTizen = () => {
    clearInterval(keyDownPressEnterTizen.current)
    keyDownPressEnterTizen.current = null
  }

  const handleKeyUp = (e) => { 
    let isAdsPlaying = false
    clearIntervalKeyPressTizen()

    if (player?.ads?.inAdBreak) {
      isAdsPlaying = player?.ads?.inAdBreak() === true
    }
    if(isAdsPlaying || isLoadingContentSeason) return
    handleCancelSeek()
  }

  const handleKey = (e) => {
    e.preventDefault()
    let isAdsPlaying = false
    if (player?.ads?.inAdBreak) {
      isAdsPlaying = player?.ads?.inAdBreak() === true
    }
    if (isBlockKeyboard) return
    if (isLimit) {
      return handleKeyLimit(e)
    }

    switch (e.keyCode) {
      case keyCode.RETURN: {
        returnExitEvent()
        break
      }
      case keyCode.EXIT: {
        returnExitEvent()
        break
      }
      case keyCode.ENTER: {
        if(isAdsPlaying || isLoadingContentSeason) return
        if (!isShowSeason) {
          if (!showInfo) {
            setShowInfo(true)
          } else {
            if (active === 'back') {
              history.goBack()
            }
            debouceFunc()
            if (active === 'playPause') {
              handelEnterFocusBottom()

            }
            if (active === 'season') {
              setActiveButton('season')
              dispatch(setIsShowSeason(true))
            }
          }
        }
        break
      }
      // Hiển thị thông tin khi đang ẩn
      // Từ nút player --> nút Back
      case keyCode.UP: {
        if(isAdsPlaying || isLoadingContentSeason) return

        if (!isShowSeason) {
          if (!showInfo) {
            setShowInfo(true)
          } else {
            if (active === 'playPause') {
              if (playerData && playerData.season) {
                setActiveButton('season')
              } else {
                setActiveButton('back')
              }
            }
          }
        }
        break
      }
      // Hiển thị thông tin khi đang ẩn
      // Từ nút Back --> nút player
      case keyCode.DOWN: {
        if(isAdsPlaying || isLoadingContentSeason) return

        if (!isShowSeason) {
          if (!showInfo) {
            setShowInfo(true)
          } else {
            if (active === 'back' || active === 'season') {
              currentFocus.current = currentPlayIndex.current
              handleFocusBottom()
            }
          }
        }
        break
      }
        
      case keyCode.LEFT: {
        if (active === 'back') {
        seekToEnd.current += 1
          if (seekToEnd.current === 5) {
            player.currentTime(player.duration() - 60)
            seekToEnd.current = 0
          }
        }
        if(isAdsPlaying || isLoadingContentSeason) return

        if (!isShowSeason) {
          if (!showInfo) {
            setShowInfo(true)
          } else {
            if (active === 'season') {
              setActiveButton('back')
              return
            }
            if (active === 'back') return
            if(currentFocus.current === 0) return
            currentFocus.current = currentFocus.current - 1
            handleFocusBottom()
          }
        }
        break
      }
      case keyCode.RIGHT: {
        if (isAdsPlaying || isLoadingContentSeason) return
        if (!isShowSeason) {
          if (!showInfo) {
            setShowInfo(true)
          } else {
            if (active === 'back' && playerData && playerData.season) {
              setActiveButton('season')
              return
            }

            if (active === 'season') return

            if(currentFocus.current === ARRAY_PART.length -1) return
            currentFocus.current = currentFocus.current + 1
            handleFocusBottom()
          }
        }
        break
      }
      case keyCode.MEDIA_PLAY_PAUSE:
      case keyCode.MEDIA_PAUSE:
      case keyCode.MEDIA_PLAY: {
        if(isAdsPlaying || isLoadingContentSeason) return

        if (isLimit) return
        if (player) {
          if (!isShowSeason) {
            if (status === 'play') {
              player?.pause()
              setStatus('pause')
            } else {
              player?.play()
              setStatus('play')
            }
          }
        }
        break
      }
      case keyCode.MEDIA_REWIND: {
        if(isAdsPlaying || isLoadingContentSeason) return

        if (isLimit) return
        if (!isShowSeason && player) {
          player.currentTime(player.currentTime() - 60)
        }
        break
      }
      case keyCode.MEDIA_FAST_FORWARD: {
        if(isAdsPlaying || isLoadingContentSeason) return

        if (isLimit) return
        if (!isShowSeason && player) {
          player.currentTime(player.currentTime() + 60)
        }
        break
      }
    }
  }


  useEffect(() => {
    setActiveButton('playPause')
  }, [])

  useEffect(() => {
    if(!player) return
    player?.on('playing', () => {
      setAttr()
    })
    player?.on('pause', () => {
      setAttr()
    })
  }, [player])

  useEffect(() => {
    if (status === 'play') {
      debounceFuncRef.current?.cancel()
    }
  }, [status])

  useEffect(() => {
    const $controlbarEl = document.getElementsByClassName('vjs-control-bar')
    if (!$controlbarEl?.length) return
    $controlbarEl[0].classList.remove(!showInfo ? 'visibility-visible' : 'visibility-hidden')
    $controlbarEl[0].classList.add(showInfo ? 'visibility-visible' : 'visibility-hidden')
  }, [showInfo])
}
export default useKeyBoardPlayer

