import axios from 'axios'
import consolere from 'console-remote-client'
import config from './config'
import { getUserAgent } from './userAgent'
import { createAxios } from './axiosCreate'
import { omit } from 'lodash'
import {
  TIER_BASIC,
  TIER_SAME,
  TIER_LEVEL
} from 'constant/profile'

let verisonOs = ''
let modelOs = ''

const blackListDisableCache = []

const keyCodePlatform = window.platform === 'web'
  ? {
      RETURN: 8,
      ENTER: 13,
      EXIT: 27,
      LEFT: 37,
      RIGHT: 39,
      UP: 38,
      DOWN: 40
    }
  : window.platform === 'tv_webos' // LG (webOS)
    ? {
        RETURN: 461,
        ENTER: 13,
        EXIT: 27,
        LEFT: 37,
        RIGHT: 39,
        UP: 38,
        DOWN: 40
      }
    : { // Samsung (Tizen)
        RETURN: 10009,
        ENTER: 13,
        EXIT: 27,
        LEFT: 37,
        RIGHT: 39,
        UP: 38,
        DOWN: 40
      }

export const keyCode = {
  LEFT: keyCodePlatform.LEFT,
  UP: keyCodePlatform.UP,
  RIGHT: keyCodePlatform.RIGHT,
  DOWN: keyCodePlatform.DOWN,
  RETURN: keyCodePlatform.RETURN,
  ENTER: keyCodePlatform.ENTER,
  EXIT: keyCodePlatform.EXIT
}

let xPreviewMode = false

/**
 * Format lại dữ liệu
 * Dùng trong trường hợp có danh sách ở nhiều dòng
 * @param {array} data Mảng dữ liệu
 * @param {number} chunk Số item trên 1 dòng
 */
export const formatDataChunk = (data, chunk) => {
  if (data.items) {
    let i
    let j
    const tempArr = []
    for (i = 0, j = data.items.length; i < j; i += chunk) {
      const sliceArr = data.items.slice(i, i + chunk)
      tempArr.push(sliceArr)
    }

    return tempArr
  } else {
    return []
  }
}


export const formatDate = (value, type) => {
  if (value) {
    const date = new Date(value)
    const day = date.getDate() < 10 ? ('0' + date.getDate()) : date.getDate()
    const month = (date.getMonth() + 1) < 10 ? ('0' + (date.getMonth() + 1)) : date.getMonth() + 1
    const year = date.getFullYear()

    if (type === 'dd/mm/yyyy') {
      return `ngày ${day} tháng ${month} năm ${year}`
    } else {
      return year + '-' + month + '-' + day
    }
  } else {
    return ''
  }
}

export const formatTime = (value, type) => {
  if (value) {
    const date = new Date(value * 1000)
    const hour = date.getHours() < 10 ? ('0' + date.getHours()) : date.getHours()
    const minute = date.getMinutes() < 10 ? ('0' + date.getMinutes()) : date.getMinutes()

    if (type === 'hh:mm') {
      return hour + ':' + minute
    } else {
      return hour + ':' + minute
    }
  } else {
    return ''
  }
}

export const formatThousand = (value) => {
  if (value) {
    if (typeof value === 'string') {
      console.log(1)
    } else {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    }
  } else {
    return '0'
  }
}

/**
 * Gọi api, lấy dữ liệu
 */

export const getHeader = () => {
  let auThor = {}
  if (localStorage.getItem('user_profile')) {
    const userProfile = JSON.parse(localStorage.getItem('user_profile'))
    auThor = {
      Authorization: userProfile.access_token,
    }
  }

  const header = {
    'accept-language': 'vi',
    'x-enable-content-preview': xPreviewMode,
    'X-User-Agent': getUserAgent(),
    ...auThor
  }
  return header
}

const callApi = (method, endpoint, data) => {
  const fetchApi = createAxios()

  return fetchApi({
    method: method.toLowerCase(),
    url: `/backend/cm/${endpoint}`,
    data: data,
  })
}

export const callApiRoot = (method, endpoint, data) => {
  const isNoCache = false
  const fetchApi = createAxios(isNoCache)

  return fetchApi({
    method: method.toLowerCase(),
    url: `${endpoint}`,
    data: data,
  })
}

const callApiTenant = (method, endpoint, data) => {
  // eslint-disable-next-line max-len
  // const isNoCache = blackListDisableCache.find((element) => endpoint.includes(element)) ? true : false
  const isNoCache = false
  const fetchApi = createAxios(isNoCache)

  return fetchApi({
    method: method.toLowerCase(),
    url: `/tenants/${config.tenantSlug}/${endpoint}`,
    data: data,
  })
}

export const getData = (endpoint, dataSend, method) => {
  return new Promise(async (resolve, reject) => { /* eslint-disable-line */
    try {
      const result = await callApi(endpoint, dataSend, method)
      const { data } = result
      return data ? resolve(data) : resolve(null)
    } catch (e) {
      console.log(e)
      return reject(e)
    }
  })
}

const pypassLogin = (userId) => {
  return axios({
    method: 'POST',
    url: `${config.domainRoot}/tenants/${config.tenantSlug}/accounts/${userId}/login`,
    headers: {
      Authorization: 'mnz1564p76gs*x$8g#@w9o+25c&82-&-y1ml%v^c3&a9j%i*$1'
    }
  })
}

export const hardLogin = (userId) => {
  return new Promise(async (resolve, reject) => { /* eslint-disable-line */
    try {
      const result = await pypassLogin(userId)
      const { data } = result
      return data ? resolve(data) : resolve(null)
    } catch (e) {
      return reject(e)
    }
  })
}

export const getDataTenant = (method, endpoint, dataSend) => {
  return new Promise(async (resolve, reject) => { /* eslint-disable-line */
    try {
      const result = await callApiTenant(method, endpoint, dataSend)
      const { data } = result
      return data ? resolve(data) : resolve(null)
    } catch (e) {
      return reject(e)
    }
  })
}

export const login = (endpoint, dataSend, method) => {
  return new Promise(async (resolve, reject) => { /* eslint-disable-line */
    const result = await callApiTenant(endpoint, dataSend, method)
    console.log('result', result)
    return resolve(result)
  })
}

/**
 * Scroll theo chiều dọc khi navigation
 * @param {string} parentId Id của parent
 * @param {string} childId Id của child
 * @param {string} direction Hướng navigation (UP or DOWN)
 */
export const scrollVertical = (parentId, childId, direction) => {
  const parentEl = document.getElementById(parentId)
  const childEl = document.getElementById(childId)

  if (parentEl && childEl) {
    const parentHeight = parentEl.clientHeight
    const parentOffsetTop = parentEl.offsetTop
    const parentScrollTop = parentEl.scrollTop
    const childHeight = childEl.clientHeight
    const childOffsetTop = childEl.offsetTop

    // Tính offset top để check overflow
    const offsetTop = childOffsetTop - parentOffsetTop - parentScrollTop

    // if (direction === 'UP') {
    //   // Chỉ scroll khi overflow
    //   if (offsetTop < parentHeight) {
    //     parentEl.scrollTop = childOffsetTop - parentOffsetTop - 40
    //   }
    // } else if (direction === 'DOWN') {
    //   const childOffsetBottom = offsetTop + childHeight

    //   // Chỉ scroll khi overflow
    //   if (childOffsetBottom > parentHeight) {
    //     parentEl.scrollTop = childOffsetTop - parentOffsetTop - 40
    //   }
    // }

    parentEl.scrollTop = childOffsetTop - parentOffsetTop - 40
  }
}

/**
 * Scroll theo chiều ngang khi navigation
 * @param {string} parentId Id của parent
 * @param {string} childId Id của child
 * @param {string} direction Hướng navigation (LEFT or RIGHT)
 */
export const scrollHorizontal = (parentId, childId, direction) => {
  const parentEl = document.getElementById(parentId)
  const childEl = document.getElementById(childId)

  if (parentEl && childEl) {
    const parentWidth = parentEl.clientWidth
    const parentOffsetLeft = parentEl.offsetLeft
    const parentScrollLeft = parentEl.scrollLeft
    const childWidth = childEl.clientWidth
    const childOffsetLeft = childEl.offsetLeft

    // Tính offset left để check overflow
    const offsetLeft = childOffsetLeft - parentOffsetLeft - parentScrollLeft

    // if (direction === 'LEFT') {
    //   // Chỉ scroll khi overflow
    //   if (offsetLeft < parentWidth) {
    //     parentEl.scrollLeft = childOffsetLeft - parentOffsetLeft
    //   }
    // } else if (direction === 'RIGHT') {
    //   const childOffsetRight = offsetLeft + childWidth

    //   // Chỉ scroll khi overflow
    //   if (childOffsetRight > parentWidth) {
    //     parentEl.scrollLeft = childOffsetLeft - parentOffsetLeft
    //   }
    // }
    parentEl.scrollLeft = childOffsetLeft - parentOffsetLeft
  }
}

export const activeDown = (data, category, item) => {
  return data[category + 1] && data[category + 1][item]
    ? item
    : (data[category + 1]).length - 1
}

export const renderPeople = (peoples, role) => {
  if (peoples && peoples.length > 0) {
    const temp = []
    let i
    for (i = 0; i < peoples.length; i++) {
      if (peoples[i].role === role) {
        temp.push(peoples[i].name)
      }
    }

    if (temp.length > 0) {
      return temp.join(', ')
    }

    return 'Đang cập nhật'
  }

  return 'Đang cập nhật'
}

export const findCurrentMenuIndex = (menus, currentMenu) => {
  let currentMenuIndex = -1
  if (menus && menus.length > 0) {
    let i
    for (i = 0; i < menus.length; i++) {
      if (menus[i].slug === currentMenu.slug) {
        currentMenuIndex = i
      }
    }
  }

  return currentMenuIndex
}

export const enabledPreview = () => {
  xPreviewMode = true
  localStorage.setItem('xPreviewMode', 'true')
}

export const getModal = () => {
  let modelName = ''
  if (modelOs) return modelOs?.toString()

  if (window?.tizen?.systeminfo) {
    modelName = window?.tizen?.systeminfo.getCapability('http://tizen.org/system/model_name')
  }

  if (window.platform === 'tv_webos') {
    modelName = window?.device?.modelName || ''
  }

  modelOs = modelName
  return modelName
}

export const getVerisonOs = () => {
  let osVer = ''
  if (verisonOs) return verisonOs?.toString()
  if (window?.tizen?.systeminfo) {
    osVer = window?.tizen?.systeminfo.getCapability('http://tizen.org/feature/platform.version')
  }

  if (window.platform === 'tv_webos') {
    osVer = window.OSVersion || ''
  }

  verisonOs = osVer
  return osVer
}

export const getDeviceInfo = () => {
  const device = window?.platform === 'web'
    ? 'web'
    : window.platform === 'tv_webos'
      ? 'LG'
      : 'SAMSUNG' || window.navigator.platform

  const osVer = getVerisonOs()
  const deviceType = 'tv'
  const modelName = getModal()

  return {
    device,
    osVer,
    deviceType,
    modelName
  }
}

const unique = () => {
  let text = ''
  const randstring = '0123456789'

  for (let i = 0; i < 5; i++) {
    text += randstring.charAt(Math.floor(Math.random() * randstring.length))
  }

  return text
}

export const generateRemoteDebugChannelID = () => {
  const tt = new Date().getTime().toString().slice(0, 4)
  const uni = unique().toString()
  return tt + uni
}

export const addRemoteLogging = (remoteDebugChannelIDValue) => {
  try {
    consolere.connect({
      server: 'https://js-console.vimai.io/',
      channel: remoteDebugChannelIDValue,
      redirectDefaultConsoleToRemote: true,
      disableDefaultConsoleOutput: false
    })
  } catch (e) {
    console.log('addRemoteLogging error', e)
  }
}

export const removeRemoteLogging = () => {
  try {
    consolere.connect({
      server: 'https://js-console.vimai.io/',
      channel: '',
      redirectDefaultConsoleToRemote: true,
      disableDefaultConsoleOutput: false
    })
  } catch (e) {
    console.log('removeRemoteLogging error', e)
  }
}

/* global webapis */
/* global webOS */
export const getMacAddress = () => {
  try {
    let macAd = ''
    if (window.platform !== 'web') {
      if (window.platform === 'tv_tizen') {
        macAd = webapis.network.getMac()
        let remoteDebugLogChannelID = localStorage.getItem('remoteDebugLogChannelID')
        if (!remoteDebugLogChannelID) {
          remoteDebugLogChannelID = macAd
          localStorage.setItem('remoteDebugLogChannelID', remoteDebugLogChannelID)
        }
      } else {
        webOS.service.request('luna://com.webos.service.connectionmanager', {
          method: 'getinfo',
          parameters: {},
          onSuccess: function (args) {
            macAd = args?.wiredInfo?.macAddress
            let remoteDebugLogChannelID = localStorage.getItem('remoteDebugLogChannelID')
            if (!remoteDebugLogChannelID) {
              remoteDebugLogChannelID = macAd
              localStorage.setItem('remoteDebugLogChannelID', remoteDebugLogChannelID)
            }
          },
          onFailure: function (args) {
            macAd = generateRemoteDebugChannelID()
            let remoteDebugLogChannelID = localStorage.getItem('remoteDebugLogChannelID')
            if (!remoteDebugLogChannelID) {
              remoteDebugLogChannelID = macAd
              localStorage.setItem('remoteDebugLogChannelID', remoteDebugLogChannelID)
            }
          }
        })
      }
    }
  } catch (e) {
    console.log('getMacAddress error', e)
  }
}

export const checkVipBadge = (data, login) => {
  const { isVip, subTier, isLogin } = login
  const minSubTier = data?.min_sub_tier
  let isBadge = false

  const isFreeContent = (data?.has_free_content !== null && data?.has_free_content) ? data?.has_free_content : data?.can_preview || false

  if (isFreeContent === false && data?.is_premium === true) {
    isBadge = true
  }

  if (isFreeContent) {
    isBadge = false
  }

  if (isLogin && subTier?.support_type_for_android_tv_box !== TIER_BASIC && isFreeContent === false) {
    isBadge = !checkTierAvaiable(subTier, minSubTier, isVip)
  }
  return isBadge
}

export const checkTierAvaiable = (subTierAcc, minSubTier, isVip) => {
  let isAvaiable = false

  switch (subTierAcc?.support_type_for_android_tv_box) {
    case TIER_BASIC:
      isAvaiable = false
      break;
    case TIER_SAME:
      if (subTierAcc?.tier === minSubTier?.tier) isAvaiable = true
      break;
    case TIER_LEVEL:
      if ((subTierAcc?.tier <= minSubTier?.tier || minSubTier === null || subTierAcc === null) && isVip) isAvaiable= true
      break;
    default:
      isAvaiable = false
      break;
  }
  return isAvaiable
}

export const googleAnalytics = (data) => {
  const ga4Config = {
    measurement_id:
      window.platform === 'tv_webos'
        ? config.ga4WebOSMeasurementId
        : config.ga4TizenMeasurementId,
    api_secret:
      window.platform === 'tv_webos'
        ? config.ga4WebOSApiSecret
        : config.ga4TizenApiSecret
  }

  const advertisingId = localStorage.getItem('advertisingId')
  const UA = navigator.userAgent

  const isDebug = false // Enable debug, with data on realtime board
  const transpotURL = isDebug
    ? 'https://www.google-analytics.com/debug/mp/collect'
    : 'https://www.google-analytics.com/mp/collect'
  const pathURL = `${transpotURL}?measurement_id=${ga4Config?.measurement_id}&api_secret=${ga4Config?.api_secret}`

  const dataPost = {
    client_id: advertisingId,
    events: [{
    name: data.type,
      params: {
        ...(omit(data, ['type']))
      }
    }]
  }
  
  return axios({
    method: 'POST',
    url: pathURL,
    headers: {
      'User-Agent': UA
    },
    data: { ...dataPost }
  })
}

export const handleEventForceLogout = (e = {}) => {
  document.dispatchEvent(new CustomEvent('force-logout', {
    detail: new Date(),
    ...e
  }))
}

export const addShakaLib = async () => {
  try {
    const bodyDoc = document.getElementsByTagName("body")[0]
    const scriptAdd = document.createElement("script")
    scriptAdd.type = "text/javascript"
    scriptAdd.src = 'https://cdnjs.cloudflare.com/ajax/libs/shaka-player/2.1.0/shaka-player.compiled.js'
    bodyDoc.appendChild(scriptAdd)
    scriptAdd.onload = () => {}
  } catch (e) {
    console.log('addVideoJSCSS error', e)
  }
}
