import videojs from 'video.js';
import './afterDialog';
import './beforeDialog';
import { LIVE_EVENT } from 'constant/content'

import moment from 'moment';
import { isDVRRewatchEnabled } from 'core/method/content';

const LiveEventBeforeDialog = videojs.getComponent('LiveEventBeforeDialog');
const LiveEventAfterDialog = videojs.getComponent('LiveEventAfterDialog');

const Plugin = videojs.getPlugin('plugin');

class LiveEvent extends Plugin {
  constructor(player, options) {
    super(player, options);
    this.options_ = options;


    this.liveEventBeforeDialog = new LiveEventBeforeDialog(player, options);
    this.liveEventAfterDialog = new LiveEventAfterDialog(player, options);

    player.addChild(this.liveEventBeforeDialog);
    player.addChild(this.liveEventAfterDialog);

    this.beforeLiveDuration = 0;
    this.endLiveDuration = 0;
    this.now = moment();

    this.startOn = null;
    this.endAt = null;
    this.timer = null;
    this.init();

    this.on(player, ['playing', 'pause', 'play'], this.updateState);
    // this.on(player, ['timeupdate'], this.handleUpdateTime);
    this.on(['rewatch'], this.handleRewatch);
  }

  init() {
    const { content = {} } = this.options_;

    const { type, start_on, duration } = content;

    if (type !== LIVE_EVENT) {
      return;
    }
    this.startOn = moment(start_on);
    this.endAt = this.startOn.clone().add(duration, 'second');
    this.beforeLiveDuration = this.getDurationToStartOn();
    this.endLiveDuration = this.getDurationToEnd();
    this.rewatch = isDVRRewatchEnabled.call(content);
    this.isOpen = false;
    this.startTimer();
  }

  startTimer() {
    this.handleLiveEventUI();
    if (this.isVOD()) {
      return;
    }

    this.timer = setInterval(() => {
      this.handleLiveEventUI()
    }, 2000);
  }

  handleRewatch() {
    this.player.currentTime(0);
    this.applyLiveUI();
  }

  handleLiveEventUI() {
    const isBefore = this.isBeforeState();
    const isAfter = this.isEndLiveState();

    if (isBefore) {
      return this.applyBeforeLiveUI();
    }

    if (!isAfter) {
      //|| this.isVOD()
      return this.applyLiveUI();
    }

    this.applyAfterLiveUI();
    this.clearTimer();
  }

  isVOD() {
    return this.isEndLiveState() && this.rewatch;
  }

  getDurationToStartOn() {
    return this.startOn.clone().diff(moment(), 'second');
  }
  getDurationToEnd() {
    return this.endAt.clone().diff(moment(), 'second');
  }

  isBeforeState() {
    return moment().isBefore(this.startOn.clone());
  }

  isEndLiveState() {
    return moment().isSameOrAfter(this.endAt.clone());
  }

  dispose() {
    super.dispose();
    this.clearTimer();
  }

  clearTimer() {
    clearInterval(this.timer)
  }

  applyBeforeLiveUI() {
    this.liveEventBeforeDialog.show();
    this.liveEventAfterDialog.hide();
    this.isOpen = true;
    this.player.pause();
    this.applyBeforeLiveEvent()
  }

  applyAfterLiveUI() {
    this.liveEventBeforeDialog.hide();
    this.liveEventAfterDialog.show();
    this.isOpen = true;
    this.player.pause();
    this.endLiveDuration = -1;
    this.applyAfterLiveEvent()
  }

  applyLiveUI() {
    this.liveEventBeforeDialog.hide();
    this.liveEventAfterDialog.hide();
    this.isOpen = false;
    this.player.play();

    this.applyLiveEvent()

    if (this.isVOD()) {
      this.removeClass();
    } else {
      this.addClass();
    }
  }

  applyBeforeLiveEvent() {
    this.player.trigger('before-live');
  }

  applyAfterLiveEvent() {
    this.player.trigger('after-live');
  }

  applyLiveEvent() {
    this.player.trigger('live-event');
  }

  updateState() {
    if (this.player.paused()) {
      return;
    }
    if (this.isOpen) {
      this.player.pause();
    }
  }

  addClass() {
    if (this.options_?.content?.type !== LIVE_EVENT) {
      return;
    }
    const { player } = this;
    const classList = ['vjs-live-event', 'vjs-liveui', 'vjs-live'];
    classList.map(cls => {
      if (player.hasClass(cls)) {
        return null;
      }
      player.addClass(cls);
    });
  }

  removeClass() {
    if (this.options_?.content?.type !== LIVE_EVENT) {
      return;
    }
    const { player } = this;
    const classList = ['vjs-live-event', 'vjs-liveui', 'vjs-live'];
    classList.map(cls => {
      if (!player.hasClass(cls)) {
        return null;
      }
      player.removeClass(cls);
    });
  }

  // handleUpdateTime() {
  //   this.addClass();
  // }
}

videojs.registerPlugin('liveEvent', LiveEvent);
