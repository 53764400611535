import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  playerData: null,
  isShowEpg: false,
  drmToken: null,
  playerDataLive: null
}

const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    setPlayerData (state, action) {
      state.playerData = action.payload
    },
    setIsShowEpg (state, action) {
      state.isShowEpg = action.payload
    },
    setTokenDrm (state, action) {
      state.drmToken = action.payload
    },
    setPlayerDataLive (state, action) {
      state.playerDataLive = action.payload
    }
  }
})

export const {
  setPlayerData,
  setIsShowEpg,
  setTokenDrm,
  setPlayerDataLive
} = playerSlice.actions
export default playerSlice.reducer
