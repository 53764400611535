import { CONTENT_RATING, AGE_RATING } from 'constant/ageContentRating'
import { LIVE_PREMIERE } from 'constant/content'

import moment from 'moment'

function getContentRatingList() {
  try {
    const {
      metadata: { content_ratings },
    } = this;
    const contentRatings = (content_ratings || []).filter(rating => rating.type === CONTENT_RATING);
    return contentRatings;
  } catch (error) {
    return [];
  }
}

function getContentLivePremiere() {
  try {
    const { badges } = this;
    if (badges.includes(LIVE_PREMIERE)) { 
      return true
    }
    return false;
  } catch (error) {
    return false;
  }
}

function getAgeContentRating() {
  const limitAge = 0;
  try {
    const {
      metadata: { content_ratings },
    } = this;
    const ageRating = (content_ratings || []).filter(
      rating => rating.type === AGE_RATING && (rating?.min_age ?? -1) >= limitAge,
    );
    return ageRating.length > 0 ? ageRating[0] : null;
  } catch (error) {
    return null;
  }
}

function isBeforeLiveEvent(nowMomentInstance = null) {
  try {
    const { start_on } = this;
    const now = nowMomentInstance || moment();
    return now.isBefore(moment(start_on));
  } catch (error) {
    return false;
  }
}

function isEndLiveEvent(nowMomentInstance = null) {
  try {
    const { start_on, duration, unpublished_time } = this;
    const now = nowMomentInstance || moment();

    return (
      now.isAfter(moment(unpublished_time)) || now.isAfter(moment(start_on).add(duration, 'second'))
    );
  } catch (error) {
    return false;
  }
}

function activeLiveEvent(nowMomentInstance = null) {
  try {
    const now = nowMomentInstance || moment();
    return !(isBeforeLiveEvent.call(this, now) || isEndLiveEvent.call(this, now));
  } catch (error) {
    return false;
  }
}

function isDVRRewatchEnabled() {
  try {
    return !!this?.license_period?.dvr_rewatch_enabled;
  } catch (error) {
    console.log('err')
  }
  return false;
}

export {
  getContentRatingList,
  getAgeContentRating,
  activeLiveEvent,
  isEndLiveEvent,
  isBeforeLiveEvent,
  isDVRRewatchEnabled,
  getContentLivePremiere
}