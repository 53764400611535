import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: true, // trạng thái loading
  openMenu: false, // trạng thái đóng/mở menu
  navigateMenu: false, // trạng thái navigate menu
  navigateRibbon: true, // trạng thái navigate ribbon
  isHomePage: true,
  ribbonSelected: null
}

const rootSlice = createSlice({
  name: 'root',
  initialState,
  reducers: {
    setLoading (state, action) {
      state.loading = action.payload
    },
    setIsHomePage (state, action) {
      state.isHomePage = action.payload
    },
    toggleOpenMenu (state) {
      state.openMenu = !state.openMenu
    },
    toggleNavigateMenu (state) {
      state.navigateMenu = !state.navigateMenu
    },
    toggleNavigateRibbon (state) {
      state.navigateRibbon = !state.navigateRibbon
    },
    setRibbonSelected (state, action) {
      state.ribbonSelected = action.payload
    }
  }
})

export const { setLoading, toggleOpenMenu, toggleNavigateMenu, toggleNavigateRibbon, setIsHomePage, setRibbonSelected } = rootSlice.actions
export default rootSlice.reducer
