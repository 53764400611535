import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  ribbonContent: null,
  ribbonViewmore: null,
  ribbonLive: null, // kênh live
  currentRibbon: { // ribbon đang chọn
    isInit: true, // trạng thái init, check để kiểm tra load app lần đầu
    tag: 'ribbon', // waching: đang xem
    category: 0,
    item: 0
  }
}

const ribbonSlice = createSlice({
  name: 'ribbon',
  initialState,
  reducers: {
    setRibbonContent (state, action) {
      state.ribbonContent = action.payload
    },
    setRibbonViewmore (state, action) {
      state.ribbonViewmore = action.payload
    },
    setRibbonLive (state, action) {
      state.ribbonLive = action.payload
    },
    setCurrentRibbon (state, action) {
      state.currentRibbon = action.payload
    }
  }
})

export const {
  setRibbonContent,
  setRibbonViewmore,
  setRibbonLive,
  setCurrentRibbon
} = ribbonSlice.actions
export default ribbonSlice.reducer
