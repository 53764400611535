import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isRewatchEpg: false,
  linkPlay: '',
  channelId: ''
}

const epgSlice = createSlice({
  name: 'epg',
  initialState,
  reducers: {
    setIsRewatchEpg (state, action) {
      state.isRewatchEpg = action.payload
    },
    setLinkPlay (state, action) {
      state.linkPlay = action.payload
    },
    setChannelId (state, action) {
      state.channelId = action.payload
    }
  }
})

export const {
  setIsRewatchEpg,
  setLinkPlay,
  setChannelId
} = epgSlice.actions
export default epgSlice.reducer
