import  { useState, useEffect, useMemo, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useQuery } from 'react-query'
import { callApiRoot, getDataTenant, scrollVertical, scrollHorizontal, checkVipBadge } from 'common/utils'
import { setLoading, toggleOpenMenu, toggleNavigateMenu, toggleNavigateRibbon, setRibbonSelected } from 'common/slice'
import { setRibbonContent, setRibbonViewmore, setCurrentRibbon } from '../ribbonSlice'

import config from 'common/config'

const usePageRibbons = () => {
  const menu = useSelector((state) => state.menu.menu)
  const account = useSelector(state => state.login.account)
  const isLogin = useSelector(state => state.login.isLogin)

  const { data: dataPageRibbon, isLoading: isLoadingPage } = useQuery(['getPage', menu, isLogin], async () => {
    if (menu && menu.slug && menu.slug !== 'search' && menu.slug !== 'login') {
      return await getDataTenant('GET', `tenant_pages/${menu.slug}/ribbons/`)
    }
  }, {
    // refetchonwindowfocus: true,
    cacheTime: 0,
    staleTime: Infinity
    // enabled: selectedMenu?.display_style !== 'CHANNELS'
  })

  const {
    data: personalizedPage,
    isError: personalizedPageError,
    isFetching: fetchPersonalizedPage,
    isLoading: personalizedPageLoading
  } = useQuery(
    ['personalized', menu, isLogin],
    async () => {
      if (isLogin && menu && menu.slug && menu.slug !== 'search' && menu.slug !== 'login') {
        const data = await callApiRoot('GET', `tenants/${config.tenantSlug}/accounts/${account?.profile?.id}/tenant_pages/${menu.id}/personalized_ribbons?limit=10`)
        return data.data
      }
      return { ribbons: [] }
    },
    {
      // refetchonwindowfocus: true,
      cacheTime: 0,
      staleTime: Infinity
    }
  )
  

  const dataPage = useMemo(() => {
    if (personalizedPage && dataPageRibbon) {
      const data = {
        ...dataPageRibbon,
        ribbons: [
          ...personalizedPage.ribbons,
          ...dataPageRibbon.ribbons,
        ]
      }
      return data
    }
    return null
  }, [personalizedPage, dataPageRibbon])
  


  return {
    dataPage,
    isLoadingRibbon: personalizedPageLoading || isLoadingPage
  }
}

export default usePageRibbons
