import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  viewmore: null
}

const viewmoreSlice = createSlice({
  name: 'viewmore',
  initialState,
  reducers: {
    setViewmore (state, action) {
      state.viewmore = action.payload
    }
  }
})

export const {
  setViewmore
} = viewmoreSlice.actions
export default viewmoreSlice.reducer
