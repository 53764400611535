import '../styles/information.scss'
const Infomation = ({ content, isOpen }) => {
  
  return (
    <div className={`vjs-infomation ${isOpen ? 'open' : 'close' }`}>
      <div className='vjs-infomation-type'>
        {content?.content_categories?.[0]?.name}
      </div>
      <div className='vjs-infomation-title'>
        {content?.title}
      </div>
      <div className='vjs-infomation-description'>
        {content?.long_description}
      </div>
    </div>
  )
}

export default Infomation