/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-var-requires */
'use strict'

// import config from './config'
import Hls from 'hls.js'
// var Hls = require('hls.js')
var hls = null

/**
 * hls.js source handler
 * @param source
 * @param tech
 * @constructor
 */
function Html5HlsJS(source, tech) {
  var options = tech.options_
  var el = tech.el()
  var duration = null
  hls = this.hls = new Hls(options.hlsjsConfig)
  // let timeToStartWatching = new Date().getTime()
  // const WATCH_TIME_CAN_RESET = 15
  const isDebugMode = false

  // const statusRemoteDebugJS = localStorage.getItem('statusRemoteDebugJS') || null
  // const ups = localStorage.getItem('user_profile')
  // const user_profile = ups ? JSON.parse(ups) : null
  // const NON_PLANS = ['free', 'basic', 'plus']

  // if (statusRemoteDebugJS === 'enable') {
  //   isDebugMode = true
  // }

  /**
   * creates an error handler function
   * @returns {Function}
   */
  function errorHandlerFactory() {
    var _recoverDecodingErrorDate = null
    var _recoverAudioCodecErrorDate = null

    return function () {
      var now = Date.now()

      if (!_recoverDecodingErrorDate || now - _recoverDecodingErrorDate > 2000) {
        _recoverDecodingErrorDate = now
        hls.recoverMediaError()
      } else if (!_recoverAudioCodecErrorDate || now - _recoverAudioCodecErrorDate > 2000) {
        _recoverAudioCodecErrorDate = now
        hls.swapAudioCodec()
        hls.recoverMediaError()
      } else {
        console.error('Error loading media: File could not be played')
      }
    }
  }

  // create separate error handlers for hlsjs and the video tag
  var hlsjsErrorHandler = errorHandlerFactory()
  var videoTagErrorHandler = errorHandlerFactory()

  // listen to error events coming from the video tag
  el.addEventListener('error', function (e) {
    var mediaError = e.currentTarget.error

    if (mediaError.code === mediaError.MEDIA_ERR_DECODE) {
      videoTagErrorHandler()
    } else {
      console.error('Error loading media: File could not be played')
    }
  })

  /**
   * Destroys the Hls instance
   */
  this.dispose = function () {
    console.log('Hls.js dispose() called')
    hls.destroy()
  }

  // if (config.buildType.isLocal() && window.platform === 'web') {
  //   window.hlsjsRefs = window.hlsjsRefs || []
  //   window.hlsjsRefs.push(new WeakRef(hls))
  // }

  /**
   * returns the duration of the stream, or Infinity if live video
   * @returns {Infinity|number}
   */
  this.duration = function () {
    return duration || el.duration || 0
  }

  // update live status on level load
  hls.on(Hls.Events.LEVEL_LOADED, function (event, data) {
    if (isDebugMode) {
      console.log('LEVEL_LOADED', data)
    }
    duration = data.details.live ? Infinity : data.details.totalduration
  })

  // try to recover on fatal errors
  hls.on(Hls.Events.ERROR, function (event, data) {
    if (data.fatal) {
      switch (data.type) {
        case Hls.ErrorTypes.NETWORK_ERROR:
          hls.startLoad()
          break
        case Hls.ErrorTypes.MEDIA_ERROR:
          hlsjsErrorHandler()
          break
        default:
          console.error('Error loading media: File could not be played')
          break
      }
    }
  })

  Object.keys(Hls.Events).forEach(function (key) {
    var eventName = Hls.Events[key]
    hls.on(eventName, function (event, data) {
      if (isDebugMode) {
        console.log(`${new Date().toLocaleString()} ${eventName}`)
      }
      tech.trigger(eventName, data)
    })
  })

  // hls.on('TOO_FAR_AWAY_LIVE_EDGE', function () {
  //   const curTime = new Date().getTime()
  //   const watchingTime = (curTime - timeToStartWatching) / 1000 / 60

  //   if (isDebugMode) {
  //     console.log(`TOO_FAR_AWAY_LIVE_EDGE: ${watchingTime}p`)
  //   }

  //   if (Math.abs(Math.round(watchingTime)) >= WATCH_TIME_CAN_RESET) {
  //     hls.stopLoad()

  //     setTimeout(() => {
  //       hls.startLoad()
  //     }, 2000)

  //     timeToStartWatching = new Date().getTime()
  //   }
  // })

  hls.on(Hls.Events.FRAG_LOADED, (event, data) => {
    try {
      if (isDebugMode) {
        const startPTS = data?.frag?.elementaryStreams?.video?.startPTS
        const endPTS = data?.frag?.elementaryStreams?.video?.endPTS
        const fragDuration = data?.frag?.duration
        const level = data?.frag?.level
        const sn = data?.frag?.sn
        const loadingStart = data?.frag?.stats?.loading?.start
        const loadingEnd = data?.frag?.stats?.loading?.end
        const fragLoadingTime = (loadingEnd - loadingStart) / 1000
        const bwEstimate = (data?.frag?.stats?.bwEstimate || 0) / 1000000
  
        console.log(
          `Loaded frag #${sn} level=${level},duration: ${fragDuration?.toFixed(
            2
          )}s, PTS(start=${startPTS?.toFixed(2)},end=${endPTS?.toFixed(
            2
          )}). Loading time=${fragLoadingTime?.toFixed(2)}s, Frag bwEstimate: ${bwEstimate?.toFixed(
            2
          )}Mbps.`
        )
      }
    } catch (e) {
      //
    }
  })

  hls.on(Hls.Events.MANIFEST_LOADED, (event, data) => {
    try {
      if (isDebugMode) {
        // console.log('MANIFEST_LOADED', data)
        const loadingStart = data?.stats?.loading?.start
        const loadingEnd = data?.stats?.loading?.end
        console.log(`Manifest loaded time: ${(loadingEnd - loadingStart) / 1000}`)
        console.log(`Levels list ${data?.levels?.length}`)
        data?.levels?.map(level => {
          console.log(
            `- Resolution: ${level?.attrs?.RESOLUTION}, bitrate: ${level?.bitrate / 1000000} Mbps`
          )
        })
      }
    } catch (e) {
      //
    }
  })

  // Intercept native TextTrack calls and route to video.js directly only
  // if native text tracks are not supported on this browser.
  if (!tech.featuresNativeTextTracks) {
    Object.defineProperty(el, 'textTracks', {
      value: tech.textTracks,
      writable: false
    })
    el.addTextTrack = function () {
      return tech.addTextTrack.apply(tech, arguments)
    }
  }

  // attach hlsjs to videotag
  hls.attachMedia(el)
  hls.loadSource(source.src)
}

var hlsTypeRE = /^application\/(x-mpegURL|vnd\.apple\.mpegURL)$/i
var hlsExtRE = /\.m3u8/i

var HlsSourceHandler = {
  canHandleSource: function (source) {
    if (source.skipContribHlsJs) {
      return ''
    } else if (hlsTypeRE.test(source.type)) {
      return 'probably'
    } else if (hlsExtRE.test(source.src)) {
      return 'maybe'
    } else {
      return ''
    }
  },
  handleSource: function (source, tech) {
    return new Html5HlsJS(source, tech)
  },
  canPlayType: function (type) {
    if (hlsTypeRE.test(type)) {
      return 'probably'
    }

    return ''
  }
}

if (Hls.isSupported()) {
  var videojs = require('video.js') // resolved UMD-wise through webpack

  // support es6 style import
  videojs = (videojs && videojs.default) || videojs

  if (videojs) {
    var html5Tech = videojs.getTech && videojs.getTech('Html5') // videojs6 (partially on videojs5 too)
    html5Tech = html5Tech || (videojs.getComponent && videojs.getComponent('Html5')) // videojs5

    if (html5Tech) {
      html5Tech.registerSourceHandler(HlsSourceHandler, 0)
    }
  } else {
    console.warn(
      "videojs-contrib-hls.js: Couldn't find find window.videojs nor require('video.js')"
    )
  }
}

export { hls, Hls }
