
import { useState, useEffect, useRef, useCallback } from 'react'

import {  keyCode, getHeader } from 'common/utils'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import useDrmQnet from './useDrmQnet'
import axios from 'axios'
import queryString from 'query-string'

const TIMER_PING = 60000
const FOCUS_MAP = ['back', 'btn']

const useStreamLimit = ({ playerRef }) => {
  const history = useHistory()
  const playerData = useSelector((state) => state.player.playerData)

  const timerInterval = useRef(null)
  const timeOutLimit = useRef(false)
  const initRef = useRef(null)
  const endPingDrmRef = useRef(false)
  const tokenRef = useRef(null)
  const limitRef = useRef(false)


  const [isLimit, setLimit] = useState(false)
  const [limitErr, setLimitErr] = useState(0)
  const [loadingLimit, setLoadingLimit] = useState(false)
  const [activeCwl, setActive] = useState(1)

  const { handleRetryDrm, limitDrm } = useDrmQnet({ playerRef, setLoadingLimit })

  useEffect(() => {
    if (limitErr === 6) {
      createModal()
    }
    if (limitErr > 0 && limitErr < 6) {
      setupStreamLimit()
    }
  }, [limitErr])


  useEffect(() => {
    if (isLimit) {
      clearTimer()
      pingToken('end')
    }
  }, [isLimit])

  const setupStreamLimit = async (player = playerRef?.current) => {
    initRef.current = true
    endPingDrmRef.current = false
    await pingToken('end')
    if (!player) return
    try {
      await pingToken()
      initEventPlayer(player)
      timerInterval.current = setInterval(async () => {
        await pingToken()
      }, TIMER_PING)
    } catch {
      createModal(player)
    }
  }

  const createModal = (player = playerRef?.current) => {
    if (!player) return
    clearTimer()
    pingToken('end')
    limitRef.current = true
    setLimit(true)
  }

  const pingToken = async (action = 'ping') => {
    try {
      const data = await callApiDrm(action)
      return data?.data
    } catch (e) {
      if (e.response?.data?.error_code === "max_devices_ccu_limited") {
        clearTimer()
        throw new Error(e.response?.data?.message)
      } 
      setLimitErr(() => limitErr + 1)
    }
  }

  const callApiDrm = async (action) => {
    // const userProfile = JSON.parse(localStorage.getItem('user_profile'))
    // const accountId = userProfile?.profile?.id
    // const data = {
    //   user_id: accountId,
    //   session: uuid4
    // }
    // return await callApiRoot('POST',`/v2/tenants/${config.tenantSlug}/accounts/${accountId}/drm/${action}`, data )
    pingOrRefreshToken(action)
  }

  const pingOrRefreshToken = async (action) => {
    if (!playerData.cwl_info) return
    /* eslint-disable camelcase */
    const { session, operator_id, session_id, limit_ccu_ping_url, limit_ccu_end_url, limit_ccu_refresh_url } = playerData.cwl_info
    let params = {
      sessionId: session_id,
      operatorId: operator_id || null,
      session: session
    }
    if (tokenRef.current) {
      params = { token: tokenRef.current }
    }
    let queryParams = queryString.stringify(params)
    let linkApi = `${limit_ccu_ping_url}?${queryParams}`
    if (action === 'refresh') {
      linkApi = `${limit_ccu_refresh_url}?${queryParams}`
    }
    // console.log('ping', action)
    // console.log('token', tokenRef.current)

    if (action === 'end') {
      clearTimer()
      if (!tokenRef.current) {
        return
      }
      const paramsend = {
        token: tokenRef.current
      }
      const queryParamEnd = queryString.stringify(paramsend)
      linkApi = `${limit_ccu_end_url}?${queryParamEnd}`
      tokenRef.current = null
    }
  
    try {
      const headers = getHeader()
      const tokenData = await axios.get(linkApi, { headers: headers, validateStatus: () => true })
      const { status, data } = tokenData

      if (data.token) {
        tokenRef.current = data.token
      }
      if (status) {
        switch (status) {
          case 426: {
            pingOrRefreshToken('refresh')
            break
          }
          case 401: case 405: case 400: {
            createModal()
            break
          }
          default:
            limitRef.current = false
            break
        }
      }
    } catch (error) {
      console.error(error, 'error')
    }
  }

  const initEventPlayer = (player) => {
    if (!initRef.current) return
    player?.on('dispose', () => {
      handleReset()
    })
  }

  const handleRetry = useCallback(async () => {
    endPingDrmRef.current = false
    if (limitDrm) {
      handleRetryDrm()
      return
    }
    setLoadingLimit(true)
    try {
      await callApiDrm('ping')
      const timer = setTimeout(() => {
        setLimit(limitRef.current)
        setLimitErr(0)
        clearTimeout(timer)
      }, 1000);
    } finally {
      timeOutLimit.current = setTimeout(() => {
        setLoadingLimit(false)
        clearTimeout(timeOutLimit.current)
      }, 1000);
    }
  }, [limitDrm])

  useEffect(() => {
    return () => {
      clearTimer()
      handleEndPing()
    }
  }, [])

  const clearTimer = () => {
    clearInterval(timerInterval.current)
  }

  const handleReset = () => {
    if (!endPingDrmRef.current && !playerData?.drmSession) {
      handleEndPing()
    }
  }

  const handleEndPing = () => {
    pingToken('end')
    endPingDrmRef.current = true
  }

  const handleKeyLimit = (e) => {
    switch (e.keyCode) {
      case keyCode.EXIT:
      case keyCode.RETURN: {
        history.goBack()
        break
      }
      case keyCode.ENTER: {
        if (FOCUS_MAP[activeCwl] === FOCUS_MAP[0]) {
          history.goBack()
        }

        if (FOCUS_MAP[activeCwl] === FOCUS_MAP[1] && !loadingLimit) {
          handleRetry()
        }
        break
      }

      case keyCode.DOWN: {
        setActive(1)
        break
      }
      case keyCode.UP: {
        setActive(0)
        break
      }
    }
  }

  return {
    setupStreamLimit,
    handleRetry,
    handleKeyLimit,
    callApiDrm,

    handleRetryDrm,
    isLimit: isLimit || limitDrm,
    loadingLimit,
    activeCwl: FOCUS_MAP[activeCwl]
  }
}

export default useStreamLimit
