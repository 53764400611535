import React, { useEffect, useState, useCallback } from 'react'
// import { useHistory } from 'react-router-dom'
import { keyCode, getDataTenant } from 'common/utils'
import '../styles/login.scss'
// import { icSpace } from '../../../constant/images'
import { useMutation } from 'react-query'
import Spinner from 'components/Spinner'

const LoginPage = (props) => {
  // const history = useHistory()

  const [active, setActive] = useState({
    field: 'email',
    tag: 'form',
    item: -1,
    category: 0
  })
  const [isLoading, setIsLoading] = useState(false)
  const [usernameText, setUsernameText] = useState('')
  const [passwordText, setPasswordText] = useState('')
  const [isShiftActive, setIsShiftActive] = useState(false)

  const [errorText, setErrorText] = useState('')

  const eventListener = useCallback(() => {
  }, [active])

  const { mutate: handleLoginAction } = useMutation(
    async () => await getDataTenant('POST', 'accounts/user_account_login', { username: usernameText, password: passwordText }),
    {
      onSuccess: async loginResponse => {
        setIsLoading(false)
        await localStorage.setItem('user_profile', JSON.stringify(loginResponse))
        handleClickBack()
      },
      onError: error => {
        setIsLoading(false)
        setErrorText('Thông tin tài khoản đăng nhập không đúng, vui lòng kiểm tra lại.')
        console.log('Thông tin tài khoản đăng nhập không đúng', error)
      }
    }
  )

  const listAlphabet = [
    ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l'],
    ['m', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x'],
    ['y', 'z', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
  ]

  const listAlphabetShift = [
    ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'],
    ['M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X'],
    ['Y', 'Z', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
  ]

  useEffect(() => {
    document.addEventListener('keydown', handleKey)
    return () => {
      document.removeEventListener('keydown', handleKey)
    }
  }, [eventListener])

  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */
  const handleKey = (e) => {
    e.preventDefault()

    switch (e.keyCode) {
      case keyCode.ENTER: {
        if (active.tag === 'form') {
          if (active.field === 'email' || active.field === 'password') {
            setActive({
              ...active,
              item: 0,
              tag: 'alphabet'
            })
            setErrorText('')
          }

          if (active.field === 'loginBtn') {
            if (!isLoading) {
              if (usernameText && passwordText) {
                setErrorText('')
                setIsLoading(true)
                handleLoginAction()
              } else {
                setErrorText('Vui lòng nhập đầy đủ thông tin')
              }
            }
          }

          if (active.field === 'cancelBtn') {
            handleClickBack()
          }
        }

        if (active.tag === 'alphabet') {
          if (!Number.isInteger(active.category) && active.category === 'action') {
            if (active.item === 'backspace') {
              handleClickBackspace()
            }

            // if (active.item === 'space') {
            //   handleClickSpace()
            // }

            if (active.item === 'done') {
              handleClickDone()
            }

            if (active.item === 'shift') {
              handleClickShift()
            }
          } else {
            if (active.field === 'email') {
              const key = isShiftActive ? listAlphabetShift[active.category][active.item] : listAlphabet[active.category][active.item]
              setUsernameText(text => text.concat(key))
            }
            if (active.field === 'password') {
              const key = isShiftActive ? listAlphabetShift[active.category][active.item] : listAlphabet[active.category][active.item]
              setPasswordText(text => text.concat(key))
            }
          }
        }
        break
      }
      case keyCode.DOWN: {
        if (active.tag === 'form') {
          if (active.field === 'email') {
            setActive({
              ...active,
              field: 'password'
            })
          }

          if (active.field === 'password') {
            setActive({
              ...active,
              field: 'loginBtn'
            })
          }

          if (active.field === 'loginBtn') {
            setActive({
              ...active,
              field: 'cancelBtn'
            })
          }
        }

        if (active.tag === 'alphabet') {
          if (active.category < 2) {
            setActive({
              ...active,
              category: active.category + 1,
              item: active.item
            })
          } else {
            setActive({
              ...active,
              category: 'action',
              item: 'shift'
            })
          }
        }
        break
      }
      case keyCode.UP: {
        if (active.tag === 'form') {
          if (active.field === 'password') {
            setActive({
              ...active,
              field: 'email'
            })
          }

          if (active.field === 'loginBtn') {
            setActive({
              ...active,
              field: 'password'
            })
          }

          if (active.field === 'cancelBtn') {
            setActive({
              ...active,
              field: 'loginBtn'
            })
          }
        }

        if (active.tag === 'alphabet') {
          if (Number.isInteger(active.category) && active.category > 0) {
            setActive({
              ...active,
              category: active.category - 1,
              item: active.item
            })
          }

          if (active.category === 'action') {
            setActive({
              ...active,
              category: 2,
              item: 0
            })
          }
        }
        break
      }
      case keyCode.RIGHT: {
        if (active.tag === 'alphabet') {
          if (!Number.isInteger(active.category) && active.category === 'action') {
            // if (active.item === 'space') {
            //   setActive({
            //     ...active,
            //     item: 'backspace'
            //   })
            // }

            if (active.item === 'backspace') {
              setActive({
                ...active,
                item: 'done'
              })
            }

            if (active.item === '@') {
              setActive({
                ...active,
                item: '.'
              })
            }

            if (active.item === '.') {
              setActive({
                ...active,
                item: 'backspace'
              })
            }

            if (active.item === 'shift') {
              setActive({
                ...active,
                item: '@'
              })
            }
          } else { // không phải nút space, backspace
            if (active.item < 11) {
              setActive({
                ...active,
                item: active.item + 1
              })
            }
          }
        }
        break
      }
      case keyCode.LEFT: {
        if (active.tag === 'alphabet') {
          if (active.category === 'action') {
            if (active.item === 'done') {
              setActive({
                ...active,
                item: 'backspace'
              })
            }

            if (active.item === 'backspace') {
              setActive({
                ...active,
                item: '.'
              })
            }

            // if (active.item === 'space') {
            //   setActive({
            //     ...active,
            //     item: '.'
            //   })
            // }

            if (active.item === '.') {
              setActive({
                ...active,
                item: '@'
              })
            }

            if (active.item === '@') {
              setActive({
                ...active,
                item: 'shift'
              })
            }
          } else { // không phải nút space, backspace
            if (Number.isInteger(active.category) && active.item > 0) {
              setActive({
                ...active,
                item: active.item - 1
              })
            }
          }
        }
      }
    }
  }

  const handleClickLogin = () => {
    if (!isLoading) {
      if (usernameText && passwordText) {
        setErrorText('')
        setIsLoading(true)
        handleLoginAction()
      } else {
        setErrorText('Vui lòng nhập đầy đủ thông tin')
      }
    }
  }

  const handleClickShift = () => {
    setIsShiftActive(prev => !prev)
  }

  const handleClickAlphabet = (e, index, indexChild) => {
    if (active.field === 'email') {
      const key = isShiftActive ? listAlphabetShift[index][indexChild] : listAlphabet[index][indexChild]
      setUsernameText(text => text.concat(key))
    }

    if (active.field === 'password') {
      const key = isShiftActive ? listAlphabetShift[index][indexChild] : listAlphabet[index][indexChild]
      setPasswordText(text => text.concat(key))
    }
  }

  const handleClickBackspace = () => {
    if (active.field === 'email') {
      setUsernameText(text => text.slice(0, -1))
    }

    if (active.field === 'password') {
      setPasswordText(text => text.slice(0, -1))
    }
  }

  const handleClickInput = (type) => {
    setErrorText('')
    setActive({
      ...active,
      tag: 'alphabet',
      field: type
    })
  }

  const handleClickDone = () => {
    setActive({
      ...active,
      field: 'email',
      tag: 'form',
      item: -1,
      category: 0
    })
  }

  const handleClickACong = () => {
    setUsernameText(text => text.concat('@'))
  }

  const handleClickDot = () => {
    setUsernameText(text => text.concat('.'))
  }

  const handleClickBack = () => {
    // history.goBack()
    setActive({
      field: 'email',
      tag: 'form',
      item: -1,
      category: 0
    })
  }

  return (
    <div className="container-fluid">
      <div className="login-container col-md-3">
        <div className="mb-3">
          <label className="form-label label">Số điện thoại hoặc Email</label>
          <input
            onClick={e => handleClickInput('email')}
            value={usernameText} type="text"
            className={`form-control email-input ${active.field === 'email' ? 'active' : ''}`}
            id="formGroupExampleInput"
            autoComplete={false}
          />
        </div>
        <div className="mb-4">
          <label className="form-label label">Mật khẩu</label>
          <input
            onClick={e => handleClickInput('password')}
            value={passwordText} type="password"
            className={`form-control password-input ${active.field === 'password' ? 'active' : ''}`}
            id="formGroupExampleInput"
            autoComplete={false}
          />
        </div>
        {errorText
          ? (<div className="error-text">{errorText}</div>)
          : null}
        <button
          onClick={handleClickLogin}
          className={`btn btn-primary login-btn ${active.field === 'loginBtn' ? 'active' : ''}`}
        >
          {
            isLoading
              ? (<Spinner />)
              : 'Đăng nhập'
          }
        </button>
        <button
          onClick={handleClickBack}
          className={`btn btn-primary back-btn ${active.field === 'cancelBtn' ? 'active' : ''}`}
        >
          Trở lại
        </button>
      </div>

      <div className="keyboard">
        {listAlphabet.map((item, index) => {
          return (
            <div key={index} className="row search-alphabet-container">
              {item.map((itemChild, indexChild) => {
                return (
                  <div
                    key={indexChild}
                    className={`col-md-1 search-alphabet \
                      ${active.tag === 'alphabet' && active.category === index && active.item === indexChild ? 'active' : ''} \
                      ${indexChild === item.length - 1 ? 'search-border-right' : ''} \
                      ${index === listAlphabet.length - 1 ? 'search-border-bottom' : ''}`
                    }
                    onClick={e => handleClickAlphabet(e, index, indexChild)}
                  >
                    {isShiftActive ? listAlphabetShift[index][indexChild] : listAlphabet[index][indexChild]}
                  </div>
                )
              })}
            </div>
          )
        })}

        <div className="row mt-2 ps-6 pe-6">
          <div className="col-md-9 offset-md-3">
            <div
              className={`d-inline-block me-4 ps-4 pe-4 search-alphabet-action ${(active.tag === 'alphabet' && active.category === 'action' && active.item === 'shift') || isShiftActive ? 'active' : ''}`}
              onClick={handleClickShift}
            >
              Shift
            </div>
            <div
              className={`d-inline-block me-4 ps-4 pe-4 search-alphabet-action ${active.tag === 'alphabet' && active.category === 'action' && active.item === '@' ? 'active' : ''}`}
              onClick={handleClickACong}
            >
              @
            </div>
            <div
              className={`d-inline-block me-4 ps-4 pe-4 search-alphabet-action ${active.tag === 'alphabet' && active.category === 'action' && active.item === '.' ? 'active' : ''}`}
              onClick={handleClickDot}
            >
              .
            </div>
            {/* <div
              className={`d-inline-block me-4 ps-4 pe-4 search-alphabet-action ${active.tag === 'alphabet' && active.category === 'action' && active.item === 'space' ? 'active' : ''}`}
              onClick={handleClickSpace}
            >
              <img src={icSpace} />
            </div> */}
            <div
              className={`d-inline-block ms-4 ps-4 pe-4 search-alphabet-action ${active.tag === 'alphabet' && active.category === 'action' && active.item === 'backspace' ? 'active' : ''}`}
              onClick={handleClickBackspace}
            >
              Xóa
            </div>
            <div
              className={`d-inline-block ms-4 ps-4 pe-4 search-alphabet-action ${active.tag === 'alphabet' && active.category === 'action' && active.item === 'done' ? 'active' : ''}`}
              onClick={handleClickDone}
            >
              Xong
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
