/* eslint-disable react-hooks/exhaustive-deps */
import  { useEffect, useState, useCallback, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import Epg from 'features/epg/components/Epg'
import { keyCode } from 'common/utils'
import { setIsShowEpg, setPlayerDataLive, setPlayerData } from 'features/player/playerSlice'
import { setContent } from 'features/content/contentSlice'

import VideoLiveJS from 'components/VideoLive'
import { logoMenu } from '../../../constant/images'
import TopControlBar from './topControlBar'

import useStreamLimit from '../hooks/streamingLimit'
import useEventFirebase from '../hooks/eventFirebase'
import useLiveEvent from '../hooks/liveEvent'
import useBlockKeyboard from 'hooks/useBlockKeyboard'
import useFetchPlayer from '../hooks/useFetchPlayer'
import Spinner from 'components/Spinner'
import Infomation from './infomation'
import Cwl from './cwl'

import '../styles/player-live.scss'


const PlayerLive = () => {
  const history = useHistory()
  const playerRef = useRef(null)
  const modalEpgRef = useRef()
  const enterCountRef = useRef(null)
  const timeoutResetEnterCountRef = useRef(null)
  const isDisplayDebugLogRef = useRef(null)
  const timerGetDebugLogRef = useRef(null)
  const remoteDebugLogChannelIdRef = useRef(null)
  const timeoutVar = useRef(null)

  const dispatch = useDispatch()
  const ribbonLive = useSelector((state) => state.ribbon.ribbonLive)
  const isShowEpg = useSelector((state) => state.player.isShowEpg)
  const login = useSelector((state) => state.login)
  const playerData = useSelector((state) => state.player.playerData)
  const playerDataLive = useSelector((state) => state.player.playerDataLive)
  const content = useSelector((state) => state.content.content)
  const isLogin = useSelector(state => state.login.isLogin)
  

  const [src, setSrc] = useState(null)
  const [options, setOptions] = useState(null)

  const [player, setPlayer] = useState(null)
  const [channelId, setChannelId] = useState('')

  const [title, setTitle] = useState('')
  const [active, setActive] = useState('back') // schedule: lịch phát sóng, back: nút Back
  const [showInfo, setShowInfo] = useState(true)
  const {
    setupStreamLimit,
    handleRetry,
    handleKeyLimit,

    isLimit,
    loadingLimit,
    activeCwl
  } = useStreamLimit({ playerRef })

  const { checkLogEvent } = useEventFirebase(playerRef)
  const { isBlockKeyBoardLiveEvent, isLiveEventType, statusLiveEvent } = useLiveEvent({ playerRef })

  const [isDisplayDebugLog, setIsDisplayDebugLog] = useState(false)
  const [bandWidth, setBandWidth] = useState(0)
  const [videoBitrateState, setVideoBitrateState] = useState(0)
  const [currentTimeDebug, setCurrentTimeDebug] = useState(0)
  const [buffered, setBuffered] = useState(0)
  const [videoBuffered, setVideoBuffered] = useState(0)
  const [seekable, setSeekable] = useState(0)
  const [bufferCount, setBufferCount] = useState(0)
  const [currentSource, setCurrentSource] = useState('')
  const [currentSegment, setCurrentSegment] = useState({})
  const [isBlackOut, setIsBlackOut] = useState(false)
  const [isShowInformation, setShowInfomation] = useState(false)
  const [dataLiveInfo, setDataLive] = useState(null)
  const { isBlockKeyboard } = useBlockKeyboard()

  const {
    dataLive,
    dataLiveDetail,

    isLoading,
    isLoadingDetail
  } = useFetchPlayer({ ribbonLive })


  useEffect(() => {
    if (dataLiveDetail && dataLive) {
      const data = {
        ...dataLiveDetail,
        ...dataLive
      }
      const dataContent = {
        id: data.id,
        slug: data.slug,
        defaultEpisodeId: null,
        season: false,
        listEpisode: [],
        content_title: '',
        title: data.title,
        views: data.views,
        linkPlay: data && data.link_play,
        drmSession: data.drm_session_info ? {
          ...data.drm_session_info,
          ...data.cwl_info
        } : null,
        playInfo: data?.play_info?.data,
        content_type: data?.type,
        cwl_info: data.cwl_info,
        titleProgram: data?.title,
        cp: data?.provider_slug,
        cpName: data?.metadata?.provider?.name
      }
      dispatch(setPlayerData(dataContent))

      dispatch(setContent(data))
      setDataLive(data)
    }
  }, [dataLive, dataLiveDetail])

  const eventListener = useCallback(() => {
  }, [
    dataLive,
    player,
    active,
    isShowEpg,
    showInfo,
    timeoutVar,
    isShowEpg,
    isLimit,
    isDisplayDebugLog,
    bandWidth,
    videoBitrateState,
    currentTimeDebug,
    buffered,
    videoBuffered,
    seekable,
    bufferCount,
    playerDataLive,
    isBlackOut,
    isBlockKeyBoardLiveEvent,
    isLiveEventType,
    isBlockKeyboard,
    statusLiveEvent,
    isShowInformation,
    activeCwl,
    loadingLimit
  ])

  useEffect(() => {
    document.addEventListener('keydown', handleKey)
    return () => {
      document.removeEventListener('keydown', handleKey)
    }
  }, [eventListener])

  useEffect(() => {
    if (isLimit) {
      setPlayer(null)
    }
  }, [isLimit])

  useEffect(() => {
    window.onpopstate = () => {
      if (!isShowEpg) {
        if (timeoutVar.current) {
          clearTimeout(timeoutVar.current)
        }

        dispatch(setIsShowEpg(false))
        modalEpgRef.current.disposeModal()
        history.goBack()
      }
    }

    return () => {
      console.clear()

      if (timeoutVar.current) {
        clearTimeout(timeoutVar.current)
      }
    }
  }, [])

  useEffect(() => {
    if (isLiveEventType) return
    setActive('schedule')
  }, [isLiveEventType])

  useEffect(() => {
    if (dataLive && !options) {
      dispatch(setPlayerDataLive(dataLive))

      setChannelId(dataLive.id)
      setTitle(dataLive.title)

      setSrc(dataLive?.link_play)
    }
  }, [dataLive])

  useEffect(() => {
    if (!playerRef.current) return
      // console.log('isBlackOut', isBlackOut)
    if (isBlackOut) {
      playerRef.current?.pause()
    } else {
      playerRef.current?.play()
    }
    
  }, [isBlackOut])

  useEffect(() => {
    if (player) {
      // player.src({
      //   type: 'application/x-mpegURL',
      //   src: dataLive.link_play
      // })

      remoteDebugLogChannelIdRef.current = localStorage.getItem('remoteDebugLogChannelID')

      const elementCurrentTime = document.getElementsByClassName('vjs-current-time') && document.getElementsByClassName('vjs-current-time')[0]
      const elementControlBar = document.getElementsByClassName('vjs-control-bar') && document.getElementsByClassName('vjs-control-bar')[0]
      if (elementCurrentTime) {
        elementCurrentTime.style.display = 'none'
      }
      if (elementControlBar) {
        elementCurrentTime.style.display = 'none'
      }

      player.on('waiting', () => {
        // if (toast && toast._element) {
        //   timeoutBuffering = setTimeout(() => {
        //     toast.show()
        //   }, 60000)
        // }
        if (isDisplayDebugLogRef.current) {
          setBufferCount(pre => pre + 1)

          const curSegment = getCurrentSegmentInfo(player)
          // console.log('--------------- waiting curSegment', curSegment)
          setCurrentSegment(() => curSegment)
        }
      })

      player.on('playing', () => {
        // clearTimeout(timeoutBuffering)
        if (isDisplayDebugLogRef.current) {
          const curSegment = getCurrentSegmentInfo(player)
          // console.log('--------------- playing curSegment', curSegment)
          setCurrentSegment(() => curSegment)
        }
      })

      player.on('progress', () => {
        //
      })

      player.on('timeupdate', () => {
        if (isDisplayDebugLogRef.current) {
          setCurrentTimeDebug(player.currentTime().toFixed(1))
        }
      })

      // Finish init player
    }

  }, [player])

  useEffect(() => {
    if (isBlockKeyBoardLiveEvent && !showInfo) {
      setShowInfo(true)
    }
    if (showInfo) {
      if (document.getElementsByClassName('vjs-text-track-display') && document.getElementsByClassName('vjs-text-track-display')[0]) {
        document.getElementsByClassName('vjs-text-track-display')[0].style.bottom = '0'
        document.getElementsByClassName('vjs-text-track-display')[0].style.backgroundColor = 'black'
        document.getElementsByClassName('vjs-text-track-display')[0].style.opacity = '0.7'
      }

      if(isBlockKeyBoardLiveEvent) return
      timeoutVar.current = setTimeout(() => {
        setShowInfo(false)
        clearTimeout(timeoutVar.current)
        if (document.getElementsByClassName('vjs-text-track-display') && document.getElementsByClassName('vjs-text-track-display')[0]) {
          document.getElementsByClassName('vjs-text-track-display')[0].style.bottom = ''
          document.getElementsByClassName('vjs-text-track-display')[0].style.backgroundColor = ''
          document.getElementsByClassName('vjs-text-track-display')[0].style.opacity = ''
        }
      }, 4000)
    }
    return () => {
      clearTimeout(timeoutVar.current)
    }
  }, [showInfo, isBlockKeyBoardLiveEvent, active])

  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */

  const getBuffered = (buffered) => {
    let bufferedText = ''

    if (!buffered) {
      return bufferedText
    }

    if (buffered.length) {
      bufferedText += buffered.start(0) + ' - ' + buffered.end(0)
    }
    for (let i = 1; i < buffered.length; i++) {
      bufferedText += ', ' + buffered.start(i) + ' - ' + buffered.end(i)
    }
    return bufferedText
  }

  const increaseCount = () => {
    enterCountRef.current++
  }

  const createDebugModeCounter = () => {
    if (!player) return

    if (enterCountRef.current >= 10) {
      enterCountRef.current = 0
      if (timeoutResetEnterCountRef.current) {
        clearTimeout(timeoutResetEnterCountRef.current)
        timeoutResetEnterCountRef.current = null
      }

      if (isDisplayDebugLog) {
        setIsDisplayDebugLog(() => false)
        isDisplayDebugLogRef.current = false

        // window.videojs.log.level('off')
        // removeRemoteLogging()

        if (timerGetDebugLogRef.current) {
          clearInterval(timerGetDebugLogRef.current)
          timerGetDebugLogRef.current = null
        }
      } else {
        setIsDisplayDebugLog(() => true)
        isDisplayDebugLogRef.current = true

        // addRemoteLogging(remoteDebugLogChannelIdRef.current)
        // window.videojs.log.level('debug')

        if (!timerGetDebugLogRef.current) {
          timerGetDebugLogRef.current = setInterval(() => {
            const bandwidth = (player?.tech_?.vhs?.bandwidth / 1024).toLocaleString(undefined, { maximumFractionDigits: 1 }) + ' kbps'
            setBandWidth(() => bandwidth)

            const playlist = player?.tech_?.vhs?.playlists?.media()
            if (playlist && playlist.attributes && playlist.attributes.BANDWIDTH) {
              const videoBW = (playlist.attributes.BANDWIDTH / 1024).toLocaleString(undefined, { maximumFractionDigits: 1 }) + ' kbps'
              setVideoBitrateState(() => videoBW)
            }

            setBuffered(getBuffered(player?.buffered()))

            const videoBufferedStat = getBuffered(player.tech_?.vhs?.playlistController_?.mainSegmentLoader_?.sourceUpdater_?.videoBuffer && player.tech(true)?.vhs?.playlistController_?.mainSegmentLoader_?.sourceUpdater_?.videoBuffer?.buffered)
            setVideoBuffered(() => videoBufferedStat)

            const seekable = player.seekable()
            if (seekable && seekable.length) {
              const seekableStartStat = seekable.start(0).toFixed(1)
              setSeekable(() => seekableStartStat)
            }

            const curSource = player?.currentSource()?.src
            setCurrentSource(() => curSource)

            const curSegment = getCurrentSegmentInfo(player)
            setCurrentSegment(() => curSegment)
          }, 2000)
        }
      }
    } else {
      if (timeoutResetEnterCountRef.current) {
        clearTimeout(timeoutResetEnterCountRef.current)
        timeoutResetEnterCountRef.current = null
      }

      timeoutResetEnterCountRef.current = setTimeout(() => {
        enterCountRef.current = 0
      }, 1000)
    }
  }

  const getCurrentSegmentInfo = (obj) => {
    let segment
    const targetMedia = obj?.tech_?.vhs?.playlists?.media()
    const snapshotTime = obj?.currentTime()

    if (targetMedia && targetMedia?.segments) {
      for (let i = 0, l = targetMedia?.segments?.length; i < l; i++) {
        if (snapshotTime < targetMedia.segments[i].end) {
          segment = targetMedia.segments[i]
          break
        }
      }
    }
    return segment
  }

  const handlePlayerReady = (player) => {
    playerRef.current = player
    setPlayer(player)
    checkLogEvent(player, 'live')
    // if (login?.isLogin === true && !playerData?.drmSession) {
    if (!playerData?.drmSession) {
      setupStreamLimit(playerRef.current)
    }
  }

  const disposeVis = () => {
    setPlayer(null)
    playerRef.current?.dispose?.()
    playerRef.current = null
  }

  useEffect(() => {
    if (isShowInformation) {
      setShowInfo(false)
      return
    }
    setShowInfo(true)
  }, [isShowInformation])

  const handleKeyLiveEvent = (e) => {
    if (!showInfo && !isShowInformation) {
      setShowInfo(true)
    } 
    clearTimeout(timeoutVar.current)
    switch (e.keyCode) {
      case keyCode.EXIT: {
        history.goBack()
        break
      }
      case keyCode.RETURN: {
        if (active === 'infoLiveEvent' && isShowInformation) {
          setShowInfomation(false)
          return
        }
        history.goBack()
        break
      }
      case keyCode.ENTER: {
        if (active === 'back') {
          history.goBack()
        }

        if (active === 'infoLiveEvent') {
          setShowInfomation(!isShowInformation)
        }
        break
      }

      case keyCode.LEFT: {
        if (active === 'infoLiveEvent' && isShowInformation) { 
          return
        }
        setActive('back')
        break
      }
      case keyCode.RIGHT: {
        setActive('infoLiveEvent')
        break
      }
    }
  }

  const handleKey = (e) => {
    if (isBlockKeyboard) return
    if (isLimit) {
      return handleKeyLimit(e)
    }
    if (isLiveEventType) {
      handleKeyLiveEvent(e)
      return
    }
    switch (e.keyCode) {
      case keyCode.RETURN: {
        if (isLimit) {
          history.goBack()
        }

        if (!isShowEpg) {
          dispatch(setIsShowEpg(false))
          modalEpgRef.current.disposeModal()
          history.goBack()
        }
        break
      }
      case keyCode.EXIT: {
        if (isLimit) {
          history.goBack()
        }
        if (!isShowEpg) {
          dispatch(setIsShowEpg(false))
          modalEpgRef.current.disposeModal()
          history.goBack()
        }
        break
      }
      case keyCode.ENTER: {
        if (!isShowEpg) {
          if (!showInfo) {
            setShowInfo(true)
          } else {
            if (active === 'back') {
              history.goBack()
            }

            if (active === 'schedule') {
              dispatch(setIsShowEpg(true))
            }
          }
        }
        break
      }
      // Hiển thị các action (nút Back, Lịch phát sóng)
      // Từ Lịch phát sóng --> nút Back
      case keyCode.UP: {
        if (!isShowEpg) {
          if (!showInfo) {
            setShowInfo(true)
          }
        }
        break
      }
      // Hiển thị các action (nút Back, Lịch phát sóng)
      // Từ nút Back --> Lịch phát sóng
      case keyCode.DOWN: {
        if (!isShowEpg) {
          if (!showInfo) {
            setShowInfo(true)
          }
        }
        break
      }
      // Hiển thị các action (nút Back, Lịch phát sóng)
      case keyCode.LEFT: {
        if (!isShowEpg) {
          if (!showInfo) {
            setShowInfo(true)
          } else {
            if (active === 'back' && !isLiveEventType) {
              setActive('schedule')
            }
            if (active === 'schedule') {
              setActive('back')
            }
          }
        }
        break
      }
      // Hiển thị các action (nút Back, Lịch phát sóng)
      case keyCode.RIGHT: {
        increaseCount()
        // createDebugModeCounter()

        if (!isShowEpg) {
          if (!showInfo) {
            setShowInfo(true)
          } else {
            if (active === 'back' && !isLiveEventType) {
              setActive('schedule')
            }
            if (active === 'schedule') {
              setActive('back')
            }
          }
        }
        break
      }
    }
  }

  const handleClickBack = () => {
    if (timeoutVar) {
      clearTimeout(timeoutVar)
    }
    history.goBack()
  }

  const handleClickShowEpg = () => {
    setActive('schedule')
    dispatch(setIsShowEpg(true))
  }

  if ((isLoading || isLoadingDetail) && !dataLiveInfo) return (
    <div className="player-live-loading">
      <Spinner />
    </div>
  )

  if (isLimit) {
    return (<Cwl handleRetry={handleRetry} loadingLimit={loadingLimit} activeCwl={activeCwl} />)
  }

  return (
    <div className="player-live">
      <div className="row">
        <div className="col col-md-12 player-live-col">
          <TopControlBar
            showInfo={showInfo}
            handleClickBack={handleClickBack}
            handleClickShowEpg={handleClickShowEpg}
            handleClickShowInfo={() => setShowInfomation(!isShowInformation)}
            isLiveEventType={isLiveEventType}
            active={active}
            isLive={true}
            title={title}
            content={content}
            player={player}
            isBlockKeyBoardLiveEvent={isBlockKeyBoardLiveEvent}
            statusLiveEvent={statusLiveEvent}
          />
          <div className="video-js-container" style={{ width: '100%', height: window.innerHeight }}>
            {/* <video ref={playerRef} className="video-js vjs-default-skin vjs-fill"></video> */}
            {isBlackOut ? (
              <div className='player-live-blackout-wrapper'>
                <img className='player-live-blackout-logo' src={logoMenu} />
                <div className='player-live-blackout-title'>Thông báo</div>
                <div className='player-live-blackout-message'>Chương trình tạm thời bị giáng đoạn vì lý do bản quyền.</div>
                <div className='player-live-blackout-message'>Mong quý khán giả thông cảm và quay lại sau.</div>
              </div>
            ) : null}
            <VideoLiveJS src={src} onReady={handlePlayerReady} player={player} videoIdTag='vimai-video-player' />
          </div>

          {/* Kênh EPG */}
          {player ?
            <Epg player={player} isRewatch={false} ref={modalEpgRef} channelId={channelId} title={title} setIsBlackOut={(value) => setIsBlackOut(() => value)} content={content}/>
          : null}

        </div>
      </div>

      {/* <div ref={toastRef} className="toast align-items-center text-white" role="alert" aria-live="assertive" aria-atomic="true">
        <div className="d-flex">
          <div className="toast-body">
            Kết nối mạng có vấn đề, vui lòng kiểm tra.
          </div>
        </div>
      </div> */}

      {/* <DebugVjs
        isDisplayDebugLog={isDisplayDebugLog}
        obj={{
          bandWidth: bandWidth, 
          videoBitrateState: videoBitrateState,
          currentTimeDebug: currentTimeDebug, 
          buffered: buffered, 
          videoBuffered: videoBuffered, 
          seekable: seekable,
          bufferCount: bufferCount,
          currentSource: currentSource,
          currentSegment: currentSegment,
          remoteDebugLogChannelIdRef: remoteDebugLogChannelIdRef
      }}
      /> */}
      <Infomation content={content} isOpen={isShowInformation} />

    </div>
  )
}

export default PlayerLive
