import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isShowWarningLogin: false,
  isLoadingOverlay: false,
  isShowWarningForcedLogout: false
}

const ModalSlide = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setIsShowWarningLogin (state, action) {
      state.isShowWarningLogin = action.payload
    },
    setIsLoadingOverlay (state, action)  {
      state.isLoadingOverlay = action.payload
    },
    setShowWarningForcedLogout (state, action)  {
      state.isShowWarningForcedLogout = action.payload
    }
  }
})
export const {
  setIsShowWarningLogin,
  setIsLoadingOverlay,
  setShowWarningForcedLogout
} = ModalSlide.actions
export default ModalSlide.reducer
