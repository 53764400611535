/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */

import  { useState, useEffect, useCallback, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useQuery } from 'react-query'
import { keyCode, getDataTenant, scrollVertical, scrollHorizontal, checkVipBadge } from 'common/utils'
import { setLoading, toggleOpenMenu, toggleNavigateMenu, toggleNavigateRibbon, setRibbonSelected } from 'common/slice'
import { setRibbonContent, setRibbonViewmore, setCurrentRibbon } from '../ribbonSlice'
import Carousel from 'bootstrap/js/dist/carousel'
import { viewmore } from '../../../constant/images'

import '../styles/ribbon.scss'
import { setIsLoadingOverlay } from 'features/modal/modalSlice'
import useFollowUpgrade from 'hooks/useFollowUpgrade'
import useBlockKeyboard from 'hooks/useBlockKeyboard'
import usePageRibbons from '../hooks/usePageRibbons'
import Thumbnail from 'components/thumbnail'
import Banner from './Banner'
import { v4 as uuidv4 } from 'uuid'

const Ribbon = () => {
  const history = useHistory()
  const bannerRef = useRef()

  const dispatch = useDispatch()
  const openMenu = useSelector((state) => state.root.openMenu)
  const navigateRibbon = useSelector((state) => state.root.navigateRibbon)
  const menu = useSelector((state) => state.menu.menu)
  const isEnbaleJSDebugMode = useSelector((state) => state.menu.isEnbaleJSDebugMode)
  const currentRibbon = useSelector((state) => state.ribbon.currentRibbon)
  const isShowLogin = useSelector((state) => state.login.isShowLogin)
  const isShowSubscription = useSelector((state) => state.subscription.isShowSubscription)
  const login = useSelector((state) => state.login)

  const isLoadingOverlay = useSelector((state) => state.modal.isLoadingOverlay)
  const isShowWarningLogin = useSelector((state) => state.modal.isShowWarningLogin)

  const [carousel, setCarousel] = useState(null)
  const [banners, setBanners] = useState([])
  const [data, setData] = useState([])
  const [selectedContent, setSelectedContent] = useState({})
  const [active, setActive] = useState({
    tag: 'ribbon',
    category: 0,
    item: 0
  })
  const [advertisingId, setAdvertisingId] = useState('')

  const {
    dataPage,
    isLoadingRibbon
  } = usePageRibbons()

  const { handleClick, isLoadingUpgrade } = useFollowUpgrade()
  const { isBlockKeyboard } = useBlockKeyboard()

  const eventListener = useCallback(() => {
    return navigateRibbon
  }, [
    data,
    banners,
    active,
    navigateRibbon,
    currentRibbon,
    isShowLogin,
    isShowSubscription,
    isShowWarningLogin,
    isLoadingOverlay,
    isEnbaleJSDebugMode,
    isBlockKeyboard
  ])

  useEffect(() => {
    if (eventListener()) {
      document.addEventListener('keydown', handleKey)
      return () => {
        document.removeEventListener('keydown', handleKey)
      }
    }
  }, [eventListener])

  useEffect(() => {
    const debugChannel = localStorage.getItem('advertisingId')
    setAdvertisingId(debugChannel)
  }, [])

  useEffect(() => {
    if (isLoadingUpgrade) {
      return dispatch(setIsLoadingOverlay(true))
    }
    dispatch(setIsLoadingOverlay(false))
  }, [isLoadingUpgrade])

  useEffect(() => {
    if (menu) {
      dispatch(setCurrentRibbon({
        isInit: true,
        tag: 'ribbon',
        category: 0,
        item: 0
      }))

      setActive({
        tag: 'ribbon',
        category: 0,
        item: 0
      })
    }
  }, [menu])

  useEffect(() => {
    if (carousel) {
      carousel.cycle()

      if (currentRibbon && currentRibbon.tag === 'banner') {
        setActive({
          tag: 'banner',
          category: 'banner',
          item: 'banner'
        })
      }
    }

    return () => {
      if (carousel) {
        carousel.dispose()
      }
    }
  }, [carousel])

  useEffect(() => {
    dispatch(setLoading(isLoadingRibbon))
  }, [isLoadingRibbon])

  useEffect(() => {
    // console.log('dataPage', dataPage)
    if (dataPage) {
      dispatch(setCurrentRibbon({
        isInit: false,
        tag: 'ribbon',
        category: 0,
        item: 0
      }))

      setBanners(dataPage.banners)

      if (Array.isArray(dataPage.ribbons)) {
        const dataRibbon = []
        // eslint-disable-next-line array-callback-return
        dataPage.ribbons.map((item, index) => {
          if (item.items.length > 0) {
            dataRibbon.push({
              ...item,
              currentActiveIndex: 0
            })
          }
        })

        if (currentRibbon && currentRibbon.tag === 'ribbon') {
          if (!currentRibbon.isInit) {
            for (let i = 0; i < dataRibbon.length; i++) {
              if (i === currentRibbon.category && dataRibbon[i] && dataRibbon[i].items) {
                for (let j = 0; j < dataRibbon[i].items.length; j++) {
                  if (j === currentRibbon.item) {
                    dataRibbon[i].currentActiveIndex = currentRibbon.item
                  }
                }
              }
            }

            setTimeout(() => {
              scrollVertical('ribbon', `ribbon-container-${currentRibbon.category}`, 'DOWN')
            }, 500)
            if (currentRibbon.item > 3) {
              setTimeout(() => {
                scrollHorizontal(`ribbon-item-container-${currentRibbon.category}`, `ribbon-item-${currentRibbon.item}`, 'RIGHT')
              }, 500)
            }
            setActive({
              tag: 'ribbon',
              category: currentRibbon.category ? currentRibbon.category : 0,
              item: currentRibbon.item ? currentRibbon.item : 0
            })
          } else {
            //
          }
        }
        // console.log('dataRibbon', dataRibbon)
        setData(dataRibbon)
      }
    }
  }, [dataPage])

  useEffect(() => {
    if (banners && banners.length > 0) {
      // eslint-disable-next-line no-new
      setCarousel(new Carousel(bannerRef.current))
    }
  }, [banners])

  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */
  const handleKey = (e) => {
    e.preventDefault()

    if (isBlockKeyboard || isShowSubscription || isShowWarningLogin || isShowLogin || isLoadingOverlay) return

    switch (e.keyCode) {
      case keyCode.ENTER: {
        if (active.tag === 'ribbon') {
          // Lưu trạng thái ribbon để focus
          dispatch(setCurrentRibbon({
            isInit: false,
            tag: 'ribbon',
            category: active.category,
            item: active.item
          }))

          dispatch(setRibbonSelected(data?.[active?.category]))

          if (active.item < 9) {
            const ribbonItem = data[active.category] && data[active.category].items && data[active.category].items[active.item]
            const isBadge = checkVipBadge(ribbonItem, login)
            handleClickRibbon(active.category, active.item, isBadge)
          } else {
            const ribbonItem = data[active.category]
            console.log('ribbonItem', ribbonItem)
            dispatch(setRibbonViewmore(ribbonItem))
            history.push('/viewmore')
          }
        }
        if (active.tag === 'banner') {

          // Lưu trạng thái ribbon để focus
          dispatch(setCurrentRibbon({
            isInit: false,
            tag: 'banner',
            category: 'banner',
            item: 'banner'
          }))

          const carouselItemList = [...document.querySelectorAll('.carousel-item')]
          const carouseItemActive = document.querySelector('.carousel-item.active')
          const indexActive = carouselItemList.indexOf(carouseItemActive)
          const ribbonItem = banners[indexActive]

          setSelectedContent(ribbonItem)
          handleClick(ribbonItem)
        }
        break
      }
      case keyCode.UP: {
        if (active.tag === 'ribbon') {
          if (active.category !== 'banner') {
            if (active.category === 0) {
              if (banners && banners.length > 0) {
                scrollVertical('ribbon', 'home-page', 'UP')
                setActive({
                  tag: 'banner',
                  category: 'banner',
                  item: 'banner'
                })
              }
            } else {
              scrollVertical('ribbon', `ribbon-container-${active.category - 1}`, 'UP')
              setActive({
                tag: 'ribbon',
                category: active.category - 1,
                item: data[active.category - 1].currentActiveIndex
              })
            }
          }
        }
        break
      }
      case keyCode.DOWN: {
        if (active.tag === 'banner') {
          setActive({
            tag: 'ribbon',
            category: 0,
            item: data[0] ? data[0].currentActiveIndex : 0
          })
        }

        if (active.tag === 'ribbon') {
          if (active.category < (data.length - 1)) {
            scrollVertical('ribbon', `ribbon-container-${active.category + 1}`, 'DOWN')
            setActive({
              tag: 'ribbon',
              category: active.category + 1,
              item: data[active.category + 1].currentActiveIndex
            })
          }
        }
        break
      }
      case keyCode.LEFT: {
        if (active.tag === 'ribbon') {
          if (active.item === 0) {
            dispatch(toggleOpenMenu())
            dispatch(toggleNavigateMenu())
            dispatch(toggleNavigateRibbon())
          } else {
            if (active.item === 1) {
              scrollHorizontal(`ribbon-item-container-${active.category}`, `ribbon-item-container-${active.category}`, 'LEFT')
            } else {
              scrollHorizontal(`ribbon-item-container-${active.category}`, `ribbon-item-${active.item - 1}`, 'LEFT')
            }
            setActive({
              tag: 'ribbon',
              category: active.category,
              item: active.item - 1
            })
            data[active.category].currentActiveIndex = active.item - 1
          }
        }

        if (active.tag === 'banner') {
          dispatch(toggleOpenMenu())
          dispatch(toggleNavigateMenu())
          dispatch(toggleNavigateRibbon())
        }
        break
      }
      case keyCode.RIGHT: {
        if (active.tag === 'ribbon') {
          if (data[active.category] && (data[active.category]).items && (data[active.category].items)[active.item + 1]) {
            if (active.item < 9) {
              scrollHorizontal(`ribbon-item-container-${active.category}`, `ribbon-item-${active.item + 1}`, 'RIGHT')
              setActive({
                tag: 'ribbon',
                category: active.category,
                item: active.item + 1
              })
              data[active.category].currentActiveIndex = active.item + 1
            }
          }
        }
        break
      }
    }
  }

  const handleClickRibbon = (index, indexChild, isBadge) => {
    const ribbonItem = data[index] && data[index].items[indexChild]
    setSelectedContent(ribbonItem)
    // Lưu trạng thái ribbon để focus
    dispatch(setCurrentRibbon({
      isInit: false,
      tag: 'ribbon',
      category: index,
      item: indexChild
    }))
    dispatch(setRibbonSelected(data?.[index]))
    handleClick(ribbonItem, isBadge)
  }

  const handleClickViewmore = (e, index) => {
    // Lưu trạng thái ribbon để focus
    dispatch(setCurrentRibbon({
      isInit: false,
      tag: 'ribbon',
      category: index,
      item: 9
    }))

    const ribbonItem = data[index]
    dispatch(setRibbonViewmore(ribbonItem))
    history.push('/viewmore')
  }

  const handleClickBanner = () => {
    const carouselItemList = [...document.querySelectorAll('.carousel-item')]
    const carouseItemActive = document.querySelector('.carousel-item.active')
    const indexActive = carouselItemList.indexOf(carouseItemActive)
    const ribbonItem = banners[indexActive]
    dispatch(setRibbonContent(ribbonItem))
    history.push('/content')
  }

  return (
    <div
      id="ribbon"
      className="col-auto col-lg-11 col-md-11 col-xl-11 ribbon"
      style={{ paddingTop: (banners && banners.length > 0) ? '0px' : '30px' }}
    > 
      {isEnbaleJSDebugMode && (
        <div className='remote-js-debug-id'>{advertisingId}</div>
      )}

      {/* Slide Banner */}
      {!isLoadingRibbon ? <Banner
        banners={banners}
        bannerRef={bannerRef}
        handleClickBanner={handleClickBanner}
        active={active}
        isLoadingRibbon={isLoadingRibbon}
      /> : null}

      {/* Ribbon */}
      <div className="ribbon-ribbon">
        {!isLoadingRibbon && data && data.map((item, index) => {
          if (item.items && item.items.length > 0) {
            return (
              <div
                key={index}
                id={`ribbon-container-${index}`}
                className='ribbon-container-row'
              >
                <div className="ribbon-title">{item.name}</div>
                <div
                  id={`ribbon-item-container-${index}`}
                  className="row d-flex flex-nowrap ribbon-item-container"
                >
                {item.items && item.items.map((itemChild, indexChild) => {
                  if (indexChild < 9) {
                    const isBadge = checkVipBadge(itemChild, login)
                    return (
                      <div
                        key={indexChild}
                        id={`ribbon-item-${indexChild}`}
                        className="col-md-3 col-lg-3 ps-2 pe-2 ribbon-item-wrapper"
                        onClick={(e) => handleClickRibbon(index, indexChild, isBadge)}
                      >
                        
                      <Thumbnail
                        isBadge={isBadge}
                        itemChild={itemChild}
                        clss={`ribbon-item ${(!openMenu && active.tag === 'ribbon' && active.category === index && active.item === indexChild) ? 'active' : ''}`}
                      />
                      </div>
                    )
                  }

                  if (indexChild === 9) {
                    const idRamdom = uuidv4()
                    return (
                      <div
                        key={indexChild}
                        id="ribbon-item-9"
                        className="col-md-3 col-lg-3 ps-2 pe-2 ribbon-item-wrapper"
                        onClick={e => handleClickViewmore(e, index)}
                      >
                        <figure key={indexChild} className={`ribbon-item ${(!openMenu && active.tag === 'ribbon' && active.category === index && active.item === indexChild) ? 'active' : ''}`}>
                          <div className='img-wrapper'>
                            <img
                              className="figure-img img-fluid rounded image-viewmore"
                              src={`${viewmore}`}
                              alt='Xem tất cả'
                              onError={(e) => {
                                document.getElementById(`view-more-${idRamdom}`) ? 
                                document.getElementById(`view-more-${idRamdom}`).classList.add('show'): null
                              }}
                            />
                            <div id={`view-more-${idRamdom}`} className='view-more' >Xem Tất Cả</div>
                          </div>
                        </figure>
                      </div>
                    )
                  }
                  return null
                })}
                </div>
              </div>
            )
          } else {
            return null
          }
        })}
      </div>
    </div>
  )
}

export default Ribbon
