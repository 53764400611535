import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import '../styles/loadingOverlay.scss'
import Spinner from 'components/Spinner'
import { setIsLoadingOverlay } from 'features/modal/modalSlice'

const LoadingOverlay = () => {
  const isLoadingOverlay = useSelector((state) => state.modal.isLoadingOverlay)
  const dispatch = useDispatch()
  const timer = useRef(null)

  useEffect(() => {
    if (isLoadingOverlay) {
      timer.current = setTimeout(() => {
        dispatch(setIsLoadingOverlay(false))
      }, 30000)
    }
    return  () => clearTimeout(timer.current)
  }, [isLoadingOverlay])

  return (
    <div className={`${isLoadingOverlay ? '' : 'hide'} loading-overlay`} tabIndex="-1">
      <Spinner />
    </div>
  )
}

LoadingOverlay.displayName = 'LoadingOverlay'

export default LoadingOverlay
