import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  menu: null,
  menuList: [],
  preSelectedMenu: null,
  currentMenu: null,
  isEnbaleJSDebugMode: false
}

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setMenu (state, action) {
      state.menu = action.payload
    },
    setCurrentMenu (state, action) {
      state.currentMenu = action.payload
    },
    setMenuList (state, action) {
      state.menuList = action.payload
    },
    setPreSelectedMenu (state, action) {
      state.preSelectedMenu = action.payload
    },
    setIsEnbaleJSDebugMode (state, action) {
      state.isEnbaleJSDebugMode = action.payload
    }
  }
})

export const {
  setMenu,
  setCurrentMenu,
  setMenuList,
  setPreSelectedMenu,
  setIsEnbaleJSDebugMode
} = menuSlice.actions
export default menuSlice.reducer
