import moment from 'moment';
import '../styles/before.scss'

const LiveEventBeforeReact = ({ handleClickBack, content }) => {

  return (
    <div className="live-event-before">
      <div className="vjs-live-event-react">
        <div className="title">SẮP PHÁT SÓNG</div>
        <div className="content-name">{content?.title}</div>
        <div className="txt">Chương trình sẽ được phát sóng lúc</div>
        <div className="time">{`${moment(content?.start_on).format('HH:mm')} ngày ${moment(content?.start_on).format('DD-MM-YYYY')}`}</div>
      </div>
    </div>
  );
}

export default LiveEventBeforeReact;
