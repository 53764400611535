/* eslint-disable no-async-promise-executor */
import { useMemo, useRef } from 'react'

import { useQuery, useMutation } from 'react-query'
import { getDataTenant } from 'common/utils'

import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { merge } from 'lodash'
import { setPlayerData } from 'features/player/playerSlice'


const useFetchApiContent = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const ribbonContent = useSelector((state) => state.ribbon.ribbonContent)
  const selectedId = useMemo(() => ribbonContent.id, [ribbonContent])
  const epsDefaultRef = useRef(null)

  const {
    mutateAsync: getViewEpsAsync,
    mutate: getViewEps,
    isLoading: isLoadingViewEps
  } = useMutation(
    ({ id }) => {
      console.log('get api getViewEps')
      return getDataTenant('GET', `contents/${id}/view?platform=ctv`)
    },
    {
      onSuccess: async (res) => {
        epsDefaultRef.current = res
        console.log('get api getViewEps complete')
      },
      onError: error => {
        console.log('error getViewEps', error)
        return Promise.reject(error)
      }
    }
  )
  
    const {
      isLoading: isLoading,
      data: dataContentSelected
    } = useQuery(['getContentDetailInfo', selectedId], () => getProgramInfo(selectedId), {
      onSuccess: data => {
        if (!data) return
        const func = async() => {
          let dataContent = {
            id: data.id,
            slug: data.slug,
            defaultEpisodeId: null,
            season: false,
            listEpisode: [],
            content_title: '',
            title: data.title,
            views: data.views,
            linkPlay: data && data.link_play,
            drmSession: data.drm_session_info ? {
              ...data.drm_session_info,
              ...data.cwl_info
            } : null,
            playInfo: data?.play_info?.data,
            content_type: data?.type,
            cwl_info: data.cwl_info,
            titleProgram: data?.title,
            progress: data?.progress
          }
          if (data.type === 2 && data.seasons && data.seasons.length > 0) {
            // eslint-disable-next-line camelcase
            const defaultEpisode = data?.current_season?.current_episode
            try {
              const valueEps = await getViewEpsAsync({ id: defaultEpisode.id })
              dataContent = {
                id: data.id,
                slug: data.slug,
                defaultEpisodeId: valueEps?.id,
                defaultEpisodeSlug: valueEps && valueEps?.slug,
                season: true,
                listEpisode: [],
                title: `Tập ${defaultEpisode?.episode_index}`,
                content_title: valueEps?.title,
                views: 0,
                linkPlay: valueEps?.link_play,
                drmSession: valueEps.drm_session_info ? {
                  ...valueEps.drm_session_info,
                  ...valueEps.cwl_info
                } : null,
                playInfo: data?.valueEps?.data,
                content_type: data?.type,
                currentEpisodes: valueEps,
                cwl_info: valueEps.cwl_info,
                titleProgram: data?.title,
                progress: valueEps?.progress
              }
            } catch (error) {
              console.log('err')
            }
          }
          dispatch(setPlayerData({ ...dataContent, cp: data?.provider_slug, cpName: data?.metadata?.provider?.name }))
        }
        func()
      },
      onError: e => {
        if (e.status >= 500) {
          console.log('e',e )
        }
      },
      enabled: true
    })
  
  
  const getProgramInfo = contentId => {
    return new Promise(async (resolve, reject) => {
      const listPromise = [
        getContentDetail(contentId),
        getContentViewInfo(contentId)
      ]
      try {
        const arrRes = await Promise.all(listPromise)
        const [first = {}, second = {}] = arrRes
        resolve(merge(first, second))
      } catch (error) {
        reject(error)
      }
    })
  }
  
  
  const getContentViewInfo = (slug) => {
    console.log('get api getContentViewInfo', slug)
    return new Promise((resolve, reject) => {
      return getDataTenant('GET', `contents/${slug}/view?platform=ctv`)
        .then(res => {
          if (res.status >= 500) {
            reject(res)
          }
          resolve(res)
        })
        .catch(e => {
          reject(e)
        })
    })
  }

  const getContentDetail = (slug) => {
    console.log('get api getContentDetail', slug)
    return new Promise((resolve, reject) => {
      return getDataTenant('GET', `contents/${slug}/detail?platform=ctv`)
        .then(res => {
          if (res.status >= 500) {
            reject(res)
          }
          resolve(res)
        })
        .catch(e => {
          reject(e)
        })
    })
  }


  return {
    getViewEpsAsync,
    getViewEps,

    isLoadingViewEps,
    isLoadingSelected: isLoading,
    dataContentSelected,
    epsDefaultRef
  }
}

export default useFetchApiContent
