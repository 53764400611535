import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import  { useEffect, useRef , StrictMode} from 'react'
import ReactDOM from 'react-dom'
import { MemoryRouter, Switch, Route } from 'react-router-dom'
import {
  QueryClient,
  QueryClientProvider
} from 'react-query'
import { Provider } from 'react-redux'
import store from 'common/store'
import routes from './routes'
import { v4 as uuidv4 } from 'uuid'
import { generateRemoteDebugChannelID } from 'common/utils'

import './index.scss'
// import reportWebVitals from './reportWebVitals'
import LoginModal from 'features/home/pages/LoginModal'
import WarningLogin from 'features/modal/components/warningLogin'
import LoadingOverlay from 'features/modal/components/overlaySpiner'
import SubscriptionPage from 'features/subscription/pages/SubscriptionPage'
import ForcedLogout from 'features/modal/components/forcedLogout'
// import { setupRemoteLoggingIfEnabled } from 'common/remote-logger'

import * as Sentry from '@sentry/react'
import config from 'common/config'
import { addIma3, logger } from 'common/addScript'
import './index.scss'

Sentry.init({
  dsn: 'https://4efea0e6670f4bfdbaf6ec618f4ccdbf@o166441.ingest.sentry.io/6749591',
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  release: `${config.tenantPlatform}@${config.version ? config.version : 'local'}`,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
})

// setupRemoteLoggingIfEnabled()

const App = () => {
  const modalLoginRef = useRef(null)
  const modalWarningLogin = useRef(null)
  const modalSubscriptionRef = useRef(null)
  const modalForcedLogoutRef = useRef(null)

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  })

  useEffect(() => {
    let advertisingId = localStorage.getItem('advertisingId')
    if (!advertisingId) {
      advertisingId = uuidv4()

      if (advertisingId) {
        localStorage.setItem('advertisingId', advertisingId)
      }
    }

    let remoteDebugLogChannelID = localStorage.getItem('remoteDebugLogChannelID')
    if (!remoteDebugLogChannelID) {
      remoteDebugLogChannelID = generateRemoteDebugChannelID()
      localStorage.setItem('remoteDebugLogChannelID', remoteDebugLogChannelID)
    }
    addIma3()
    logger().enableClear()
    window.logger = logger

  }, [])

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <MemoryRouter>
          <Switch>
            {routes.map((route, index) => (
              <Route key={index} path={route.path} component={route.component} exact={route.exact} />
            ))}
          </Switch>
          <LoginModal ref={modalLoginRef} />
          <WarningLogin ref={modalWarningLogin} />
          <SubscriptionPage ref={modalSubscriptionRef} />
          <ForcedLogout ref={modalForcedLogoutRef}/>
          <LoadingOverlay />
        </MemoryRouter>
      </QueryClientProvider>
    </Provider>
  )
}

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
