import React from 'react'
import videojs from 'video.js'
import { muxConfig } from './plugins/mux'
import { useSelector, useDispatch } from 'react-redux'
import { setTokenDrm } from 'features/player/playerSlice'
import { getVerisonOs } from 'common/utils'
import initDrm, { endDrmToday } from 'features/player/components/drm'
import 'videojs-mux'
import 'videojs-shaka'
import './styles/video.min.css'
import { hls } from '../common/videojs.hlsjs'

export const VideoLiveJS = (props) => {
  window.videojs = videojs

  const videoRef = React.useRef(null)
  const playerRef = React.useRef(null)
  // eslint-disable-next-line react/prop-types
  const { onReady, src, player, videoIdTag } = props
  const dispatch = useDispatch()
  const playerData = useSelector((state) => state.player.playerData)
  const { account } = useSelector(state => state.login)
  const drmToken = useSelector((state) => state.player.drmToken)

  const setTokenGlobal = (value) => {
    dispatch(setTokenDrm(value))
  }
  const method = {  setTokenGlobal }

  React.useEffect(() => {
    if(!src) return
    if (!playerRef.current) {
      const videoElement = videoRef.current
      if (!videoElement) return

      const optionsCustome = {
        loop: false,
        techOrder: playerData?.drmSession ? ['shaka', 'html5'] : ['html5'],
        autoplay: true,
        muted: false,
        controls: true,
        preload: 'auto',
        bigPlayButton: false,
        inactivityTimeout: 0,
        errorDisplay: false,
        html5: {
          nativeTextTracks: false,
          hlsjsConfig: {
            capLevelToPlayerSize: false,
            enableWorker: window.platform === 'tv_webos' ? false : true,

            maxBufferLength: 20,
            maxMaxBufferLength: 40,
            backBufferLength: 10,
            maxBufferSize: 20 * 1000 * 1000,
            startLevel: 0,
            startPosition: 0,
            liveSyncDuration: 15,
      
      
            // Increase the EMA half life because we're fixed the bitrate estimate algorithm of hls.js to use segment duration as weight
            abrEwmaFastLive: 24, // Default: 3
            abrEwmaSlowLive: 48, // Default: 9

            maxLoadingDelay: 1,
            maxStarvationDelay: 1,
      
            testBandwidth: getVerisonOs().startsWith('3') ? true : false,
      
            stretchShortVideoTrack: true,
            forceKeyFrameOnDiscontinuity: false,
            maxAudioFramesDrift: 2,
            manifestLoadPolicy: {
              default: {
                maxTimeToFirstByteMs: 10000,
                maxLoadTimeMs: 30000,
                timeoutRetry: {
                  maxNumRetry: 200,
                  retryDelayMs: 100,
                  maxRetryDelayMs: 1000
                },
                errorRetry: {
                  maxNumRetry: 200,
                  retryDelayMs: 100,
                  maxRetryDelayMs: 1000
                }
              }
            },
            playlistLoadPolicy: {
              default: {
                maxTimeToFirstByteMs: 10000,
                maxLoadTimeMs: 30000,
                timeoutRetry: {
                  maxNumRetry: 200,
                  retryDelayMs: 100,
                  maxRetryDelayMs: 1000
                },
                errorRetry: {
                  maxNumRetry: 200,
                  retryDelayMs: 100,
                  maxRetryDelayMs: 1000
                }
              }
            }
          },
          nativeVideoTracks: false
        },
        plugins: {
          reloadSourceOnError: {},
          ...(muxConfig(playerData, account) || {})
        }
      }

      videojs.log.level('off')
      videojs.log.history.disable()

      const playerEl = (playerRef.current = videojs(videoElement, optionsCustome))

      if (playerData?.drmSession) {
        endDrmToday(drmToken, playerData?.drmSession?.limit_ccu_end_url)
        initDrm(playerEl, playerData, method)
      } else {
        playerEl.src(src)
      }

      onReady && onReady(playerEl)
    } else {
      if (!player) return
      const currentSource = player?.currentSource()?.src
      if (playerData?.drmSession) {
        endDrmToday(drmToken, playerData?.drmSession?.limit_ccu_end_url)
        initDrm(player, playerData, method)
      } else {
        if (currentSource === src) return
        player.src(src)
      }
      playerRef.current = null
    }
  }, [src])

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    return () => {
      const playerEl = playerRef.current
      hls?.destroy()
      if (playerEl) {
        playerEl.dispose()
        playerRef.current = null
      } else {
        if (playerData?.drmSession) {
          endDrmToday()
        }
      }
    }
  }, [playerRef])

  return (
    <div data-vjs-player>
      <video id={videoIdTag || 'vimai-video-player'} ref={videoRef} className="video-js vjs-default-skin vjs-fill" />
    </div>
  )
}

export default VideoLiveJS
