import  { useEffect, useState, useCallback, useRef, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// eslint-disable-next-line max-len
import { faPlay, faClone, faUnlockAlt, faArrowLeft, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import SpinnerRed from 'components/SpinnerRed'
import { keyCode, checkVipBadge, checkTierAvaiable } from 'common/utils'
import { setLoading } from 'common/slice'
import { setContent } from 'features/content/contentSlice'
import { setIsShowSubscription, setMessageSubscription } from '../../subscription/subscriptionSlice'
import Spinner from 'components/Spinner'
import { star } from '../../../constant/images'
import useBlockKeyboard from 'hooks/useBlockKeyboard'
import AgeRatingBadge from 'components/badge/ageContentRating'
import useFetchApiContent from '../hooks/useFetch'
import { CONTENT_TYPE, CLIP, MOVIE, SHOW  } from 'constant/content'
import SeasonModal from 'features/season/components/SeasonModal'
import { setIsShowSeason } from 'features/season/seasonSlice'
import useFetchApiSeason from 'features/season/hooks/useFetchSeason'
import '../styles/content.scss'

const VipIcons = ({ data, login }) => {
  const isBadge = checkVipBadge(data, login)
  if (!isBadge) return null
  return (
    <div className='vip-badge-icon'>
      <img src={star} />
      VIP
    </div>
  )
}

// eslint-disable-next-line react/prop-types
const Icons = ({ data, login }) => {
  return (
    <div className='content-icons'>
      <AgeRatingBadge content={data} clss={'content'}/>
      <VipIcons data={data} login={login} />
    </div>
  )
}

const Content = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const txtMessPremiumRef = useRef('')
  const isNavigate =useRef(false)

  const login = useSelector((state) => state.login)
  const isShowSeason = useSelector((state) => state.season.isShowSeason)

  const { isVip, isLogin, subTier } = login
  const isShowSubscription = useSelector((state) => state.subscription.isShowSubscription)
  const isShowWarningLogin = useSelector((state) => state.modal.isShowWarningLogin)
  const isShowLogin = useSelector((state) => state.login.isShowLogin)
  const modalSeasonRef = useRef()

  const [data, setData] = useState([])
  const [title, setTitle] = useState('')
  const [year, setYear] = useState('')
  const [isLoading, setLoadingState] = useState(true)
  const [description, setDescription] = useState('')
  const [season, setSeasonContent] = useState([])

  const { dataContentSelected, isLoadingSelected, isLoadingViewEps, epsDefaultRef } = useFetchApiContent()
  
  const {
    setEpisode,
    setSeason,

    dataSeasonById,
    isLoadingSeasonById
  } = useFetchApiSeason({ type: 'modal' })

  // Gồm 3 tag: back, action, ss
  const [active, setActive] = useState({
    tag: 'action',
    category: 'play',
    item: 0
  })
  const { isBlockKeyboard } = useBlockKeyboard()


  // eslint-disable-next-line max-len
  const isCanplay = useMemo(() => {
    let dataTmp = data
    if (data.type === CONTENT_TYPE.SHOW) {
      dataTmp = epsDefaultRef.current || data
    }
    const isFreeContent = dataTmp?.has_free_content
      ? dataTmp?.has_free_content
      : dataTmp?.can_preview || dataTmp?.is_watchable || false
    if (isFreeContent) return true
    return checkTierAvaiable(subTier, data?.min_sub_tier, isVip)
  }, [data, subTier, isVip, epsDefaultRef, isLoadingViewEps])
  
  
  const isPlay = useMemo(() => {
    if (isLogin) {
      if (data.type === CONTENT_TYPE.MOVIE){
        return isCanplay && data?.is_watchable
      }
      if (data.type === CONTENT_TYPE.SHOW) {
        return isCanplay
      }
    }
    return data?.has_free_content || isCanplay ||
      (data?.current_season &&
        data?.current_season.current_episode &&
        data?.current_season.current_episode.can_preview)
    }
    , [data, isCanplay, isLogin])

  /* --------------------------------------------- */
  /* ------------------ Hook --------------------- */
  /* --------------------------------------------- */
  const eventListener = useCallback(() => {
  }, [data, season, active, isShowSubscription, isShowWarningLogin, isShowLogin, isBlockKeyboard, isLoadingViewEps, isLoadingSelected, isLoading, isShowSeason])

  useEffect(() => {
    window.onpopstate = () => {
      history.goBack()
    }

    return () => {
      console.clear()
      // dispatch(setDataSeason([]))
    }
  }, [])

  useEffect(() => {
    const txt = txtMessPremiumRef.current
    if (isPlay === false) return dispatch(setMessageSubscription(txt)) 
  }, [isPlay])

  useEffect(() => {
    dispatch(setLoading(isLoadingSelected))
    setLoadingState(isLoadingSelected)
    
  }, [isLoadingSelected])

  useEffect(() => {
    if (dataContentSelected?.id) {
      setValueContent(dataContentSelected)
    }
  }, [dataContentSelected])

  const setValueContent = (dataContent) => {
    
    setData(dataContent)
    setTitle(dataContent.title)
    setYear(dataContent.release_year)

    setDescription(dataContent.short_description)

    setSeasonContent(dataContent.seasons)
    setActive({
      tag: 'action',
      category: 'play',
      item: 0
    })

    dispatch(setContent(dataContent))
    txtMessPremiumRef.current = dataContent?.warning_message
    dispatch(setMessageSubscription(dataContent?.warning_message)) 
    if (isNavigate.current && isLogin) {
      handleClickPlay()
    }
}


  useEffect(() => {
    document.addEventListener('keydown', handleKey)
    return () => {
      document.removeEventListener('keydown', handleKey)
    }
  }, [eventListener])

  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */
  const handleKey = (e) => {
    e.preventDefault()
    if (isBlockKeyboard || isShowSubscription || isShowWarningLogin || isShowLogin || isShowSeason) return

    switch (e.keyCode) {
      case keyCode.RETURN: {
        if (!isShowSubscription) {
          history.goBack()
        }
        break
      }
      case keyCode.EXIT: {
        if (!isShowSubscription) {
          history.goBack()
        }
        break
      }
      case keyCode.ENTER: {
        if (!isShowSubscription) {
          if (active.category === 'back') {
            history.goBack()
          }

          if (active.category === 'play') {
            handleClickPlay()
          }

          if (active.category === 'season') {
            handleClickSeason()
          }
        }
        break
      }
      // Navigate trong danh sách liên quan
      // Từ danh sách liên quan --> Action (Xem ngay, Trọn bộ)
      // Từ Action --> nút Back
      case keyCode.UP: {
        if (!isShowSubscription) {
          if (active.category === 'play') {
            setActive({
              tag: 'action',
              category: 'back',
              item: 0
            })
          }

          if (active.category === 'season') {
            setActive({
              tag: 'action',
              category: 'play',
              item: 0
            })
          }
        }
        break
      }
      // Navigate trong danh sách liên quan
      // Từ nút Back --> Action(Xem ngay, Trọn bộ)
      // Từ Action --> danh sách liên quan
      case keyCode.DOWN: {
        if (!isShowSubscription) {
          if (active.category === 'back') {
            setActive({
              tag: 'action',
              category: 'play',
              item: 0
            })
          }

          if (active.category === 'play' && season && season.length > 0) {
            setActive({
              tag: 'action',
              category: 'season',
              item: 0
            })
          }
        }
        break
      }
    }
  }

  const renderPeople = (peoples, role) => {
    if (peoples && peoples.length > 0) {
      const temp = []
      // eslint-disable-next-line array-callback-return
      peoples.map((item, index) => {
        if (item.role === role) {
          temp.push(item.name)
        }
      })

      if (temp.length > 0) {
        return temp.join(', ')
      }

      return null
    }

    return null
  }

  const handleClickSeason = () =>
  {
    dispatch(setIsShowSeason(true))
    // history.push('/season')
  }

  const handleClickPlay = useCallback(() => {
    if(isLoadingViewEps) return
    // eslint-disable-next-line max-len
    isNavigate.current = true
    // if (!isLogin) return dispatch(setIsShowWarningLogin(true))
    if (isPlay) {
      return history.push('/player')
    }
    return dispatch(setIsShowSubscription(true))
  }, [isPlay, isLogin, isLoadingViewEps])

  const UpgradeButton = () => {
    return (
      <div className="row content-row mt-5 content-button-group">
        <div onClick={() => handleClickPlay()} className={`content-button-item col-md-3 col-lg-3 ps-2 pe-2 ${active.category === 'play' ? 'active' : ''}`}>
          <FontAwesomeIcon
            className="button-icon"
            icon={faUnlockAlt}
          />
          <span className="button-text">Nâng cấp để xem</span>
        </div>
      </div>
    )
  }

  const WatchNowButton = () => {
    let buttonText = 'Phát'
    let percent = 0
    const isProcess =  data?.current_season?.current_episode?.progress > 0 || data?.progress > 0
    if ([CLIP, MOVIE, SHOW].includes(data.type) && isProcess) {
      if (data.current_season && data.current_season.current_episode) {
        buttonText = `Tập ${data.current_season.current_episode.episode_index}`
      } else {
        buttonText = `Xem tiếp`
      }
      if (isProcess && isLogin) {
        const duration = data.duration || data?.current_season?.current_episode?.duration
        const progress = data?.current_season?.current_episode?.progress || data?.progress 
        percent =progress / duration * 100
      }
    }

    return (
      <div className="row content-row mt-5 content-button-group">
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={() => handleClickPlay()} className={`content-button-item col-md-3 col-lg-3 ps-2 pe-2 ${active.category === 'play' ? 'active' : ''}`}>
          {isLoadingViewEps || isLoadingSelected ?
            <SpinnerRed />
            : <FontAwesomeIcon
              className="button-icon"
              icon={faPlay}
              style={{ marginTop: 0 }}
            />
          }
          {/* <SpinnerRed /> */}
          <span style={{ marginTop: 0 }} className="button-text">{buttonText}</span>
        </div>
        {percent ? 
          <div className='progress content-progress col-md-3 col-lg-3'>
            <div
              className='progress-bar content-progress-bar '
              role='progressbar'
              style={{
                width: `${percent}%`
              }}
              aria-valuenow={percent}
              aria-valuemin='0'
              aria-valuemax='100'
            />
        </div>
        : null}

      </div>
    )
  }

  const handleClickBack = () => {
    history.goBack()
  }

  const renderButton = () => {
    if (isPlay ) {
      return <WatchNowButton />
    }

    return <UpgradeButton />
  }

  if (isLoading) {
    return (
      <div className="content-wrapper content-detail-loading">
        <Spinner />
      </div>
    )
  }

  const seasonText = data.type === 2 && season.length > 0 ? `${season.length} Mùa` : ''
  let thumbnail = data.images && data.images.backdrop ? data.images.backdrop : ''
  // if (thumbnail) {
  //   thumbnail = thumbnail.replace('https', 'http')
  // }

  return (
    <div>
      {!isLoading &&
        <div
          id="content-wrapper"
          className={`container-fluid content-wrapper ${thumbnail ? '' : 'content-background-color'}`}
          style={{ backgroundImage: `url('${thumbnail}')` }}
        >

        <div className="content-overlay">
          <div className="row">
            <div className="col col-md-12">
              <div id="content-landing-back-button" className="content-landing-back-button">
                <div id='content-landing-back-button-wrapper' className={`content-landing-back-button-wrapper ${active.category === 'back' ? 'active' : ''}`}>
                  <FontAwesomeIcon
                    className='content-landing-back-icon'
                    icon={faChevronLeft}
                    onClick={() => handleClickBack()}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row content-title-container">
            <div className="col-md-8">
              <Icons data={data} login={login} />
              <div className="content-title">
                {title}
              </div>
            </div>
          </div>

          <div className="row content-row content-info-view">
            <div className="col-md-8">
              {seasonText
                ? (
                    <span>{year} &bull; {seasonText}</span>
                  )
                : (
                    <span>{year}</span>
                  )
              }
            </div>
          </div>
          <div className="row content-row">
            <div className="col-md-7">
              <p className="content-description">{description}</p>
            </div>
          </div>

          {renderPeople(data.people, 'DIRECTOR')
            ? (
                <div className="row content-row">
                  <div className="col-md-8">
                    <div className="content-meta-data">
                      <span className="content-actor-title">Đạo diễn:</span>
                      <span className="content-actor-list">
                        {renderPeople(data.people, 'DIRECTOR')}
                      </span>
                    </div>
                  </div>
                </div>
              )
            : null}

          {renderPeople(data.people, 'ACTOR')
            ? (
                <div className="row content-row">
                  <div className="col-md-8">
                    <div className="content-meta-data">
                      <span className="content-actor-title">Diễn viên:</span>
                      <span className="content-actor-list">
                        {renderPeople(data.people, 'ACTOR')}
                      </span>
                    </div>
                  </div>
                </div>
              )
            : null}

            <div className="row content-landing-margin-top-button">
              {renderButton()}

              {season && season.length > 0
                ? (
                  <>
                    <div className="row content-row mt-1 content-button-group">
                      <div className={`content-button-item col-md-3 col-lg-3 ps-2 pe-2 ${active.category === 'season' ? 'active' : ''}`} onClick={handleClickSeason}>
                        <FontAwesomeIcon
                          className="button-icon"
                          icon={faClone}
                        />
                        <span className="button-text">Các tập & thông tin</span>
                      </div>
                    </div>
                    <SeasonModal
                      ref={modalSeasonRef}
                      setEpisode={setEpisode}
                      setSeason={setSeason}
                      dataSeasonById={dataSeasonById}
                      isLoadingSeasonById={isLoadingSeasonById}
                    />
                  </>
                  )
                : null
              }
            </div>

          </div>

          

          <div className="content-back-instruction">
            Bấm &nbsp;<FontAwesomeIcon className="content-button-shadow" icon={faArrowLeft} /> Back &nbsp;trên điều khiển để trở về
          </div>
        </div>
      }

    </div>
  )
}

export default Content
