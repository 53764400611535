import { getAgeContentRating } from 'core/method/content';
import '../styles/badge.scss'

const AgeRatingBadge = ({ content, clss }) => {
  const ageRating = getAgeContentRating.call(content);
  if (!ageRating) {
    return null;
  }
  return (
    <div className={`age-rating-badge ${clss}`}>
      <img className="age-rating-badge-image" src={ageRating.image_url} />
    </div>
  );
};

export default AgeRatingBadge;
