// Get the Component base class from Video.js
import videojs from 'video.js';
import { seek } from 'constant/images'

var Button = videojs.getComponent('Button');

// The videojs.extend function is used to assist with inheritance. In
// an ES6 environment, `class SeekNext extends Component` would work
// identically.

var SeekNext = videojs.extend(Button, {
  // The constructor of a component receives two arguments: the
  // player it will be associated with and an object of options.
  constructor: function (player, options) {
    this.autoNext = false;
    this.currentEpisode = null;
    this.timerOut = null;
    // It is important to invoke the superclass before anything else,
    // to get all the features of components out of the box!
    Button.apply(this, arguments);
    // If a `text` option was passed in, update the text content of
    // the component.

  },

  // The `createEl` function of a component creates its DOM element.
  createEl: function () {
    const btn = videojs.dom.createEl('button', {
      className: 'vjs-seek-next vjs-control vjs-button',
    });
    btn.innerHTML = `
    <img src=${seek} class="fa fa-repeat vjs-icon-placeholder" aria-hidden="true"></i>
    <span>Nhấn để đi tới 60 giây</span>
    `
    return btn;
  },
  handleClick: function (event) {
    console.log('hello')
  },
});

// Register the component with Video.js, so it can be used in players.
videojs.registerComponent('Seek_Next', SeekNext);
